export function arrayItemToLast(arr, keyItem){		//re-arrange an item of array to the end 
	let fromIdx = arr.indexOf(keyItem); 
	let element = arr.splice(fromIdx, 1)[0];
	arr.splice(-1, 0, element);

	return arr;
}

export function placeCrpPosition(arr){		//re-place crp to the fixed position
	let fromIdx = arr.indexOf('crp');
	let element = arr.splice(fromIdx, 1)[0];
	arr.splice(-4, 0, element);

	return arr;
}