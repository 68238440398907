import "./conversionPreviewUpload.css";
import '../../overlayStyle.css';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from '../../withRouter';
import { Drawer } from '@mui/material';
import { getCookie } from "../../util/cookie";
import { clearSession, setSession } from "../../actions/session"
import Dropzone from 'react-dropzone';
import Header from "../../components/Header";
import ListUploadingFiles from "../../components/ListUploadingFiles";
import languageUtil from "../../lib/languageUtil";
import errormsgUtil from "../../lib/errormsgUtil";
import axios from 'axios';
import JSZip from "jszip";
import { Buffer } from "buffer";
import ManagementSettingChangePW from "../../components/ManagementSettingChangePW";
import DragDropImg from "../../static/img/drag-drop.svg";


function mapStateToProps(state) {
    return { session: state.session, error: state.error }
}

function mapDispatchToProps(dispatch) {
    return {
        clearSession: () => dispatch(clearSession()),
        setSession: (session) => dispatch(setSession(session))
    }
}

export class ConversionPreviewUpload extends Component {
    constructor(props) {
        super(props);
        this.onDrop = (uploadedFiles) => {
            this.onDropFiles(uploadedFiles)
        };
        this.state = {
            drawerToggle: false,
            whichSetting: '',
            uploadedFiles: [],
        };
        this.overlay = React.createRef();
        this.fileUpload = React.createRef(null);
    }

    //when file added by onDrop 
    onDropFiles = async (uploadingfiles) => {
        await this.setState({ uploadedFiles: [uploadingfiles[0]] })
    }

    componentDidMount = async () => {
        this.props.setSession({
            ...this.props.session,
            conversionContent: []

        })
        if (this.props.location.state && this.props.location.state.uploadingfile) {
            this.setState({ uploadedFiles: this.props.location.state.uploadingfile })
        } else {
            await this.fileUpload.current.click();
        }
    }

    componentDidUpdate = async () => {
        if (this.state.uploadedFiles.length > 0) {
            let tmpFilenameArray = this.state.uploadedFiles[0].name.split('.');
            if (tmpFilenameArray[tmpFilenameArray.length - 1] !== 'zip') {
                window.alert(languageUtil("FAIL_OPEN", this.props.session.language));
                this.setState({ uploadedFiles: [] })
                this.props.navigate('/Conversion')
            }
        }
    }

    //loading image 
    showOverlay = () => {
        this.overlay.current.style.width = "100%";
    }
    hideOverlay = () => {
        if (this.overlay.current) this.overlay.current.style.width = "0%";
    }

    //open drawer for the account settings
    openDrawer = (settingItem) => {
        this.setState({
            drawerToggle: true,
            whichSetting: settingItem
        })
    }
    //close drawer to close the account settings 
    closeDrawer = () => {
        this.setState({
            drawerToggle: false,
            whichSetting: ''
        })
    }

    refreshList = (newList) => {
        this.setState({ uploadedFiles: newList })
    }

    // //handle upload files to state
    handleFileUpload = (uploadingfiles) => {
        let file = uploadingfiles[0];
        let fileSize = file.size;
        let maxSize = 1024 * 1024 * 1024; //1GB in bytes

        if (fileSize > maxSize) {
            window.alert(languageUtil("MAX_SIZE_ALERT", this.props.session.language))
        } else {
            this.setState({ uploadedFiles: [file] })
            // here
        }
    }

    //reference input to combine with the button
    handleFileUploadRef = () => {
        this.fileUpload.current.click();
    }


    handleProcessFile = async () => {
        const file = this.state.uploadedFiles[0];
        const reader = new FileReader();
        const filenames_list = [];
        let fileContents = [];
        const promise1 = new Promise((resolve) => {
            reader.onload = () => {
                const zip = new JSZip();
                var validZip = false;
                zip.loadAsync(reader.result).then(async (zipFiles) => {

                    //loop if there is a dch file in the zip
                    zipFiles.forEach(async (relativePath, file) => {
                        if (file.dir || !(file.name.endsWith('.dch'))) {
                            return;
                        } else validZip = true
                    })

                    if (!validZip) {
                        window.alert(languageUtil("FAIL_READ", this.props.session.language));
                    } else {
                        this.showOverlay();
                    }

                    //Promise all                     
                    await Promise.all(Object.entries(zipFiles.files).map(async (files) => {
                        let file = files[1]
                        if (file.dir || !(file.name.endsWith('.dch'))) {
                            return;
                        }
                        const fileReader = new FileReader();
                        fileReader.onload = () => {
                            let fileContent = JSON.parse(Buffer.from(fileReader.result, "base64").toString("utf-8"));
                            fileContent.data[0] = JSON.parse(Buffer.from(fileContent.data[0], "base64").toString("utf-8"));
                            filenames_list.push(file.name.split('/').pop());
                            delete fileContent["file_id"]
                            // contents here
                            let name = file.name.split('/').pop();

                            fileContents.filename = name;
                            fileContents.file = fileContent;

                            this.updateSession(fileContents)
                            resolve(fileContents)
                        };
                        fileReader.readAsText(await file.async('blob'), 'utf-8');
                    }
                    ))
                })

            };
        })
        reader.readAsArrayBuffer(file);
        await promise1.then((resolve) => {
            // console.log('await',resolve);
        })
    }

    //update session use for handleProcessFiles
    updateSession = async (input) => {
        let tmpStoreList = []
        let fullFile = {
            filename: input.filename,
            files: input.file,
        }
        // setSessions        
        if (!this.props.session.conversionContent) {
            await this.props.setSession({
                ...this.props.session,
                conversionContent: [fullFile]

            })
        } else if (this.props.session.conversionContent) {
            let { conversionContent } = this.props.session;
            for (let i = 0; i < conversionContent.length; i++) {
                tmpStoreList.push(conversionContent[i])
            }
            tmpStoreList.push(fullFile)  
            await this.props.setSession(
                {
                    ...this.props.session,
                    conversionContent: tmpStoreList
                }
            )
        }
    }


    //delete all uploaded files when delete all button clicked 
    handleDeleteAllFiles = () => {
        this.setState({ uploadedFiles: [] })
    }

    //delete single uploaded file 
    handleDeleteItem = (idx) => {
        let tmpUploadingFiles = [];

        for (let i = 0; i < this.state.uploadedFiles.length; i++) {
            if (i !== idx) {
                tmpUploadingFiles.push(this.state.uploadedFiles[i])
            }
        }
        this.setState({ uploadedFiles: tmpUploadingFiles })
    }

    //send new password information to the API server. 
    submitOperatorChangePW = async (oldPassword, confirmPassword) => {
        const submitData = {
            'oldpassword': oldPassword,
            'newpassword': confirmPassword
        }

        this.closeDrawer();
        this.showOverlay();
        await axios({
            method: 'post',
            url: `/api/operator/changepassword`,
            data: submitData,
            headers: {
                "Authorization": getCookie('accessToken')
            }
        })
            .then((response) => {
                if (response.data) {
                    if (response.data.error !== '000') {
                        window.alert(errormsgUtil(response.data.error, this.props.session.language))
                    } else {
                        window.alert(languageUtil("SUCCEED_CHANGEPW", this.props.session.language))
                        this.closeDrawer();
                        this.props.navigate("/ConversionLogin");
                    }
                }
            })
            .catch((err) => {
                console.debug(err);
                if (err.response) {
                    console.debug(err.response);
                    console.debug(err.response.status);
                    console.debug(err.message);
                    console.debug(err.response.data);
                    if (err.response.status === 401) {
                        window.alert("Your login session has timeout.  Please re-login.");
                    } else {
                        window.alert("Error:" + err.message + ". Please report this error status to development team.")
                    }
                } else {
                    window.alert("Error:" + err.message + ". Please report this error status to development team.")
                    console.debug(err.message);
                }
            }).finally(this.hideOverlay);
    }

    navigation = async () => {
        if (this.props.session.conversionContent) {
            this.hideOverlay();
            this.props.navigate('/conversionPreview');
        }
    }

    render() {

        return (
            <div className="uploaded-opview">
                <div ref={this.overlay} className="overlay_cover">
                    <div className="spinner">
                        <div className="bounce1"></div>
                        <div className="bounce2"></div>
                        <div className="bounce3"></div>
                    </div>
                </div>
                <Header
                    openDrawer={this.openDrawer}
                    isConversion={true}
                />
                <Dropzone
                    noClick={true}
                    onDrop={this.onDrop}
                    style={{ width: '100%', heigth: '100%' }}
                >
                    {({ getRootProps, getInputProps }) => (
                        <div
                            {...getRootProps({ className: 'dropzone' })}
                            className="contentUploaded-opview"
                            style={{
                                backgroundImage: `url(${DragDropImg})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: "200px",
                                backgroundPosition: "center"
                            }}
                        >
                            <div className="flex-row-8-opview">
                                {this.state.uploadedFiles.length === 0 && (<div
                                    className="website-button-s2-cv-opview"
                                    onClick={this.handleFileUploadRef}
                                >
                                    <input {...getInputProps({ className: 'dropzone' })} />
                                    <input
                                        ref={this.fileUpload}
                                        type="file"
                                        style={{ display: "none" }}
                                        onChange={e => this.handleFileUpload(e.target.files)}
                                        // onChange={(e) => this.onMyfileChange(e.target)}
                                        onClick={(e) => e.target.value = null}
                                        accept=".zip"
                                    />
                                    <img
                                        className="icon-add-opview"
                                        src={require("../../static/img/icon---add@2x.svg").default}
                                        alt='icon-add'
                                    />
                                    <div
                                        className="add-opview valign-text-middle titilliumweb-semi-bold-white-14px"
                                    >
                                        {languageUtil("ADD", this.props.session.language)}
                                    </div>
                                </div>)}
                            </div>
                            <div
                                className="flex-row-opview"
                            >
                                <ListUploadingFiles
                                    uploadedFiles={this.state.uploadedFiles}
                                    refreshList={this.refreshList}
                                    handleDeleteItem={this.handleDeleteItem}
                                    isConversion={true}
                                />
                            </div>
                            <div className="flex-row-btn-opview">
                                {this.state.uploadedFiles.length > 0 && (<div className="button_-next-2-opview"
                                    onClick={async () => {
                                        if (this.state.uploadedFiles && this.state.uploadedFiles.length > 0) {
                                            await this.handleProcessFile()
                                        }
                                        this.navigation()
                                    }}
                                    style={this.state.uploadedFiles && this.state.uploadedFiles.length > 0
                                        ? { backgroundColor: 'var(--cherry-pie)', cursor: 'pointer' }
                                        : { backgroundColor: 'var(--grey)', cursor: 'default' }
                                    }
                                >
                                    <div className="next-opview valign-text-middle titilliumweb-normal-white-18px">
                                        {languageUtil("START", this.props.session.language)}
                                    </div>
                                </div>)}
                            </div>
                        </div>
                    )}
                </Dropzone>
                <Drawer
                    anchor='right'
                    open={this.state.drawerToggle}
                    onClose={this.closeDrawer}
                >
                    {this.state.whichSetting === 'reset_password' && (
                        <ManagementSettingChangePW
                            closeDrawer={this.closeDrawer}
                            submitOperatorChangePW={this.submitOperatorChangePW}
                            convertChangePW={true}
                        />
                    )}

                </Drawer>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ConversionPreviewUpload))