import './verificationView.css';
import '../../overlayStyle.css';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from '../../withRouter';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import WebsiteHeaderS2 from "../../components/WebsiteHeaderS2";
import languageUtil from "../../lib/languageUtil";
import WaterMarkVQ from '../../static/img/VQWatermark.svg';
import { Button, Divider, Typography } from '@mui/material';
import { arrayItemToLast, placeCrpPosition } from '../../lib/arrayUtil';

function mapStateToProps(state) {
    return { session: state.session, error: state.error }
}

export class VerificationView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFile: '',
            validFiles: [],
            invalidFiles: []
        };
        this.overlay = React.createRef();
    }

    //loading image 
    showOverlay = () => {
        this.overlay.current.style.width = "100%";
    }
    hideOverlay = () => {
        if (this.overlay.current) this.overlay.current.style.width = "0%";
    }

    //sorting uploaded file into valid and invalid files 
    componentDidMount = async () => {
        let uploadedFile = JSON.parse(JSON.stringify(this.props.session.uploadedFile));
        let decodedFile = JSON.parse(JSON.stringify(this.props.session.decodedFile));

        //define files with error (to display on header)
        if (this.props.session.uploadedFile && this.props.session.uploadedFile.length > 0) {
            let tmpInvalidFiles = [];

            for (let i = 0; i < uploadedFile.length; i++) {
                if (uploadedFile[i].error !== '000') {
                    tmpInvalidFiles.push(uploadedFile[i]);
                }
            }

            this.setState({ invalidFiles: tmpInvalidFiles });
        }

        //define files without error (to display on view page) 
        if (this.props.session.decodedFile && this.props.session.decodedFile.length > 0) {
            let tmpValidFiles = [];

            for (let i = 0; i < decodedFile.length; i++) {
                for (let j = 0; j < uploadedFile.length; j++) {
                    if (decodedFile[i].filename === uploadedFile[j].filename) {
                        if (uploadedFile[j].error === '000') {
                            tmpValidFiles.push(decodedFile[i]);
                            break;
                        }
                    }
                }
            }

            this.setState({ validFiles: tmpValidFiles });
        }
    }

    componentDidUpdate = (prevState) => {
        if (this.state.validFiles && this.state.validFiles.length > 0) {
            if (!this.state.selectedFile) {
                this.setState({ selectedFile: this.state.validFiles[0] })
            }
        }
    }

    //icon for the file list 
    displayFileIcon = (fileName, fileType) => {
        let pngFile = '';

        if (fileType === 'DCH') {
            if (this.state.selectedFile.filename === fileName) {
                pngFile = 'dch_on.png';
            } else {
                pngFile = 'dch_off.png';
            }
        } else if (fileType === 'PORTFOLIO') {
            if (this.state.selectedFile.filename === fileName) {
                pngFile = 'portfolio_on.png';
            } else {
                pngFile = 'portfolio_off.png';
            }
        } else {
            if (this.state.selectedFile.filename === fileName) {
                pngFile = 'attachment_on.png'
            } else {
                pngFile = 'attachment_off.png';
            }
        }

        return (
            <img style={{ marginRight: '10px' }} src={require('../../static/img/' + pngFile)} alt='fileIcon' />
        )
    }

    //display file list in the menu bar 
    displayFileList = () => {
        if (this.state.validFiles && this.state.validFiles.length > 0) {
            return (
                this.state.validFiles.map((file, idx) => {
                    let fileName = file.filename;
                    let fileType = file.file.file_type.toUpperCase();
                    return (
                        <ListItem
                            key={idx} component="div"
                            sx={{ height: '80px' }}
                        >
                            <ListItemButton
                                sx={
                                    this.state.selectedFile.filename === fileName
                                        ? {
                                            border: '1px solid #DCDCDC', borderRadius: '10px',
                                            boxShadow: '2px 2px 5px #DCDCDC', height: '100%'
                                        }
                                        : { borderBottom: '1px solid #DCDCDC', height: '100%' }
                                }
                                onClick={() => this.handleClickListItem(file)}
                            >
                                {this.displayFileIcon(fileName, fileType)}
                                <ListItemText className='listed_filename'>
                                    <Typography variant='body2'>{fileName}</Typography>
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>
                    )
                })
            )
        } else {
            return (<></>)
        }

    }

    displayArrayContent = (arrayContent) => {
        return (

            arrayContent.map((contentItem, contentIdx) => {
                return (
                    <div key={contentIdx}
                        style={
                            contentItem === 0
                                ? { display: 'flex', flexDirection: 'row' }
                                : { display: 'flex', flexDirection: 'row', paddingTop: '10px' }
                        }
                    >
                        <Typography style={{ fontWeight: 'bold', paddingRight: '5px' }}></Typography>
                        <div>
                            <Typography style={{ fontWeight: 'bold' }}>
                                {contentItem}
                            </Typography>
                        </div>
                    </div>
                )
            })
        )
    }

    displayCredObj = () => {
        let decodedFileData = [];
        if (this.state.selectedFile.file.data.length > 0) {
            for (let i = 0; i < this.state.selectedFile.file.data.length; i++) {
                decodedFileData.push(this.state.selectedFile.file.data[i]);
            }
        }
        return (
            <div
                style={{
                    width: 'calc(100% - 100px)', height: 'auto',
                    minWidth: '100%', margin: '50px 0px'
                }}
            >
                {decodedFileData.map((fileObj, fileIdx) => {
                    let CredKeys = Object.keys(fileObj)
                    let isQR = fileObj.source === 'QR'
                    let isQA = fileObj.source === 'QA'

                    if (isQA && !CredKeys.includes('qf_level')) {
                        if (!CredKeys.includes('student_name')) { 
                            CredKeys.unshift("qf_level")
                        } else {
                            CredKeys.splice(1, 0, "qf_level")
                        }
                    }



                    //re-ordering the attributes 
                    if (CredKeys.includes('assessment_outcomes')) CredKeys = arrayItemToLast(CredKeys, 'assessment_outcomes')
                    if (CredKeys.includes('educational_qualifications_considered')) CredKeys = arrayItemToLast(CredKeys, 'educational_qualifications_considered')
                    if (CredKeys.includes('note')) CredKeys = arrayItemToLast(CredKeys, 'note')
                    if (CredKeys.includes('crp') && isQR) CredKeys = placeCrpPosition(CredKeys);

                    return (
                        <div
                            key={fileIdx}
                            style={{
                                minWidth: '100%'
                            }}
                        >
                            {fileIdx > 0 && (
                                <Divider style={{ width: '100%', margin: '20px 0px' }} />
                            )}
                            <Typography variant="h5" style={{ fontWeight: 'bold' }} >
                                {fileObj.qualification_title && fileObj.qualification_title.toUpperCase()}
                                {fileObj.report_title && languageUtil("QA_REPORT_TITLE", this.props.session.language)}
                            </Typography>
                            <div
                                className='listing_credentials'
                                style={{
                                    backgroundImage: `url(${WaterMarkVQ})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                    backgroundSize: '25%'
                                }}>
                                {CredKeys.map((cred_key, cred_idx) => {
                                    let notDisplayKeys = ['qualification_title', 'report_title', 'source'];
                                    let fullDisplayKeys = ['assessment_outcomes', 'educational_qualifications_considered', 'note'];
                                    if (!notDisplayKeys.includes(cred_key) && !fullDisplayKeys.includes(cred_key)) {
                                        return (
                                            <div key={cred_idx} style={{ minWidth: '50%', marginTop: '20px' }}>
                                                <Typography style={{ color: 'grey' }}>
                                                    {languageUtil(cred_key.toUpperCase(), this.props.session.language)}
                                                </Typography>
                                                {Array.isArray(fileObj[cred_key]) && fileObj[cred_key].length > 1
                                                    ? this.displayArrayContent(fileObj[cred_key])
                                                    : <Typography style={{ fontWeight: 'bold', textAlign: 'justify' }}>
                                                        {cred_key === "region"
                                                            ? this.props.session.language === "english"
                                                                ? fileObj[cred_key].includes("Hong Kong") && ("Hong Kong")
                                                                : fileObj[cred_key].includes("香港") && ("香港")
                                                            : cred_key === "qf_level" && fileObj[cred_key] === undefined
                                                                ? "/"
                                                                : fileObj[cred_key]
                                                        }
                                                    </Typography>
                                                }
                                            </div>
                                        )
                                    } else if (fullDisplayKeys.includes(cred_key)) {
                                        return (
                                            <div key={cred_idx} style={{ minWidth: '100%', marginTop: '20px' }}>
                                                <Typography style={{ color: 'grey' }}>
                                                    {languageUtil(cred_key.toUpperCase(), this.props.session.language)}
                                                </Typography>
                                                {Array.isArray(fileObj[cred_key]) && fileObj[cred_key].length > 1
                                                    ? this.displayArrayContent(fileObj[cred_key])
                                                    : <Typography style={{ fontWeight: 'bold', textAlign: 'justify' }}>
                                                        {cred_key === "region"
                                                            ? this.props.session.language === "english"
                                                                ? fileObj[cred_key].includes("Hong Kong") && ("Hong Kong")
                                                                : fileObj[cred_key].includes("香港") && ("香港")
                                                            : cred_key === "qf_level" && fileObj[cred_key] === undefined
                                                                ? "/"
                                                                : fileObj[cred_key]
                                                        }
                                                    </Typography>
                                                }
                                            </div>
                                        )
                                    } else {
                                        return undefined
                                    }
                                })}
                            </div>
                            {isQR && (
                                <div style={{ width: '100%' }}>
                                    <img style={{
                                        scale: '120%',
                                        marginLeft: '95%',
                                        marginRight: '0px'
                                    }}
                                        src={require("../../static/img/qf_logo.svg").default}
                                        alt="qf_logo"
                                    />
                                </div>
                            )}

                        </div>
                    )
                })}
            </div>
        )
    }

    //display content of the selected file
    displayFileContent = () => {
        let imageFile = false;
        let pdfFile = false;
        let textFile = false;
        let encodedFileData = this.state.selectedFile.file.data[0];
        let selectedFileType = this.state.selectedFile.file.file_type;

        switch (selectedFileType.toUpperCase()) {
            case "JPG":
            case "JPEG":
            case "PNG":
                imageFile = true;
                break;
            case "PDF":
                pdfFile = true;
                break;
            case "DCH":
            case "PORTFOLIO":
                textFile = true;
                break;
            default:
                break;
        }

        if (this.state.selectedFile && Object.keys(this.state.selectedFile).length >= 1) {
            return (
                <div
                    style={{
                        backgroundColor: 'white', padding: '20px 50px',
                        minWidth: '602px', minHeight: 'calc(100vh - 220px)',
                        margin: '10px 10px', borderRadius: '10px',
                    }}
                >
                    {imageFile && (
                        <div style={{
                            width: '100%', height: '100%',
                            display: 'flex', flexDirection: 'row',
                            alignItems: 'center', justifyContent: 'center'
                        }}
                        >
                            <img
                                style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                                src={`data:image/${selectedFileType};base64, ${encodedFileData}`}
                                alt="imgPreview"
                            />
                        </div>
                    )}
                    {pdfFile && (
                        <a
                            href={`data:application/pdf;base64, ${encodedFileData}`}
                            download={this.state.selectedFile.filename.split('.')[0] + '.pdf'}
                            style={{
                                textDecoration: 'none', width: '100%', height: '100%',
                                display: 'flex', flexDirection: 'row',
                                alignItems: 'center', justifyContent: 'center'
                            }}
                        >
                            <Button
                                style={{
                                    backgroundColor: '#125D98'
                                }}
                            >
                                <Typography style={{ color: 'white' }}>{languageUtil("DOWNLOAD_FILE", this.props.session.language)}</Typography>
                            </Button>
                        </a>
                    )}
                    {textFile && (
                        <>
                            <div
                                style={{
                                    height: '130px', width: '100%', minWidth: '402px',
                                    display: 'flex', flexDirection: 'column', gap: '20px'
                                }}
                            >
                                <div
                                    style={{
                                        margin: 'auto auto', display: 'flex'
                                    }}
                                >
                                    <img style={{ scale: '130%' }} src={require("../../static/img/vq_logo.svg").default} alt="vq_logo" />
                                </div>
                                <div
                                    style={{
                                        margin: 'auto auto',
                                        padding: '7px',
                                        backgroundColor: '#F7FAFC',
                                    }}
                                >
                                    <Typography variant='caption' style={{ color: '#125D98', fontWeight: 'bold' }}>
                                        {languageUtil("PORTFOLIO_STATEMENT", this.props.session.language)}
                                    </Typography>
                                </div>
                            </div>
                            <Divider style={{ width: '100%' }} />
                            {this.displayCredObj()}
                        </>
                    )}

                </div>
            )
        } else {
            return (
                <></>
            )
        }
    }

    handleClickListItem = (file) => {
        let fileName = file.filename;

        if (this.state.selectedFile === fileName) {
            this.setState({ selectedFile: '' })
        } else {
            this.setState({ selectedFile: file })
        }

    }

    render() {
        return (
            <div className="container-center-horizontal">
                <div ref={this.overlay} className="overlay_cover">
                    <div className="spinner">
                        <div className="bounce1"></div>
                        <div className="bounce2"></div>
                        <div className="bounce3"></div>
                    </div>
                </div>
                <div className="viewCred">
                    <WebsiteHeaderS2
                        title="VIEW"
                        isVerification={true}
                        invalidFiles={this.state.invalidFiles}
                    />
                    <div className="viewContainer">
                        <div className="view_menu">
                            <this.displayFileList />
                        </div>
                        <div className="view_content">
                            {Object.keys(this.state.selectedFile).length > 0 && (this.displayFileContent())}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps)(withRouter(VerificationView))