import './managementChangePW.css';
import '../../overlayStyle.css';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from '../../withRouter';
import { getCookie } from "../../util/cookie";
import Group1332 from "../../components/Group1332";
import WebsiteInputFieldLarge from "../../components/WebsiteInputFieldLarge";
import WebsiteButtonS1B from "../../components/WebsiteButtonS1B";
import backgroundImage from "../../static/img/bg-1@1x.png";
import languageUtil from "../../lib/languageUtil";
import errormsgUtil from "../../lib/errormsgUtil";
import axios from 'axios';

function mapStateToProps(state) {
  return { session: state.session, error: state.error }
}

export class ManagementChangePW extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      disableConfirm: true,
      passwordUnmatched: false,
      isPasswordStrong: false, 
    }
    this.overlay = React.createRef();
  }

  componentDidUpdate = (prevProps, prevState) => {
    //if password not matched, show the error message using variable;passwordUnmatched.
    if (!this.state.passwordUnmatched && this.state.newPassword && this.state.confirmPassword) {
      if (this.state.newPassword !== this.state.confirmPassword) {
        this.setState({ passwordUnmatched: true })
      }
    }
    if (this.state.passwordUnmatched && 
      ((!this.state.newPassword || !this.state.confirmPassword) || (this.state.newPassword === this.state.confirmPassword))) {
      this.setState({ passwordUnmatched: false })
    }
    
    //disable the confirm button if all three fields are not filled 
    if (this.state.oldPassword && this.state.newPassword && this.state.confirmPassword) {

      //disable the confirm button if new password and confirm password are not matched
      if (!this.state.disableConfirm && (this.state.passwordUnmatched || !this.state.isPasswordStrong)) this.setState({ disableConfirm: true })
      if (this.state.disableConfirm && (!this.state.passwordUnmatched && this.state.isPasswordStrong)) this.setState({ disableConfirm: false })

    } else {
      if (!this.state.disableConfirm) this.setState({ disableConfirm: true })
    }

    if ((prevState.newPassword !== this.state.newPassword) || (prevState.confirmPassword !== this.state.confirmPassword)) {
      if (this.checkStrongPW(this.state.newPassword) && this.checkStrongPW(this.state.confirmPassword)) {
        this.setState({isPasswordStrong: true})
      } else {
        this.setState({isPasswordStrong: false})
      }
    }

  }

  //loading image 
  showOverlay = () => {
    this.overlay.current.style.width = "100%";
  }
  hideOverlay = () => {
    if (this.overlay.current) this.overlay.current.style.width = "0%";
  }

  //update from the child components 
  updatePWs = async (fieldName, updatedValue) => {
    //input: state field
    //output: set state with updated value
    let stateObj = {};
    stateObj[fieldName] = updatedValue;
    this.setState(stateObj);
  }

  checkStrongPW = (password) => {
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");

    if (strongRegex.test(password)) {
      return(true);
    } else {
      return(false);
    }
  }

  //send new password information to the API server. 
  submitIndivChangePW = async () => {
    const submitData = {
      'oldpassword': this.state.oldPassword,
      'newpassword': this.state.confirmPassword
    }

    this.showOverlay();
    await axios({
      method: 'post',
      url: `/api/auth/changepassword`,
      data: submitData,
      headers: {
        "Authorization": getCookie('accessToken')
      }
    })
      .then((response) => {
        if (response.data) {
          if (response.data.error !== '000') {
            window.alert(errormsgUtil(response.data.error, this.props.session.language))
          } else {
            window.alert(languageUtil("SUCCEED_CHANGEPW", this.props.session.language))
            this.props.navigate("/Management")
          }
        }
      })
      .catch((err) => {
        console.debug(err);
        if (err.response) {
          console.debug(err.response);
          console.debug(err.response.status);
          console.debug(err.message);
          console.debug(err.response.data);
          if (err.response.status === 401) {
            window.alert("Your login session has timeout.  Please re-login.");
          } else {
            window.alert("Error:" + err.message + ". Please report this error status to development team.")
          }
        } else {
          window.alert("Error:" + err.message + ". Please report this error status to development team.")
          console.debug(err.message);
        }
      }).finally(this.hideOverlay);
  }

  render() {

    return (
      <div className="container-center-horizontal">
        <div ref={this.overlay} className="overlay_cover">
          <div className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
        </div>
        <form
          className="home-management-login-changepw screen"
          style={{ backgroundImage: `url(${backgroundImage})` }}
          name="form36"
          action="form36"
          method="post"
        >
          <div className="card-3">
            <Group1332
              title={languageUtil("CHANGE_PASSWORD", this.props.session.language)}
              subtitle={languageUtil("NEW_PASSWORD_STATEMENT", this.props.session.language)}
              navigate={(path) => this.props.navigate(path)}
              className="title-44"
            />
            <WebsiteInputFieldLarge
              inputType="password"
              inputPlaceholder="ORIGINAL_PASSWORD"
              className="input_-field-4"
              indivChangePW={true}
              updatePWs={this.updatePWs}
              oldPassword={this.state.oldPassword}
            />
            <WebsiteInputFieldLarge
              inputType="password"
              inputPlaceholder="NEW_PASSWORD"
              className="input_-field-1-1"
              indivChangePW={true}
              updatePWs={this.updatePWs}
              newPassword={this.state.newPassword}
            />
            <WebsiteInputFieldLarge
              inputType="password"
              inputPlaceholder="CONFIRM_PASSWORD"
              className="input_-field-1"
              indivChangePW={true}
              updatePWs={this.updatePWs}
              confirmPassword={this.state.confirmPassword}
              passwordUnmatched={this.state.passwordUnmatched}
            />
            <WebsiteButtonS1B
              btnText={languageUtil("CONFIRM", this.props.session.language)}
              submitIndivChangePW={this.submitIndivChangePW}
              disableConfirm={this.state.disableConfirm}
              indivChangePW={true}
            />
          </div>
        </form>
      </div>
    )
  }
}
export default connect(mapStateToProps)(withRouter(ManagementChangePW))