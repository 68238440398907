import errorEN from '../constants/errorMsg/errorMsg_en.json';
import errorHK from '../constants/errorMsg/errorMsg_hk.json';
import errorCN from '../constants/errorMsg/errorMsg_cn.json';


const returnUS = (fieldName) => {
    return errorEN[fieldName]
}

const returnHK = (fieldName) => {
    return errorHK[fieldName]
}

const returnCN = (fieldName) => {
    return errorCN[fieldName]
}

const errormsgUtil = (fieldName, language) => {
    switch(language) {
        case 'english':
            return returnUS(fieldName);
        case 'cantonese':
            return returnHK(fieldName);
        case 'mandarin':
            return returnCN(fieldName);
        default:
    }
}

export default errormsgUtil;
