import React from "react";
import { Link } from "react-router-dom";
import "./Function6.css";
import { connect } from 'react-redux';
import languageUtil from "../../lib/languageUtil";

function mapStateToProps(state) {
  return { session: state.session, error: state.error }
}

function Function6(props) {
  let [isMouseOver, setIsMouseOver] = React.useState(false);
  let [isOnButton, setIsOnButton] = React.useState(false);

  return (
    <div className="function-5 animate-enter5"
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
    >
      <div className="credentials-management-5 titilliumweb-bold-mine-shaft-28px"
        style={isMouseOver ? { color: 'white' } : { color: 'black' }}
      >
        {languageUtil("CREDENTIALS", props.session.language)}
        {props.session.language === 'english' ? <br /> : ""}
        {languageUtil("CONVERSION", props.session.language)}
      </div>
      <Link to="/ConversionLogin">
        <div className="button-15"
          onMouseEnter={() => setIsOnButton(true)}
          onMouseLeave={() => setIsOnButton(false)}
          style={isMouseOver ? { borderColor: 'white' } : { borderColor: '#AEB2B6' }}
        >
          <div className="login-10 titilliumweb-normal-mine-shaft-16px"
            style={isMouseOver && !isOnButton ? { color: 'white' } : { color: 'black' }}>
            {languageUtil("LOGIN", props.session.language).toUpperCase()}
          </div>
          {isMouseOver && !isOnButton
            ? (<img src={require("../../static/img/enter_icon_white.svg").default} alt='arrow' />)
            : (<img src={require("../../static/img/enter_icon.svg").default} alt='arrow' />)
          }
        </div>
      </Link>
      <div className="rectangle-109-5" style={isMouseOver ? { backgroundColor: 'white' } : { backgroundColor: 'var(--blue-diamond)' }}></div>
    </div>
  );
}

export default connect(mapStateToProps)(Function6);
