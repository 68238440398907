import "./OperatorLogTable.css";
import React, { useMemo } from 'react';
import { useTable } from 'react-table';
import { connect } from 'react-redux';
import languageUtil from "../../lib/languageUtil";

function mapStateToProps(state) {
    return { session: state.session, error: state.error }
}

function OperatorLogTable(props) {

    let logTableColumns = [
        {
            Header: "ACTION",
            accessor: "action",
            Cell: ({ cell }) => (
                <div>
                    
                    {props.language ==='english'
                    ?languageUtil(cell.row.values.action.toUpperCase(), props.session.language)
                    :languageUtil(cell.row.values.action.toUpperCase(), props.session.language)
                    }
                </div>
            )
        },
        {
            Header: "CREATEDAT",
            accessor: "createdAt",
            Cell: ({ cell }) => (
                <div>
                    {new Date(cell.row.values.createdAt).toLocaleDateString() 
                        + " " 
                        + new Date(cell.row.values.createdAt).toLocaleTimeString()
                    }
                </div>
            )
        },
        {
            Header: "DATA_CONTENT",
            accessor: "data_content",
            Cell: ({ cell }) => (
                <div>
                    {cell.row.values.data_content
                    ? JSON.parse(JSON.stringify(cell.row.values.data_content).replaceAll("<br>", ", "))
                    : "/"
                    }
                </div>
            )
        }
    ];

    let DisplayLogTable = (props) => {
        let columns = useMemo(() => logTableColumns, []);
        let data = useMemo(() => props.logList, [props.logList]);
        let isDataExist = (data && data.length > 0) ? true : false;

        const { 
            getTableProps, 
            getTableBodyProps, 
            headerGroups, 
            rows, 
            prepareRow 
        } = useTable({ columns, data });

        return (
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => {
                                return (
                                    <th {...column.getHeaderProps()}>
                                        {languageUtil(column.render("Header"), props.language)}
                                    </th>
                                )
                            })}
                        </tr>
                    ))}
                </thead>
                {isDataExist && (
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return(
                                            <td {...cell.getCellProps()}>
                                                {cell.render("Cell")}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                )}
            </table>
        )
    }

    return (
        <div style={{ width: "100%", heigth: "100%" }}>
            <DisplayLogTable
                logList={props.logList}
                language={props.session.language}
            />
        </div>
    )
}

export default connect(mapStateToProps)(OperatorLogTable)