import { CLEAR_ERROR, SET_ERROR, CLEAR_EDITING, SET_EDITING } from '../constants/actionTypes';

const initialState = {
  errorMessage: "",
  isEditing: false
}

const error = (state = initialState, action) => {
  switch (action.type) {
    case SET_ERROR:
      return Object.assign({},
        state,
        { errorMessage: action.errorMessage });

    case CLEAR_ERROR:
      return Object.assign({},
        state,
        { errorMessage: "" });

    case SET_EDITING:
      return Object.assign({},
        state,
        { isEditing: true });

    case CLEAR_EDITING:
      return Object.assign({},
        state,
        { isEditing: false });

    default:
      return state;
  }
}

export default error;
