import "./SavePortfolio.css";
import React from "react";
import { Button, Typography } from "@mui/material";
import { connect } from 'react-redux';
import languageUtil from "../../lib/languageUtil";

function mapStateToProps(state) {
  return { session: state.session, error: state.error }
}

function SavePortfolio(props) {

  return (
    <div>
      <div className="overlap-group-drawer">
        <div className="title-drawer valign-text-middle titilliumweb-bold-black-18px">
          {languageUtil("NAME_OF_PORTFOLIO", props.session.language)}
        </div>
        <img
          className="icon-drawer"
          src={require("../../static/img/-icon-7@2x.svg").default}
          alt='close'
          onClick={props.closeDrawer}
        />
      </div>
      <div className="make_drawer_container">
        <input
          id="inputPortfolioName"
          style={{
            margin:'20px 0px',
            minHeight:'50px',
            borderRadius:'5px',
            border:'1px solid lightgrey',
            paddingLeft:'10px'
          }}
          placeholder={languageUtil("INPUT_THE_NAME", props.session.language)}
          onChange={(e) => props.changePortfolioName(e.target.value)}
          value={props.portfolioName}
          autoComplete='off'
        />
        <div 
          style={{ 
            minHeight:'60px', width:'100%', marginBottom: '20px',
            backgroundColor: 'var(--bluelight)',
            borderRadius:'10px', display:'flex', flexDirection:'row',
            padding:'10px 20px'
          }}
        >
          <img src={require("../../static/img/blue_message_icon.svg").default}
          alt='message_icon' />
          <Typography variant="caption" sx={{color:'var(--bluemain)', paddingLeft:'20px'}}>
            {languageUtil("SHARE_NOTICE_MESSAGE", props.session.language)}
          </Typography>
        </div>
        <div style={{ marginTop: 'auto', marginBottom: '20px'}}>
          <Button 
            variant='contained'
            disabled={!props.portfolioName}
            onClick={props.sendGroupAPI}
            sx={{
              backgroundColor: 'var(--bluemain)',
              marginLeft: 'auto',
              marginRight: 'auto',
              borderRadius: '10px',
              minHeight: '60px',
              width: '100%',
              textTransform: 'none'
            }}
          >
            <Typography sx={{color: 'white'}}>
              {languageUtil("SAVE", props.session.language)}
            </Typography>
          </Button>
          {/* <WebsiteButtonS1B
            className="button_-confirm"
            btnText={languageUtil("Save", props.session.language)}
            indivChangePW={true}
            submitIndivChangePW={() => props.submitIndivChangePW(originalPW, newPW)}
          /> */}
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(SavePortfolio);
