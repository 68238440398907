import React from "react";
import { connect } from "react-redux";
import "./WebsiteLanguageSelection2.css";
import { Select, MenuItem, Box} from '@mui/material'
import { styled } from '@mui/material/styles';
import { setSession } from "../../actions/session"

function mapStateToProps(state) {
  return { session: state.session, error: state.error }
}

function mapDispatchToProps(dispatch) {
  return {
    setSession: (session) => dispatch(setSession(session))
  }
}

const CcsSelect = styled(Select)({
  "& .MuiSelect-select:focus": {
    backgroundColor: "transparent",
  },
})


function WebsiteLanguageSelection2(props) {
  let [isMouseOn, setIsMouseOn] = React.useState(false);
  
  return (
    <div className="Language_button">
      <CcsSelect
        className="SelectLanguage"
        variant="standard"
        onOpen={() => setIsMouseOn(false)}
        onMouseEnter={() => setIsMouseOn(true)}
        onMouseLeave={() => setIsMouseOn(false)}
        disableUnderline={true}
        defaultValue=''
        value={props.session.language}
        renderValue={(value) => {
          return (
            <Box sx={{ display: 'flex', gap: 1, width: "95px" }}>
              {isMouseOn
                ? (
                  <img style={{ scale: '120%' }}
                    src={require("../../static/img/earth_icon.svg").default}
                    alt="lang"
                  />

                )
                : (
                  <img style={{ scale: '120%' }}
                    src={require("../../static/img/earth_icon.svg").default}
                    alt="lang"
                  />

                )
              }

              {value === 'english'
                ? 'ENGLISH'
                : value === 'cantonese'
                  ? '繁體中文'
                  : value ==='mandarin'
                    ?'简体中文'
                    : undefined
                    }
                  
            </Box>
            
          )
        }}
        inputProps={{
          IconComponent: () => null,
          MenuProps: {
            MenuListProps: {
              sx: {
                backgroundColor: 'lightgrey',
                color: 'black'
              }
            },
            sx: {
              "&& .Mui-selected": {
                backgroundColor: "transparent",
                color: 'black'
              },
              "& .MuiMenuItem-root:hover": {
                backgroundColor: "transparent",
                color: 'blue'
              },
            }
          }
        }}
        onChange={(e) => {
          props.setSession({...props.session, language: e.target.value})
        }}
        > 
          <MenuItem disabled ={props.session.language !== 'english'?false:true} value={'english'}>ENGLISH</MenuItem>
          <MenuItem disabled ={props.session.language !== 'cantonese'?false:true}value={'cantonese'}>繁體中文</MenuItem>
          <MenuItem disabled ={props.session.language !== 'mandarin'?false:true}value={'mandarin'}>简体中文</MenuItem>
         {/*props.session.language !== 'english'?(<MenuItem value={'english'}>ENGLISH</MenuItem>):(<MenuItem disabled>ENGLISH</MenuItem>)*/}
          {/*props.session.language !== 'cantonese' ?(<MenuItem value={'cantonese'}>繁體中文</MenuItem>):(<MenuItem disabled>繁體中文</MenuItem>)*/}
          {/*props.session.language !== 'mandarin' ?(<MenuItem value={'mandarin'}>简体中文</MenuItem>):(<MenuItem disabled>简体中文</MenuItem>)*/}
          

      </CcsSelect>
    </div>
  );

}

export default connect(mapStateToProps, mapDispatchToProps)(WebsiteLanguageSelection2);
