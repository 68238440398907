import "./WebsiteUploadFile.css";
import React from "react";
import { Typography } from "@mui/material";

function WebsiteUploadFile(props) {
  const { warning, title, credFile, updateUserInfo } = props;
  let {isCreditValid, setIsCreditValid} = props;
  const fileInput = React.useRef(null);


  //reference to react <input> component to upload file.   
  const handleUploadCred = () => {
    if(!credFile.name) {
      fileInput.current.click();
    } else {
      props.emptyCredFile();
    }
  };

  //update the state of uploaded file
  const handleChange = async (e) => {
    const tmpCredFile = e.target.files[0];

    if (tmpCredFile) {
      await updateUserInfo('credFile', tmpCredFile)

      if (tmpCredFile.name.includes('.dch')) {
        await setIsCreditValid(true)
      } else {
        await setIsCreditValid(false)
      }
    }
  };


  return (
    <React.Fragment>
      <input
        style={{ display: "none" }}
        type="file" 
        accept=".dch"
        ref={fileInput} 
        onChange={handleChange} 
        />
      <div className="uploadCredFile" onClick={handleUploadCred}>
        <div className="overlap-group1">
          <div className="overlap-group">
            <div className="title-2 valign-text-middle titilliumweb-normal-mine-shaft-16px">
              <img style={{width:'50px', height:'50px', margin:'0px 10px'}} src={require("../../static/img/drag-drop.svg").default} alt="dragDropImg" />
              <div style={{maxWidth:'300px', whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis'}}>
                {credFile.name 
                  ? credFile.name
                  : title
                }
              </div>
            </div>
          </div>
          <Typography
            className="credFileError"
            variant="caption"
          >
            {(!isCreditValid && credFile.name) && warning}
          </Typography>
          <div className="icon">
            {credFile.name
              ? <div className="closeUploaded">
                <img src={require("../../static/img/close.svg").default} alt="uploadIcon" />
              </div>
              : <div className="upload">
                <img className="upload-1" src={require("../../static/img/upload@2x.svg").default} alt="uploadIcon" />
              </div>
            }

          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default WebsiteUploadFile;
