import './managementLogin.css'
import '../../overlayStyle.css';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { withRouter } from '../../withRouter';
import WebsiteInputFieldLarge from "../../components/WebsiteInputFieldLarge";
import WebsiteButtonS1B from "../../components/WebsiteButtonS1B";
import backgroundImage from "../../static/img/bg-1@1x.png";
import axios from 'axios';
import languageUtil from "../../lib/languageUtil";
import errormsgUtil from "../../lib/errormsgUtil";
import { setCookie, removeCookie } from '../../util/cookie';
import NavNavMenu from '../../components/NavNavMenu';
import WebsiteLanguageSelection2 from '../../components/WebsiteLanguageSelection2';

function mapStateToProps(state) {
  return { session: state.session, error: state.error }
}

class ManagementLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginEmail: '',
      password: '',
      disableConfirm: true
    }
    this.overlay = React.createRef();
  }

  //loading image 
  showOverlay = () => {
    this.overlay.current.style.width = "100%";
  }
  hideOverlay = () => {
    if (this.overlay.current) this.overlay.current.style.width = "0%";
  }

  componentDidMount = () => {
    removeCookie('accessToken')
    removeCookie('backupEmail')
    removeCookie('uploadedFiles')
    removeCookie('previewSelected')
    removeCookie('operatorType')
    removeCookie('uploadedFilesType')
  }

  componentDidUpdate = () => {
    //updating disableConfirm
    if (this.state.password && this.state.loginEmail) {
      if (this.state.disableConfirm) this.setState({ disableConfirm: false })
    } else {
      if (!this.state.disableConfirm) this.setState({ disableConfirm: true })
    }
  }

  //update from the child components 
  updateLoginInfo = async (fieldName, updatedValue) => {
    //input: state field
    //output: set state with updated value
    let stateObj = {};
    stateObj[fieldName] = updatedValue;
    this.setState(stateObj);
  }

  //if enterkey pressed during the input, submit the login
  entersubmitIndivLogin = () => {
    if (this.state.loginEmail && this.state.password) this.submitIndivLogin();
  }

  //send signup information to the API server. 
  submitIndivLogin = async () => {
    const submitData = {
      'email': this.state.loginEmail,
      'password': this.state.password
    }

    this.showOverlay();
    await axios({
      method: 'post',
      url: `/api/auth/signin`,
      data: submitData
    })
      .then((response) => {
        if (response.data) {
          if (response.data.error !== '000') {
            window.alert(errormsgUtil(response.data.error, this.props.session.language))
          } else {
            //set access token in cookie
            setCookie('accessToken', response.data.accessToken)

            if (response.data.is_first_login) {
              this.props.navigate("/ManagementChangePW")
            } else {
              this.props.navigate("/Management")
            }
          }
        }
      })
      .catch((err) => {
        console.debug(err);
        if (err.response) {
          console.debug(err.response);
          console.debug(err.response.status);
          console.debug(err.message);
          console.debug(err.response.data);
          if (err.response.status === 401) {
            window.alert("Your login session has timeout.  Please re-login.");
          } else {
            window.alert("Error:" + err.message + ". Please report this error status to development team.")
          }
        } else {
          window.alert("Error:" + err.message + ". Please report this error status to development team.")
          console.debug(err.message);
        }
      }).finally(this.hideOverlay);
  }

  navigateTo = (path) => {
    this.props.navigate(path)
  }

  render() {

    return (
      <div className="container-center-horizontal">
        <div ref={this.overlay} className="overlay_cover">
          <div className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
        </div>
        <form
          className="home-management-login screen"
          style={{ backgroundImage: `url(${backgroundImage})` }}
          name="form35"
          action="form35"
          method="post"
        >
          <div className="indiv-login-header">
            <div className="indiv-login-iconlist">
              <img className="logo-4" style={{ scale: '80%' }} src={require("../../static/img/left.svg").default} alt="logo" />
            </div>
            <div className="indiv-login-link">
              <NavNavMenu
                navListIem1Props={languageUtil('CONTACT_US', this.props.session.language)}
                navListIem2Props={languageUtil('FAQ', this.props.session.language)}
                navListIem3Props={languageUtil('ABOUT_US', this.props.session.language)}
                language_selected={this.props.session.language}
                navigateTo={(path) => { this.navigateTo(path) }}
              />
              <WebsiteLanguageSelection2 />
            </div>
          </div>
          <div className="overlap-group-74">
            <div className="title-59">
              <div className="indicator-3"></div>
              <div className="credentials-management-6 titilliumweb-bold-martinique-24px">
                {languageUtil("CREDENTIALS_MANAGEMENT", this.props.session.language)}
              </div>
              <img
                className="cancel_indiv_login"
                src={require("../../static/img/-icon-7@2x.svg").default}
                alt='close'
                onClick={() => this.props.navigate("/")}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '30px' }}>
              <WebsiteInputFieldLarge
                inputType="email"
                inputPlaceholder="PERSONAL_EMAIL"
                userEmail={this.state.loginEmail}
                updateLoginInfo={this.updateLoginInfo}
                indivLogIn={true}
                entersubmitIndivLogin={this.entersubmitIndivLogin}
              />
              <WebsiteInputFieldLarge
                inputType="password"
                inputPlaceholder="PASSWORD"
                updateLoginInfo={this.updateLoginInfo}
                password={this.state.password}
                indivLogIn={true}
                entersubmitIndivLogin={this.entersubmitIndivLogin}
              />
            </div>
            <Link to="/managementForgotEmail">
              <div className="forgot-email valign-text-middle titilliumweb-normal-matisse-16px">
                {languageUtil("FORGOT_EMAIL?", this.props.session.language)}
              </div>
            </Link>
            <Link to="/managementForgotPW">
              <div className="forgot-password-1 valign-text-middle titilliumweb-normal-matisse-16px">
                {languageUtil("FORGOT_PASSWORD?", this.props.session.language)}
              </div>
            </Link>
            <div style={{ width: '400px' }}>
              <WebsiteButtonS1B
                btnText={languageUtil("LOGIN", this.props.session.language)}
                submitIndivLogin={this.submitIndivLogin}
                disableConfirm={this.state.disableConfirm}
                indivLogIn={true}
              />
            </div>
          </div>
        </form>
      </div>
    )
  }
}

export default connect(mapStateToProps)(withRouter(ManagementLogin))