import { Typography } from "@mui/material";
import { connect } from 'react-redux';
import React, { useState } from "react";
import languageUtil from "../../lib/languageUtil";
import "./WebsiteInputFieldLarge.css";

function mapStateToProps(state) {
  return { session: state.session, error: state.error }
}

function WebsiteInputFieldLarge(props) {
  const { userEmail, vqid, inputType, inputPlaceholder, updateUserInfo,
    updateLoginInfo, indivLogIn, indivChangePW, newPassword, confirmPassword, updatePWs } = props;
  let [isEmailValid, setIsEmailValid] = useState(null);
  let [isPWValid, setIsPWValid] = useState(null);
  let [isHex, setIsHex] = useState(null);
  let [isUseridValid, setIsUseridValid] = useState(null);

  //inputValidation for userid
  const inputValidationUserid = async (e) => {
    let regEx = /^[0-9a-zA-Z-_]+$/;
    await setIsUseridValid(regEx.test(e.target.value));

    if(e.target.value.length <= 20) {
      props.updateLoginInfo('userid', e.target.value);
    } else {
      e.target.value = props.userid;
    }
  }

  //input validation for vqid
  const inputValidationVQID = async (e) => {
    let regEx = /^[0-9a-fA-F]+$/;
    await setIsHex(regEx.test(e.target.value));

    if (e.target.value.length <= 10) {
      e.target.value = e.target.value.toUpperCase();
      updateUserInfo('vqid', e.target.value.toUpperCase());
    } else {
      e.target.value = vqid;
    }
  }

  //input validation for userEmail(signup and signin)
  const inputValidationEmail = async (e) => {
    let regEx = /\S+@\S+\.\S+/;
    await setIsEmailValid(regEx.test(e.target.value));

    if (e.target.value.length <= 99) {
      if (!indivLogIn) {
        updateUserInfo('userEmail', e.target.value);
      } else {
        updateLoginInfo('loginEmail', e.target.value);
      }
    } else {
      e.target.value = userEmail;
    }
  }

  //input validation for password
  const inputValidationPassword = async (e, inputPlaceholder) => {
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");

    if (!indivChangePW) { //to login: pass the password to the parent
      updateLoginInfo('password', e.target.value);
    } else {          //to chagne password: pass the password to the parent 
      switch (inputPlaceholder) {
        case 'ORIGINAL_PASSWORD':
          updatePWs('oldPassword', e.target.value);
          break;
        case 'NEW_PASSWORD':
          updatePWs('newPassword', e.target.value);
          break;
        case 'CONFIRM_PASSWORD':
          updatePWs('confirmPassword', e.target.value);
          break;
        default:
          break;
      }
    }
    // if(e.target.value.length >= 5) {
    if (strongRegex.test(e.target.value)) {
      setIsPWValid(true);
    } else {
      setIsPWValid(false);
    }
  }

  //handle enter key pressed in the input field 
  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      if(indivLogIn) {
        props.entersubmitIndivLogin();
      } else if (props.conversionLogin) {
        props.entersubmitConversionLogin();
      }
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div
        className="input_-field-9"
        style={(!isPWValid && (newPassword || confirmPassword))
          || (!isEmailValid && (userEmail)) || (!isHex && vqid) || (!isUseridValid && props.userid)
          ? { borderColor: 'red' }
          : { borderColor: 'var(--line)'}
        }
      >
        <input
          className="input_-value without_spinner titilliumweb-normal-mountain-mist-16px"
          style={{ color: 'black' }}
          name="input_value"
          placeholder={languageUtil(inputPlaceholder, props.session.language)}
          type={inputType}
          autoComplete='off'
          onChange={e => {
            if (inputType === 'email') inputValidationEmail(e)
            if (inputType === 'password') inputValidationPassword(e, inputPlaceholder)
            if (inputType === 'vqid') inputValidationVQID(e)
            if (inputType === 'userid') inputValidationUserid(e)
          }}
          onKeyDown={handleKeyDown}
          required
        />
      </div>

      <Typography
        className="signupInputWarning"
        variant="caption"
      >
        {(inputType === 'email' && !isEmailValid && userEmail)
          && languageUtil("INVALID_EMAIL", props.session.language)}
        {(inputType === 'vqid' && !isHex && vqid)
          && languageUtil("INVALID_VQID", props.session.language)}
        {(inputType === 'password' && !isPWValid && !props.passwordUnmatched && (newPassword || confirmPassword))
          && languageUtil("INVALID_PASSWORD", props.session.language)}
        {props.passwordUnmatched
          && languageUtil("CONFIRM_PASSWORD_ERROR", props.session.language)}
      </Typography>
    </div>
  );
}

export default connect(mapStateToProps)(WebsiteInputFieldLarge);
