import "./Function4.css";
import React from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import languageUtil from "../../lib/languageUtil";

function mapStateToProps(state) {
  return { session: state.session, error: state.error }
}

function Function4(props) {
  let [isMouseOver, setIsMouseOver] = React.useState(false);
  let [IsLogin,setIsLogin] = React.useState(false);
  let [IsSignup,setIsSignup] = React.useState(false)

  return (
    <div
      className="function-3 animate-enter3"
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
    >
      <div className="credentials-management-3 titilliumweb-bold-mine-shaft-28px"
        style={isMouseOver ? { color: 'white' } : { color: 'black' }}>
        {languageUtil("CREDENTIALS", props.session.language)}
        {props.session.language === 'english' ? <br /> : ""}
        {languageUtil("MANAGEMENT", props.session.language)}
      </div>
      <Link to="/managementLogin">
        <div className="button-12"
          onMouseEnter = {()=> setIsLogin(true)}
          onMouseLeave={()=> setIsLogin(false)}
          style={isMouseOver ? { borderColor: 'white' } : { borderColor: '#AEB2B6' }}>
          <div className="login-6 titilliumweb-normal-mine-shaft-16px"
            style={isMouseOver && !IsLogin ? { color: 'white' } : { color: 'black' }}>
            {languageUtil("LOGIN", props.session.language).toUpperCase()}
          </div>
          {isMouseOver && !IsLogin
            ? (<img src={require("../../static/img/enter_icon_white.svg").default} alt='arrow' />)
            : (<img src={require("../../static/img/enter_icon.svg").default} alt='arrow' />)
          }
        </div>
      </Link>
      <Link to="/managementSignup">
        <div className="button-13"
          onMouseEnter = {()=> setIsSignup(true)}
          onMouseLeave={()=> setIsSignup(false)}
          style={isMouseOver? { borderColor: 'white' } : { borderColor: '#AEB2B6' }}>
          <div className="sign-up-3 titilliumweb-normal-mine-shaft-16px"
            style={isMouseOver  && !IsSignup  ? { color: 'white' } : { color: 'black' }}>
            {languageUtil("SIGN_UP", props.session.language)}
          </div>
          {isMouseOver && !IsSignup
            ? (<img src={require("../../static/img/enter_icon_white.svg").default} alt='arrow' />)
            : (<img src={require("../../static/img/enter_icon.svg").default} alt='arrow' />)
          }
        </div>
      </Link>
      <div className="rectangle-109-3" style={isMouseOver ? { backgroundColor: 'white' } : { backgroundColor: 'var(--matisse)' }}></div>
    </div>
  );
}

export default connect(mapStateToProps)(Function4);
