import './aboutUs.css';
import React, { Component } from 'react';
import languageUtil from '../../lib/languageUtil';
import WebsiteLanguageSelection2 from '../../components/WebsiteLanguageSelection2';
import NavNavMenu from '../../components/NavNavMenu';
import { connect } from 'react-redux';
import { withRouter } from '../../withRouter';
// import ContactUs from '../../components/ContactUs';
// import { Drawer } from '@mui/material';

function mapStateToProps(state) {
  return { session: state.session, error: state.error }
}
export class AboutUs extends Component {
  //navigate from nav bar
  navigateTo = (path) => {
    this.props.navigate(path)
  }

  render() {
    return (
      <div className="container-center-horizontal13">
        <div className="ab_header">
          <div className="ab_iconlist" onClick={() => { this.props.navigate('/') }}>
            {/* <img className="ab_hkcavvqlogo" style={{ scale: '80%' }} src={require("../../static/img/vq_logo.svg").default} alt="logo" />
            <img className="ab_divline" style={{ scale: '80%' }} src={require("../../static/img/line.svg").default} alt="logo" />
            <img className="ab_hkdchicon" style={{ scale: '80%' }} src={require("../../static/img/HKDCHub_Logo.svg").default} alt="logo" /> */}
            <img className="logo-4" style={{ scale: '80%' }} src={require("../../static/img/left.svg").default} alt="logo" />
          </div>
          <div className="ab_webcontents">
            <NavNavMenu
              navListIem1Props={languageUtil('CONTACT_US', this.props.session.language)}
              navListIem2Props={languageUtil('FAQ', this.props.session.language)}
              navListIem3Props={languageUtil('ABOUT_US', this.props.session.language)}
              navigateTo={(path) => { this.navigateTo(path) }}
              language_selected={this.props.session.language}
            />
            <WebsiteLanguageSelection2 />
          </div>
        </div>
        <div className="ab_contentcontainer">
          <div className='ab_title_contain'>
            {this.props.session.language === 'english'
            ? <img
              className='ab_title_img'              
              src={require("./../../static/img/about_us_title.svg").default}
              alt="AboutUs-title"
              />
            :this.props.session.language === 'cantonese'
              ?<img
              className='ab_title_img'              
              src={require("./../../static/img/about_us_title_hk.svg").default}
              alt="AboutUs-title"
              />
              :<img
              className='ab_title_img'              
              src={require("./../../static/img/about_us_title_cn.svg").default}
              alt="AboutUs-title"
              />
            } 
          </div>
          <div className='ab_context'>
            <div className='ab_subtitle titilliumweb-bold-mine-shaft-24px'>
              {languageUtil('ABOUT_US_Q1', this.props.session.language)}
            </div>
            <div className='ab_content titilliumweb-normal-scarpa-flow-18px'>
              {languageUtil('ABOUT_US_A1', this.props.session.language)}
            </div>
            <div className='ab_subtitle titilliumweb-bold-mine-shaft-24px'>
              {languageUtil('ABOUT_US_Q2', this.props.session.language)}
            </div>
            <div className='ab_content titilliumweb-normal-scarpa-flow-18px'>
              <>
                {languageUtil('ABOUT_US_A2_1', this.props.session.language)}
                <a href={this.props.session.language === 'english'
                  ? 'https://www.itc.gov.hk/en/index.html'
                  : this.props.session.language === 'cantonese'
                    ? 'https://www.itc.gov.hk/ch/index.html'
                    : 'https://www.itc.gov.hk/gb/index.html'
                }>
                  {languageUtil('ABOUT_US_A2_H1', this.props.session.language)}
                </a>
                {languageUtil('ABOUT_US_A2_2', this.props.session.language)}
                <a href={this.props.session.language === 'english'
                  ? 'https://www.hkqf.gov.hk/en/home/index.html'
                  : this.props.session.language === 'cantonese'
                    ? 'https://www.hkqf.gov.hk/tc/home/index.html'
                    : 'https://www.hkqf.gov.hk/sc/home/index.html'
                }>
                  {languageUtil('ABOUT_US_A2_H2', this.props.session.language)}
                </a>
                {languageUtil('ABOUT_US_A2_3', this.props.session.language)}
                <a href={this.props.session.language === 'english'
                  ? 'https://www.hkqr.gov.hk/HKQRPRD/web/hkqr-en/'
                  : this.props.session.language === 'cantonese'
                    ? 'https://www.hkqr.gov.hk/HKQRPRD/web/hkqr-tc/index.html'
                    : 'https://www.hkqr.gov.hk/HKQRPRD/web/hkqr-sc/index.html'
                }>
                  {languageUtil('ABOUT_US_A2_H3', this.props.session.language)}
                </a>
                {languageUtil('ABOUT_US_A2_4', this.props.session.language)}
              </>

            </div>
            <div className='ab_banner_section'>
              <a href={this.props.session.language === 'english'
                ? 'https://www.itc.gov.hk/en/index.html'
                : this.props.session.language === 'cantonese'
                  ? 'https://www.itc.gov.hk/ch/index.html'
                  : 'https://www.itc.gov.hk/gb/index.html'
              }>
                <img
                  src={require("../../static/img/itc_banner.svg").default}
                  alt='icon-add'
                />
              </a>
              <a href={this.props.session.language === 'english'
                ? 'https://www.hkqf.gov.hk/en/home/index.html'
                : this.props.session.language === 'cantonese'
                  ? 'https://www.hkqf.gov.hk/tc/home/index.html'
                  : 'https://www.hkqf.gov.hk/sc/home/index.html'
              }>
                <img
                  src={require("../../static/img/qf_banner.svg").default}
                  alt='icon-add'
                />
              </a>
            </div>
            <div className='ab_subtitle titilliumweb-bold-mine-shaft-24px'>
              {languageUtil('ABOUT_US_Q3', this.props.session.language)}
            </div>
            <div className='ab_content titilliumweb-normal-scarpa-flow-18px'>
              {this.props.session.language === 'english'
                ? <>

                  {languageUtil('ABOUT_US_A3_1', this.props.session.language)}
                  <a href='https://www.hkcaavq.edu.hk/en/'>
                    {languageUtil('ABOUT_US_A3_H1', this.props.session.language)}
                  </a>
                  {languageUtil('ABOUT_US_A3_2', this.props.session.language)}
                  <a href='https://www.elegislation.gov.hk/hk/cap592!en?INDEX_CS=N&xpid=ID_1438403495471_005'>
                    {languageUtil('ABOUT_US_A3_H2', this.props.session.language)}
                  </a>
                  {languageUtil('ABOUT_US_A3_3', this.props.session.language)}
                </>
                : <>
                  <a href={this.props.session.language === 'cantonese'
                    ? 'https://www.hkcaavq.edu.hk/zh-hant/'
                    : 'https://www.hkcaavq.edu.hk/zh-hans/'}>
                    {languageUtil('ABOUT_US_A3_H1', this.props.session.language)}
                  </a>
                  {languageUtil('ABOUT_US_A3_2', this.props.session.language)}
                  <a href={this.props.session.language === 'cantonese'
                    ? 'https://www.elegislation.gov.hk/hk/cap1150!zh-Hant-HK?INDEX_CS=N'
                    : 'https://www.elegislation.gov.hk/hk/cap1150!sc?INDEX_CS=N'}>
                    {languageUtil('ABOUT_US_A3_H2', this.props.session.language)}
                  </a>
                  {languageUtil('ABOUT_US_A3_3', this.props.session.language)}
                  <a href={this.props.session.language === 'cantonese'
                    ? 'https://www.elegislation.gov.hk/hk/cap592!zh-Hant-HK?INDEX_CS=N'
                    : 'https://www.elegislation.gov.hk/hk/cap592!sc?INDEX_CS=N'}>
                    {languageUtil('ABOUT_US_A3_H3', this.props.session.language)}
                  </a>
                  {languageUtil('ABOUT_US_A3_4', this.props.session.language)}
                </>
              }
            </div>
          </div>
        </div>
      </div>

    )
  }
}

export default connect(mapStateToProps)(withRouter(AboutUs));
