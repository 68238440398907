import React from "react";
import languageUtil from "../../lib/languageUtil";
import "./NavNavMenu.css";

function NavNavMenu(props) {
  const { navListIem1Props, navListIem2Props, navListIem3Props, navigateTo } = props;

  function NavListIem(props) {
    const { children } = props;

    const findRouteName = (children) => {
      let languageselection = [];
      languageselection = ["english", "cantonese", "mandarin"];
      for (let i = 0; i < languageselection.length; i++) {
        switch (children) {
          case languageUtil("FAQ", languageselection[i]):
            props.navigateTo("/FAQ");
            break;
          case languageUtil("ABOUT_US", languageselection[i]):
            props.navigateTo("/AboutUs");
            break;
          case languageUtil("CONTACT_US", languageselection[i]):
            props.navigateTo("/Contact");
            break;
          default:
            break;
        }
      }
    }

    return (
      <div className="nav-list-iem">
        <div style={{ width: '100%', height: '100%' }}
          onClick={() => findRouteName(children)}
          className="about-us valign-text-middle titilliumweb-normal-scarpa-flow-16px">
          {children.toUpperCase()}
        </div>
      </div>
    );
  }


  return (
    <div className="link-1">
      <NavListIem
        children={navListIem3Props}
        navigateTo={navigateTo}
      />
      <NavListIem
        children={navListIem2Props}
        navigateTo={navigateTo}
      />
      <NavListIem
        children={navListIem1Props}
        navigateTo={navigateTo}
      />
    </div>
  );
}

export default NavNavMenu;
