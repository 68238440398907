import React, { Component } from 'react'
import './notFound.css';

export class NotFound extends Component {
  render() {
    return (
      <div className='notFoundView '>
        <div style={{margin: 'auto auto', display: 'flex'}}>
          <img style={{ scale: '100%' }} src={require("../../static/img/notFoundStatement.svg").default} alt="notFound" />
        </div>
      </div>
    )
  }
}

export default NotFound