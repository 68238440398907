import "./verificationUploaded.css";
import "../../overlayStyle.css";
import { Buffer } from "buffer";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "../../withRouter";
import { setSession } from "../../actions/session";
import Dropzone from 'react-dropzone';
import Header from "../../components/Header";
import ListUploadingFiles from "../../components/ListUploadingFiles";
import languageUtil from "../../lib/languageUtil";
import errormsgUtil from "../../lib/errormsgUtil";
import axios from "axios";
import CryptoJS from "crypto-js";
import DragDropImg from "../../static/img/drag-drop.svg";

function mapStateToProps(state) {
    return { session: state.session, error: state.error };
}

function mapDispatchToProps(dispatch) {
    return {
        setSession: (session) => dispatch(setSession(session)),
    };
}

export class VerificationUploaded extends Component {
    constructor(props) {
        super(props);
        this.onDrop = (uploadedFiles) => {
            this.onDropFiles(uploadedFiles)
        };
        this.state = {
            drawerToggle: false,
            whichSetting: "",
            uploadedFiles: []
        };
        this.overlay = React.createRef();
        this.fileUpload = React.createRef(null);
    }

    onDropFiles = async (files) => {
        await this.handleFileUpload(files)
    }

    componentDidMount = () => {
        if (this.props.location.state && this.props.location.state.uploadingfiles) {
            this.handleFileUpload(this.props.location.state.uploadingfiles)
        }

        if (
            (this.props.session.uploadedFile && this.props.session.uploadedFile.length > 0) ||
            (this.props.session.uploadedFilesType && this.props.session.uploadedFilesType.length > 0)
        ) {
            this.props.setSession({
                ...this.props.session,
                uploadedFile: [],
                uploadedFilesType: [],
            });
        }
    };

    //loading image
    showOverlay = () => {
        this.overlay.current.style.width = "100%";
    };
    hideOverlay = () => {
        if (this.overlay.current) this.overlay.current.style.width = "0%";
    };

    refreshList = (newList) => {
        this.setState({ uploadedFiles: newList });
    };

    //reference input to combine with the button
    handleFileUploadRef = () => {
        this.fileUpload.current.click();
    };

    /* send post request to the verification api*/
    //1. decrypt uploaded files and sorting into dch(portfolio) files and attachment files.
    //2. send API request for dch(portfolio) files with non-decoded files, and add response to the decrypted files.
    //3. send API request for attachment files with hashed files, and add response to the decrypted files.
    //4. go to the next page and get the decrypted files list.

    //1. decrypt uploaded files and sorting into dch(portfolio) files and attachment files.
    decryptFiles = async () => {
        //decryption
        let decodedFileArray = [];
        let hashedFileArray = [];
        let credFileArray = [];

        for (let i = 0; i < this.state.uploadedFiles.length; i++) {
            let fileToHash = {
                //JSON to hash data
                data: "",
                file_type: "",
            };
            let fileWithHash = {
                //JSON with hashed data to send server API
                filename: "",
                hash: "",
                file_id: "",
                authentication_code: "",
            };
            let decodedFile = {
                //decoded JSON to be used in view page (attachments: file.data - x decoded, credentials: decoded)
                filename: "",
                error: "",
                file: {
                    data: [],
                    file_type: "",
                },
            };
            let result_base64 = await new Promise((resolve) => {
                let fileReader = new FileReader();
                fileReader.onload = (e) => resolve(fileReader.result);
                fileReader.readAsText(this.state.uploadedFiles[i], "UTF-8");
            });

            const str = JSON.parse(
                Buffer.from(result_base64, "base64").toString("utf-8")
            );
            fileToHash.data = str.data;
            fileToHash.file_type = str.file_type;

            if (
                str.file_type.toUpperCase() === "DCH" ||
                str.file_type.toUpperCase() === 'PORTFOLIO'
            ) {
                decodedFile.filename = this.state.uploadedFiles[i].name;
                decodedFile.file.file_type = str.file_type;

                for (let credItem of str.data) {
                    decodedFile.file.data.push(
                        JSON.parse(Buffer.from(credItem, "base64"))
                    );
                }
                decodedFileArray.push({ ...decodedFile });
                credFileArray.push(this.state.uploadedFiles[i]);
            }

            if (
                str.file_type.toUpperCase() !== "DCH" &&
                str.file_type.toUpperCase() !== 'PORTFOLIO'
            ) {
                decodedFile.filename = this.state.uploadedFiles[i].name;
                decodedFile.file.file_type = str.file_type;

                for (let credItem of str.data) {
                    decodedFile.file.data.push(credItem);
                }
                decodedFileArray.push({ ...decodedFile });

                //hash the files for attachment
                const hash = CryptoJS.SHA256(JSON.stringify(fileToHash)).toString();
                fileWithHash.filename = this.state.uploadedFiles[i].name;
                fileWithHash.hash = hash;
                fileWithHash.file_id = str.file_id;
                fileWithHash.authentication_code = str.authentication_code;
                hashedFileArray.push(fileWithHash);
            }
        }

        await this.props.setSession({
            ...this.props.session,
            decodedFile: decodedFileArray,
        })

        return [credFileArray, hashedFileArray];
    };

    credFileRequest = async (credFiles) => {
        //prepare form data for the credential files
        if (credFiles && credFiles.length > 0) {
            const submitFormData = new FormData();
            for (let i = 0; i < credFiles.length; i++) {
                await submitFormData.append("files", credFiles[i]);
            }

            await this.uploadFileSendRequest(
                submitFormData,
                "uploadfile"
            );
        }
    }

    attachFileRequest = async (attachFiles) => {
        //prepare submit data for hashed (non credential) files
        if (attachFiles && attachFiles.length > 0) {
            await this.uploadFileSendRequest(
                attachFiles,
                "uploadattachment"
            );
        }
    }

    passApiRequest = async (processedFiles) => {
        //manage api request for both cred & attach files 
        if (processedFiles[0] && processedFiles[0].length > 0) {
            await this.credFileRequest(processedFiles[0])
        }

        if (processedFiles[1] && processedFiles[1].length > 0) {
            await this.attachFileRequest(processedFiles[1])
        }
        this.hideOverlay()
        //move to next page if there were any api request
        if (this.props.session.uploadedFile && this.props.session.uploadedFile.length > 0) {
            this.props.navigate("/VerificationView");
        }
    };

    //2. send API request for dch(portfolio) files, and add response to the decrypted files.
    uploadFileSendRequest = async (submitData, apiPath) => {

        await axios({
            method: "post",
            url: `/api/corporate_user/${apiPath}`,
            data: submitData,
        })
            .then(async (response) => {
                if (response.data.length > 0) {
                    let listResponse = JSON.parse(JSON.stringify(response.data));

                    if (this.props.session.uploadedFile && this.props.session.uploadedFile.length > 0) {
                        let tempSavedResponse = JSON.parse(JSON.stringify(this.props.session.uploadedFile));
                        for (let j = 0; j < listResponse.length; j++) {
                            await tempSavedResponse.push(listResponse[j])
                            if (listResponse[j].error !== "000") {
                                await window.alert(
                                    errormsgUtil(
                                        listResponse[j].error,
                                        this.props.session.language
                                    ).includes("<filename>")
                                        ? errormsgUtil(
                                            listResponse[j].error,
                                            this.props.session.language
                                        ).replace("<filename>", listResponse[j].filename)
                                        : errormsgUtil(
                                            listResponse[j].error,
                                            this.props.session.language
                                        )
                                );
                            }
                        }
                        await this.props.setSession({
                            ...this.props.session,
                            uploadedFile: tempSavedResponse,
                        });
                    } else {
                        if (response.data.length === 1 && response.data[0].error !== "000") {
                            window.alert(
                                errormsgUtil(
                                    response.data[0].error,
                                    this.props.session.language
                                ).includes("<filename>")
                                    ? errormsgUtil(
                                        response.data[0].error,
                                        this.props.session.language
                                    ).replace("<filename>", response.data[0].filename)
                                    : errormsgUtil(
                                        response.data[0].error,
                                        this.props.session.language
                                    )
                            );
                        } else {
                            await this.props.setSession({
                                ...this.props.session,
                                uploadedFile: listResponse,
                            });
                        }
                    }
                }
            })
            .catch((err) => {
                console.debug(err);
                if (err.response) {
                    console.debug(err.response);
                    console.debug(err.response.status);
                    console.debug(err.message);
                    console.debug(err.response.data);
                    if (err.response.status === 401) {
                        window.alert("Your login session has timeout.  Please re-login.");
                    } else {
                        window.alert(
                            "Error:" +
                            err.message +
                            ". Please report this error status to development team."
                        );
                    }
                } else {
                    window.alert(
                        "Error:" +
                        err.message +
                        ". Please report this error status to development team."
                    );
                    console.debug(err.message);
                }
            })
    };

    //function to rename file 
    renameFile(originalFile, newName) {
        return new File([originalFile], newName, {
            type: originalFile.type,
            lastModified: originalFile.lastModified,
        });
    }

    //function to check if valid file_type 
    checkingValidFile = (file) => new Promise(async (resolve) => {
        let decodedUploadFile = await new Promise((resolve) => {
            let fileReader = new FileReader();
            fileReader.onload = (e) => resolve(fileReader.result);
            fileReader.readAsText(file, "UTF-8");
        });

        try {
            const str = JSON.parse(Buffer.from(decodedUploadFile, "base64").toString("utf-8"));
            resolve(str.file_type.toUpperCase())
        } catch (error) {
            window.alert(languageUtil("INVALID_FILE", this.props.session.language))
        }

    })

    //handle upload files to state
    handleFileUpload = async (uploadingfiles) => {
        let tmpUploadingFiles = [];
        let uploadedFileNames = [];
        let uploadedFileTypes = [];

        // make temporary arrays for uploaded files seperating with filenames 
        for (let i = 0; i < this.state.uploadedFiles.length; i++) {
            tmpUploadingFiles.push(this.state.uploadedFiles[i]);
            uploadedFileNames.push(this.state.uploadedFiles[i].name);
        }

        // loop uploading files to check if the files are not duplicated 
        // 1. if duplicated, update file name and repeat until no duplicated name 
        // 2. if not duplicated, save the file in tmpUploadingFiles 
        for (let j = 0; j < uploadingfiles.length; j++) {
            let duplicatedNum = 1;
            let isDuplicated = false;
            let newFileName = uploadingfiles[j].name;
            let tmpFile = this.renameFile(uploadingfiles[j], newFileName);

            await this.checkingValidFile(uploadingfiles[j]).then((filetype) => {
                const fileTypesInUse = ["DCH", "PORTFOLIO", "PDF", "JPG", "JPEG", "PNG"];
                if (filetype && fileTypesInUse.includes(filetype.toUpperCase())) {
                    while (uploadedFileNames.includes(newFileName)) {
                        if (uploadingfiles[j].name.includes('.')) {
                            newFileName = uploadingfiles[j].name.split('.')[0] + `_${duplicatedNum}.` + uploadingfiles[j].name.split('.')[1];
                        } else {
                            newFileName = uploadingfiles[j].name + `_${duplicatedNum}`
                        }
                        tmpFile = this.renameFile(uploadingfiles[j], newFileName);
                        isDuplicated = true;
                        duplicatedNum += 1;
                    }

                    if (!isDuplicated) {
                        tmpUploadingFiles.push(uploadingfiles[j])
                        uploadedFileNames.push(uploadingfiles[j].name)
                    } else {
                        tmpUploadingFiles.push(tmpFile)
                        uploadedFileNames.push(tmpFile.name)
                    }

                    let tmpFileTypeObj = {};
                    tmpFileTypeObj[newFileName] = filetype;
                    uploadedFileTypes.push(tmpFileTypeObj)
                } else {
                    window.alert(languageUtil("INVALID_FILE", this.props.session.language))
                }
            })
        }
        //save file_type mapped with filename into redux storage.
        if (this.props.session.uploadedFilesType && this.props.session.uploadedFilesType.length > 0) {
            let tmpFiletypeItem = [...this.props.session.uploadedFilesType];
            for (let i = 0; i < this.props.session.uploadedFilesType.length; i++) {
                uploadedFileTypes.splice(i, 0, tmpFiletypeItem[i]);
            }
        }

        this.props.setSession({
            ...this.props.session,
            uploadedFilesType: [...uploadedFileTypes]
        })

        await this.setState({ uploadedFiles: tmpUploadingFiles });
    };

    //delete all uploaded files when delete all button clicked
    handleDeleteAllFiles = () => {
        if(this.state.uploadedFiles.length !== 0){
            this.setState({ uploadedFiles: [] });
            this.props.setSession({
                ...this.props.session,
                uploadedFilesType: [],
            });
            this.props.navigate("/Verification");
        }        
    };

    //delete single uploaded file
    handleDeleteItem = (idx) => {
        let tmpUploadingFiles = [];
        let tmpSessionFiletypes = [];
        let deletingFilename = "";

        for (let i = 0; i < this.state.uploadedFiles.length; i++) {
            if (i !== idx) {
                tmpUploadingFiles.push(this.state.uploadedFiles[i]);
            }
            if (i === idx) deletingFilename = this.state.uploadedFiles[i].name;
        }

        for (let j = 0; j < this.props.session.uploadedFilesType.length; j++) {
            if (Object.keys(this.props.session.uploadedFilesType[j])[0] !== deletingFilename) {
                tmpSessionFiletypes.push(this.props.session.uploadedFilesType[j]);
            }
        }

        this.setState({ uploadedFiles: tmpUploadingFiles });
        this.props.setSession({
            ...this.props.session,
            uploadedFilesType: [...tmpSessionFiletypes]
        })

    };

    render() {
        return (
            <div className="uploaded-corp">
                <div ref={this.overlay} className="overlay_cover">
                    <div className="spinner">
                        <div className="bounce1"></div>
                        <div className="bounce2"></div>
                        <div className="bounce3"></div>
                    </div>
                </div>
                <Header openDrawer={this.openDrawer} isVerification={true} />
                <Dropzone
                    noClick={true}
                    onDrop={this.onDrop}
                    style={{ width: '100%', heigth: '100%' }}
                >
                    {({ getRootProps, getInputProps }) => (
                        <div
                            {...getRootProps({ className: 'dropzone' })}
                            className="contentUploaded-corp"
                            style={{
                                backgroundImage: `url(${DragDropImg})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: "200px",
                                backgroundPosition: "center"
                            }}
                        >
                            <div className="flex-row-8-corp">
                                <div className="title-46-corp valign-text-middle titilliumweb-bold-black-24px">
                                    {languageUtil("UPLOADED_FILES", this.props.session.language)}
                                    {this.state.uploadedFiles.length > 0 && ` (${this.state.uploadedFiles.length})` }
                                </div>
                                <div
                                    className="website-button-s2-ou-corp"
                                    onClick={this.handleFileUploadRef}
                                >
                                    <input {...getInputProps({ className: 'dropzone' })} />
                                    <input
                                        ref={this.fileUpload}
                                        type="file"
                                        style={{ display: "none" }}
                                        onChange={e => this.handleFileUpload(e.target.files)}
                                        onClick={(e) => (e.target.value = null)}
                                        // accept=".attachment, .portfolio, .dch, .transcript, .testimonial, .certificate"
                                        multiple
                                    />
                                    <img
                                        className="icon-add-corp"
                                        src={require("../../static/img/icon---add@2x.svg").default}
                                        alt="icon-add"
                                    />
                                    <div className="add-corp valign-text-middle titilliumweb-semi-bold-white-14px">
                                        {languageUtil("ADD", this.props.session.language)}
                                    </div>
                                </div>
                                <div
                                    className="website-button-s2-b-1-corp"
                                    onClick={this.handleDeleteAllFiles}
                                >
                                    <img
                                        className="icon-trash-corp"
                                        src={
                                            require("../../static/img/icon---trash-full@2x.svg").default
                                        }
                                        alt="icon-trash"
                                    />
                                    <div className="delete-all-corp titilliumweb-semi-bold-pomegranate-14px">
                                        {languageUtil("DELETE_ALL", this.props.session.language)}
                                    </div>
                                </div>
                            </div>
                            <div className="flex-row-9-corp">
                                <ListUploadingFiles
                                    uploadedFiles={this.state.uploadedFiles}
                                    refreshList={this.refreshList}
                                    handleDeleteItem={this.handleDeleteItem}
                                />
                            </div>
                            <div className="flex-row-btn-corp">
                                <div
                                    className="button_-next-2-corp"
                                    onClick={async () => {
                                        if (this.state.uploadedFiles && this.state.uploadedFiles.length > 0) {
                                            //decrypt file to display in view page
                                            await this.showOverlay()
                                            this.decryptFiles().then(
                                                //send api request for credential files and attachement files seperately
                                                this.passApiRequest
                                            )
                                        }
                                    }}
                                    style={
                                        this.state.uploadedFiles && this.state.uploadedFiles.length > 0
                                            ? { backgroundColor: "#006C73", cursor: "pointer" }
                                            : { backgroundColor: "var(--grey)", cursor: "default" }
                                    }
                                >
                                    <div className="next-4-corp valign-text-middle titilliumweb-normal-white-18px">
                                        {languageUtil("NEXT", this.props.session.language)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </Dropzone>
            </div >
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(VerificationUploaded));
