import VocabUS from '../constants/languages/en-US.json';
import VocabHK from '../constants/languages/zh-HK.json';
import VocabCN from '../constants/languages/zh-CN.json';


const returnUS = (fieldName) => {
    return VocabUS[fieldName]
}

const returnHK = (fieldName) => {
    return VocabHK[fieldName]
}

const returnCN = (fieldName) => {
    return VocabCN[fieldName]
}

const languageUtil = (fieldName, language) => {
    switch(language) {
        case 'english':
            return returnUS(fieldName);
        case 'cantonese':
            return returnHK(fieldName);
        case 'mandarin':
            return returnCN(fieldName);
        default:
    }
}

export default languageUtil;