import "./conversionUploaded.css";
import '../../overlayStyle.css';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from '../../withRouter';
import { Drawer } from '@mui/material';
import { getCookie } from "../../util/cookie";
import { clearSession, setSession } from "../../actions/session"
import Dropzone from 'react-dropzone';
import Header from "../../components/Header";
import ListUploadingFiles from "../../components/ListUploadingFiles";
import languageUtil from "../../lib/languageUtil";
import errormsgUtil from "../../lib/errormsgUtil";
import axios from 'axios';
import JSZip from "jszip";
import saveAs from 'file-saver';
import * as XLSX from "xlsx";
import { Buffer } from "buffer";
import ManagementSettingChangePW from "../../components/ManagementSettingChangePW";
import DragDropImg from "../../static/img/drag-drop.svg";

function mapStateToProps(state) {
    return { session: state.session, error: state.error }
}

function mapDispatchToProps(dispatch) {
    return {
        clearSession: () => dispatch(clearSession()),
        setSession: (session) => dispatch(setSession(session))
    }
}

export class ConversionUploaded extends Component {
    constructor(props) {
        super(props);
        this.onDrop = (uploadedFiles) => {
            this.onDropFiles(uploadedFiles)
        };
        this.state = {
            drawerToggle: false,
            whichSetting: '',
            uploadedFiles: [],
        };
        this.overlay = React.createRef();
        this.fileUpload = React.createRef(null);
    }

    //when file added by onDrop 
    onDropFiles = async (uploadingfiles) => {
        await this.setState({ uploadedFiles: [uploadingfiles[0]] })
    }

    componentDidMount = async () => {
        this.props.setSession({
            ...this.props.session,
            conversionContent: []
            
        })
        if (this.props.location.state && this.props.location.state.uploadingfile) {
            this.setState({ uploadedFiles: this.props.location.state.uploadingfile })
        } else {
            await this.fileUpload.current.click();
        }
    }

    componentDidUpdate = async () => {
        if (this.state.uploadedFiles.length > 0) {
            let tmpFilenameArray = this.state.uploadedFiles[0].name.split('.');
            if (tmpFilenameArray[tmpFilenameArray.length - 1] !== 'zip') {
                window.alert(languageUtil("FAIL_OPEN", this.props.session.language));
                this.setState({ uploadedFiles: [] })
				this.props.navigation("/Conversion")
            }
        }
    }

    //loading image 
    showOverlay = () => {
        this.overlay.current.style.width = "100%";
    }
    hideOverlay = () => {
        if (this.overlay.current) this.overlay.current.style.width = "0%";
    }

    //open drawer for the account settings
    openDrawer = (settingItem) => {
        this.setState({
            drawerToggle: true,
            whichSetting: settingItem
        })
    }
    //close drawer to close the account settings 
    closeDrawer = () => {
        this.setState({
            drawerToggle: false,
            whichSetting: ''
        })
    }

    refreshList = (newList) => {
        this.setState({ uploadedFiles: newList })
    }

    // //handle upload files to state
    handleFileUpload = (uploadingfiles) => {
        let file = uploadingfiles[0];
        let fileSize = file.size;
        let maxSize = 1024 * 1024 * 1024; //1GB in bytes

        if (fileSize > maxSize) {
            window.alert(languageUtil("MAX_SIZE_ALERT", this.props.session.language))
        } else {
            this.setState({ uploadedFiles: [file]})
        }
    }

    //reference input to combine with the button
    handleFileUploadRef = () => {
        this.fileUpload.current.click();
    }

    

    handleProcessFile = async () => {
        const previewlist = [];
        function calculate_hash(string) {
            const utf8 = new TextEncoder().encode(string);

            return crypto.subtle.digest("SHA-256", utf8).then((hashBuffer) => {
                const hashArray = Array.from(new Uint8Array(hashBuffer));
                const hashHex = hashArray
                    .map((bytes) => bytes.toString(16).padStart(2, "0"))
                    .join("");
                return hashHex;
            });
        }
        
        

        function dateIsValid(dateStr) {
            const regex = /^\d{2}\/\d{2}\/\d{4}$/;

            if (dateStr.match(regex) === null) {
                return false;
            }

            const [day, month, year] = dateStr.split('/');

            // 👇️ format Date string as `yyyy-mm-dd`
            const isoFormattedStr = `${year}-${month}-${day}`;

            const date = new Date(isoFormattedStr);

            const timestamp = date.getTime();

            if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
                return false;
            }

            return date.toISOString().startsWith(isoFormattedStr);
        }

        const fileInput = this.state.uploadedFiles;
        const mandatory_fields_common = [
            //"Student identification number (e.g. HKID / passport number)",
            "Hash(ID)",
            "First time entry to DCH (Y/N)",
            "Generate credential file (Y/N)",
        ];
        const mandatory_fields_QA = [
            "Serial Number",
            "Issue Date (DD/MM/YYYY)",
            "Internal Reference",
            "Assessment Outcomes",
            "Summary of Educational Qualifications Considered",
            "Attachment 1",
            "Qualification Title",
        ];
        const mandatory_fields_QR = [
            //QR only
            "QR Registration Number",
            "Commencement Date (DD/MM/YYYY)",
            "Certificate Issue Date/Programme Completion Date (DD/MM/YYYY)",
            "Internal Reference",
        ];
        const allowed_extensions = [
            "pdf",
            "jpeg",
            "jpg",
            "png",
        ];


        let checking_log = [];
        let attachments = {};
        
        if (fileInput[0] === undefined) {
            return
        };
        // var filesize = fileInput.files[0].size;
        const language = this.props.session.language;
        const navigation = this.props;
        var reader = new FileReader();
        reader.onload = async (ev) => {
            await this.showOverlay();
            await JSZip.loadAsync(ev.target.result).then(
                async function (zip) {
                    let counter = 0;
                    let output_counter = 0;

                    let user_type = getCookie('operatorType')

                    for (const [key] of Object.entries(zip.files)) {
                        if (key.includes(".xlsm")) {
                            counter += 1
                        }
                    }

                    if (counter !== 1) {
                        window.alert(languageUtil("FAIL_READ", language));
                        return
                    }

                    for (const [key] of Object.entries(zip.files)) {
                        if (key.includes(".xlsm")) {
                            let content = await zip.file(key).async("arraybuffer");

                            var workbook = XLSX.read(content, { type: "buffer" });
							
                            try {
                                
                                // Get the worksheet
                                var worksheet = workbook.Sheets["Sheet1"];
    
                                // Get the value of the version number
                                var version = worksheet["U1"].v;
    
                                // Verify the version number
                                if (version !== "1.0") {
                                    window.alert(languageUtil("WRONG_EXCEL_VERSION", language));
                                    navigation.navigate('/Conversion');
                                    return
                                }
                                var json_extracted = XLSX.utils.sheet_to_json(
                                    workbook.Sheets[workbook.SheetNames[0]],
                                    {
                                        // defval: "",
                                        raw: false,
                                        // cellDates: true,
                                    }
                                );
                            } catch (error) {
                                window.alert(languageUtil("WRONG_EXCEL_VERSION", language));
                                navigation.navigate('/Conversion');
                                return
                            }
                        }
                    }

                    //** BEGIN  pre processing for each row*/
                    for (let i of Object.keys(json_extracted)) {
                        //**drop empty row */
                        if (json_extracted[i]) {
                            for (let [key, value] of Object.entries(json_extracted[i])) {
                                //** drop empty values */
                                try {
                                    if (value.trim() === "" && 
                                    [
                                        "Commencement Date (DD/MM/YYYY)",
                                        "Certificate Issue Date/Programme Completion Date (DD/MM/YYYY)",
                                        "Issue Date (DD/MM/YYYY)",
                                    ].includes(key)) { throw new Error("dummy") }
                                    else if (value.trim().length === 0) {
                                        delete json_extracted[i][key];
                                    }
                                }
                                catch (error) {
                                    // Use to catch invalid date => empty
                                    json_extracted[i][key] = "Dummy";
                                }
                            }
                        }
                    }

                    for (let i of Object.keys(json_extracted)) {
                        let failed_fields = [];
                        let missing_mandatory_fields = [];
                        let remark = [];

                        //** Checking  for QA + QR */
                        //** check mandatory fields */
                        for (let target of mandatory_fields_common) {
                            if (target in json_extracted[i]) {
                                if (json_extracted[i][[target]].trim().length === 0) {
                                    missing_mandatory_fields.push(target);
                                }
                            } else {
                                missing_mandatory_fields.push(target);
                            }
                        }

                        //** check (N,N) pair for QA+QR */
                        if (
                            json_extracted[i]["First time entry to DCH (Y/N)"] === "N" &&
                            json_extracted[i]["Generate credential file (Y/N)"] === "N"
                        ) {
                            remark.push(
                                'First time entry to DCH (Y/N) and Generate credential file (Y/N) cannot be both "N".'
                            );
                        }
                        if (json_extracted[i]["Generate credential file (Y/N)"] === "Y") {
                            output_counter++;
                        }

                        //** separate checking */
                        if (user_type === "QA") {
                            //checking for QA
                            //** check mandatory fields */
                            for (let target of mandatory_fields_QA) {
                                if (
                                    target in json_extracted[i] &&
                                    typeof json_extracted[i][target] == "string"
                                ) {
                                    if (json_extracted[i][[target]].trim().length === 0) {
                                        missing_mandatory_fields.push(target);
                                    }

                                    // ** split into list
                                    if (
                                        [
                                            "Assessment Outcomes",
                                            "Summary of Educational Qualifications Considered",
                                        ].includes(target)
                                    ) {

                                        let temp = json_extracted[i][target].split("\r\n");
                                        json_extracted[i][target] = []

                                        for (let x of temp) {
                                            if (x.trim().length >= 1) {
                                                json_extracted[i][target].push(x)
                                            }
                                        }
                                    }
                                } else {
                                    missing_mandatory_fields.push(target);
                                }
                            }
                        } else {
                            //**checking for QR */
                            for (let target of mandatory_fields_QR) {
                                if (target in json_extracted[i]) {
                                    if (json_extracted[i][[target]].trim().length === 0) {
                                        missing_mandatory_fields.push(target);
                                    }
                                } else {
                                    missing_mandatory_fields.push(target);
                                }
                            }

                            //** check either language of qualification title */
                            if (
                                !("Qualification Title" in json_extracted[i]) &&
                                !("Qualification Title (Chinese)" in json_extracted[i])
                            ) {
                                remark.push(
                                    'Please input either "Qualification Title" or "Qualification Title (Chinese)."'
                                );
                            }

                            //** check either language of granting body */
                            if (
                                !("Granting Body" in json_extracted[i]) &&
                                !("Granting Body (Chinese)" in json_extracted[i])
                            ) {
                                remark.push(
                                    'Please input either "Granting Body" or "Granting Body (Chinese)."'
                                );
                            }
                        }

                        for (let [key, value] of Object.entries(json_extracted[i])) {
                            //** check attachment exist */
                            if (
                                (key.includes("Attachment") ||
                                    key === "Testimonial" ||
                                    key === "Transcript" ||
                                    key === "Certificate") &&
                                value.trim() !== ""
                            ) {
                                let found = false;
                                for (let filename of Object.keys(zip.files)) {
                                    let file_extension = value.split('.').pop().toLowerCase()
                                    if (filename.includes(value) && allowed_extensions.includes(file_extension)) {
                                        found = true;
                                    }
                                }
                                if (!found) {
                                    delete json_extracted[i][key];
                                    failed_fields.push(key);
                                }
                            }

                            //** format dates
                            if (
                                [
                                    "Commencement Date (DD/MM/YYYY)",
                                    "Certificate Issue Date/Programme Completion Date (DD/MM/YYYY)",
                                    "Issue Date (DD/MM/YYYY)",
                                ].includes(key)
                            ) {
                                if (dateIsValid(value)) {
                                    let new_date = value.split("/").reverse().join("-");
                                    json_extracted[i][key] = new_date;
                                } else {
                                    failed_fields.push(key);
                                }

                            }
                        }



                        // output log
                        if (
                            failed_fields.length > 0 ||
                            missing_mandatory_fields.length > 0 ||
                            remark.length > 0
                        ) {
                            checking_log.push({
                                Row: (parseInt(i) + 2).toString(),
                                "Invalid fields": failed_fields.join(","),
                                "Missing mandatory fields":
                                    missing_mandatory_fields.join(","),
                                Remark: remark.join(" "),
                            });
                        }
                    }

                    // if there is no error and there are file to convert!!
                    if (checking_log.length === 0 && output_counter !== 0) {
                        var call_api = true;

                        // all checking pass
                        for (let i of Object.keys(json_extracted)) {
                            for (let [key, value] of Object.entries(json_extracted[i])) {
                                if (
                                    (key.includes("Attachment") ||
                                        key === "Testimonial" ||
                                        key === "Transcript" ||
                                        key === "Certificate") &&
                                    value.trim() !== ""
                                ) {
                                    for (const filename of Object.keys(zip.files)) {
                                        if (value === filename.split('/').at(-1)) {
                                            let data = await zip.file(filename).async("base64");
                                            let attachment_template = {
                                                data: [data],
                                                file_type: value.split(".").pop(),
                                            };
                                            const hash = await calculate_hash(
                                                JSON.stringify(attachment_template)
                                            );

                                            if (!attachments[hash]) {
                                                attachments[hash] = attachment_template
                                            }

                                            //cache attachment
                                            //replace attachment filename by hash
                                            json_extracted[i][key] = hash;
                                        }
                                    }
                                }
                            }
                        }
                    } else {
                        if (user_type === "QA") call_api = false;

                        for (let i of Object.keys(json_extracted)) {
                            for (let [key, value] of Object.entries(json_extracted[i])) {
                                if (
                                    (key.includes("Attachment") ||
                                        key === "Testimonial" ||
                                        key === "Transcript" ||
                                        key === "Certificate") &&
                                    value.trim() !== ""
                                ) {
                                    delete json_extracted[i][key];
                                }
                            }
                        }
                    }

                    if (call_api) {
                        let response = await fetch(
                            `/api/operator/uploadfile_new`,
                            {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: getCookie('accessToken'),
                                },
                                body: JSON.stringify(json_extracted),
                            }
                        );

                        var output_zip = new JSZip();
                        if (response.ok) {
                            let data = await response.json();

                            if (data.error === "000") {
                                let output_excel = [];

                                for (const row of Object.values(data.final_output)) {
                                    let filenames = [];
                                    let student_folder = output_zip.folder(row["Folder name"]);

                                    for (const [i, file] of row["Files"].entries()) {
                                        filenames.push(file.filename);
                                        if (i === 0) {
                                            // dch file
                                            student_folder.file(
                                                file.filename,
                                                btoa(JSON.stringify(file.file))
                                            );
                                            delete file.file["file_id"]
                                            let fullFile = {
                                                filename: file.filename,
                                                files: {
                                                    data: [JSON.parse(Buffer.from(file.file.data[0], "base64").toString("utf-8"))],
                                                    file_type: file.file.file_type,
                                                }
                                            }                    
                                            previewlist.push(fullFile)                                        
                                        } else {
                                            // attachments (optional)
                                            let final_template = attachments[file.hash];
                                            final_template.file_id = file.file_id;

                                            student_folder.file(
                                                file.filename,
                                                btoa(JSON.stringify(final_template))
                                            );
                                        }
                                    }
									
									
									let output_excel_row = {
                                        VQID: row["VQID"],
                                        "Hash(ID)": row["Hash(ID)"],
                                        "Folder name": row["Folder name"],
                                        Files: filenames.join(","),
										Remarks: ""
                                    }
									
									if (row["duplicated"]){
										output_excel_row["Remarks"]= "Duplicated"
									}
                                    output_excel.push(output_excel_row);
                                }

                                try {
                                    let wb = XLSX.utils.book_new();

                                    XLSX.utils.book_append_sheet(
                                        wb,
                                        XLSX.utils.json_to_sheet(output_excel),
                                        "Files"
                                    );

                                    let binary_excel = await XLSX.write(wb, {
                                        type: "binary",
                                    });

                                    output_zip.file(`output-${data.jobid}.xlsx`, binary_excel, {
                                        binary: true,
                                    });
                                } catch (err) {
                                    console.error(err);
                                }
                                output_counter = output_excel.length;
                            } else {
                                for (let i = 0; i < data.log2.length; i++) {
                                    let found = false;
                                    for (let j = 0; j < checking_log.length; j++) {
                                        if (data.log2[i]["Row"] === checking_log[j]["Row"]) {
                                            found = true;

                                            // filter duplicate fields
                                            let temp = data.log2[i]["Invalid fields"]
                                                .split(",")
                                                .filter(
                                                    (item) =>
                                                        checking_log[j]["Missing mandatory fields"]
                                                            .split(",")
                                                            .indexOf(item) < 0
                                                );

                                            if (
                                                temp.length !== 0 &&
                                                checking_log[j]["Invalid fields"] === ""
                                            ) {
                                                checking_log[j]["Invalid fields"] = temp.join();
                                            }
                                        }
                                    }
                                    if (!found) {
                                        checking_log.push(data.log2[i]);
                                    }
                                }
                            }

                        }
                    }
                    if (checking_log.length !== 0) {
                        // sort by row number before output result

                        checking_log.sort((a, b) => {
                            return parseInt(a["Row"]) > parseInt(b["Row"]) ? 1 : -1;
                        });

                        let wb = XLSX.utils.book_new();

                        XLSX.utils.book_append_sheet(
                            wb,
                            XLSX.utils.json_to_sheet(checking_log),
                            "Log"
                        );
                        XLSX.writeFile(wb, "Error.xlsx");
                        window.alert(languageUtil("CONVERSION_ERROR_MESSAGE", language));
                        navigation.navigate('/Conversion');
                    } else if (output_counter === 0) {
                        window.alert(languageUtil("CONVERSION_NO_OUTPUT_MESSAGE", language));
                        navigation.navigate('/Conversion');
                    } else {
                        await output_zip
                            .generateAsync({ type: "blob" })
                            .then(function (content) {
                                // see FileSaver.js
                                saveAs(content, "output.zip");
                            });
                        window.alert(languageUtil("CONVERSION_SUCCESS_MESSAGE", language));
                        await navigation.setSession({
                            ...navigation.session,
                            conversionContent: previewlist 
                        })
                        navigation.navigate('/ConversionPreview');
                       
                    }
                }).catch(function (err) {
                    window.alert(languageUtil("FAIL_OPEN", language) + " : " + err);
                    console.error(languageUtil("FAIL_OPEN", language) + " : " + err);
                    navigation.navigate('/Conversion');
                }).finally(this.hideOverlay);
        };
        reader.onerror = await function (err) {
            window.alert(languageUtil("FAIL_READ", language) + " : " + err);
            console.error(languageUtil("FAIL_READ", language) + " : " + err);
            navigation.navigate('/Conversion');
        };
        await reader.readAsArrayBuffer(fileInput[0]);
    }

    //delete all uploaded files when delete all button clicked 
    handleDeleteAllFiles = () => {
        this.setState({ uploadedFiles: [] })
    }

    //delete single uploaded file 
    handleDeleteItem = (idx) => {
        let tmpUploadingFiles = [];

        for (let i = 0; i < this.state.uploadedFiles.length; i++) {
            if (i !== idx) {
                tmpUploadingFiles.push(this.state.uploadedFiles[i])
            }
        }
        this.setState({ uploadedFiles: tmpUploadingFiles })
    }

    //send new password information to the API server. 
    submitOperatorChangePW = async (oldPassword, confirmPassword) => {
        const submitData = {
            'oldpassword': oldPassword,
            'newpassword': confirmPassword
        }

        this.closeDrawer();
        this.showOverlay();
        await axios({
            method: 'post',
            url: `/api/operator/changepassword`,
            data: submitData,
            headers: {
                "Authorization": getCookie('accessToken')
            }
        })
            .then((response) => {
                if (response.data) {
                    if (response.data.error !== '000') {
                        window.alert(errormsgUtil(response.data.error, this.props.session.language))
                    } else {
                        window.alert(languageUtil("SUCCEED_CHANGEPW", this.props.session.language))
                        this.closeDrawer();
                        this.props.navigate("/ConversionLogin");
                    }
                }
            })
            .catch((err) => {
                console.debug(err);
                if (err.response) {
                    console.debug(err.response);
                    console.debug(err.response.status);
                    console.debug(err.message);
                    console.debug(err.response.data);
                    if (err.response.status === 401) {
                        window.alert("Your login session has timeout.  Please re-login.");
                    } else {
                        window.alert("Error:" + err.message + ". Please report this error status to development team.")
                    }
                } else {
                    window.alert("Error:" + err.message + ". Please report this error status to development team.")
                    console.debug(err.message);
                }
            }).finally(this.hideOverlay);
    }

    render() {
        return (
            <div className="uploaded-op">
                <div ref={this.overlay} className="overlay_cover">
                    <div className="spinner">
                        <div className="bounce1"></div>
                        <div className="bounce2"></div>
                        <div className="bounce3"></div>
                    </div>
                </div>
                <Header
                    openDrawer={this.openDrawer}
                    isConversion={true}
                />
                <Dropzone
                    noClick={true}
                    onDrop={this.onDrop}
                    style={{ width: '100%', heigth: '100%' }}
                >
                    {({ getRootProps, getInputProps }) => (
                        <div
                            {...getRootProps({ className: 'dropzone' })}
                            className="contentUploaded-op"
                            style={{
                                backgroundImage: `url(${DragDropImg})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: "200px",
                                backgroundPosition: "center"
                            }}
                        >
                            <div className="flex-row-8-op">
                                {this.state.uploadedFiles.length === 0 && (<div
                                    className="website-button-s2-cv-op"
                                    onClick={this.handleFileUploadRef}
                                >
                                    <input {...getInputProps({ className: 'dropzone' })} />
                                    <input
                                        ref={this.fileUpload}
                                        type="file"
                                        style={{ display: "none" }}
                                        onChange={e => this.handleFileUpload(e.target.files)}
                                        onClick={(e) => e.target.value = null}
                                        accept=".zip"
                                    />
                                    <img
                                        className="icon-add-op"
                                        src={require("../../static/img/icon---add@2x.svg").default}
                                        alt='icon-add'
                                    />
                                    <div
                                        className="add-op valign-text-middle titilliumweb-semi-bold-white-14px"
                                    >
                                        {languageUtil("ADD", this.props.session.language)}
                                    </div>
                                </div>)}
                            </div>
                            <div
                                className="flex-row-op"
                            >
                                <ListUploadingFiles
                                    uploadedFiles={this.state.uploadedFiles}
                                    refreshList={this.refreshList}
                                    handleDeleteItem={this.handleDeleteItem}
                                    isConversion={true}
                                />
                            </div>
                            <div className="flex-row-btn-op">
                                {this.state.uploadedFiles.length > 0 && (<div className="button_-next-2-op"
                                    onClick={() => {
                                        if (this.state.uploadedFiles && this.state.uploadedFiles.length > 0) {
                                            this.handleProcessFile();
                                        }
                                    }}
                                    style={this.state.uploadedFiles && this.state.uploadedFiles.length > 0
                                        ? { backgroundColor: 'var(--cherry-pie)', cursor: 'pointer' }
                                        : { backgroundColor: 'var(--grey)', cursor: 'default' }
                                    }
                                >
                                    <div className="next-op valign-text-middle titilliumweb-normal-white-18px">
                                        {languageUtil("START", this.props.session.language)}
                                    </div>
                                </div>)}
                            </div>
                        </div>
                    )}
                </Dropzone>
                <Drawer
                    anchor='right'
                    open={this.state.drawerToggle}
                    onClose={this.closeDrawer}
                >
                    {this.state.whichSetting === 'reset_password' && (
                        <ManagementSettingChangePW
                            closeDrawer={this.closeDrawer}
                            submitOperatorChangePW={this.submitOperatorChangePW}
                            convertChangePW={true}
                        />
                    )}

                </Drawer>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ConversionUploaded))