import "./ManagementSettingChangeEmail.css";
import React, { useRef, useEffect }  from "react";
import { connect } from 'react-redux';
import WebsiteInputFieldSmall from "../WebsiteInputFieldSmall";
import WebsiteButtonS1B from "../WebsiteButtonS1B";
import languageUtil from "../../lib/languageUtil";

function mapStateToProps(state) {
  return { session: state.session, error: state.error }
}

function ManagementSettingChangeEmail(props) {
  const [newPersonalEmail, setNewPersonalEmail] = React.useState('');
  const [oldPersonalEmail, setOldPersonalEmail] = React.useState('');
  const [oldEmailValid, setOldEmailValid] = React.useState(false);
  const [newEmailValid, setNewEmailValid] = React.useState(false);

  const mounted = useRef();
  useEffect(() => {
    let emailFormatRegex = /\S+@\S+\.\S+/;
    if (!mounted.current) {
      // do componentDidMount logic
      mounted.current = true;
    } else {
      // do componentDidUpdate logic
      setOldEmailValid(emailFormatRegex.test(oldPersonalEmail))
      setNewEmailValid(emailFormatRegex.test(newPersonalEmail))
    }
  }, [newPersonalEmail, oldPersonalEmail]);

  return (
    <>
      <div className="overlap-group-67">
        <div className="title-51 valign-text-middle titilliumweb-bold-black-18px">
          {languageUtil("CHANGE_PERSONAL_EMAIL", props.session.language)}
        </div>
        <img 
          className="icon-32" 
          src={require("../../static/img/-icon-7@2x.svg").default} 
          alt='close' 
          onClick={props.closeDrawer}
        />
      </div>

      <div className="setting_changeEmail_container">
        <WebsiteInputFieldSmall
          inputType='email'
          inputPlaceholder="ORIGINAL_PERSONAL_EMAIL"
          setOldPersonalEmail={setOldPersonalEmail}
          oldPersonalEmail={oldPersonalEmail}
        />
        <WebsiteInputFieldSmall
          inputType='email'
          inputPlaceholder="NEW_PERSONAL_EMAIL"
          setNewPersonalEmail={setNewPersonalEmail}
          newPersonalEmail={newPersonalEmail}
        />
        <div style={{marginTop:'auto', marginBottom:'20px'}}>
          <WebsiteButtonS1B 
            disableConfirm={!newPersonalEmail || !oldPersonalEmail || !newEmailValid || !oldEmailValid}
            className='button_-confirm-1'
            btnText={languageUtil("CONFIRM", props.session.language)}
            indivChangePersonalEmail={true}
            submitIndivChangePrimaryEmail={() => props.submitIndivChangePrimaryEmail(oldPersonalEmail, newPersonalEmail)}
          />
        </div>
      </div>
    </>
  );
}

export default connect(mapStateToProps)(ManagementSettingChangeEmail);
