import './contactUs.css'
import React, { Component } from 'react'
import languageUtil from "../../lib/languageUtil"
import WebsiteLanguageSelection2 from '../../components/WebsiteLanguageSelection2'
import NavNavMenu from '../../components/NavNavMenu'
import { connect } from 'react-redux';
import { withRouter } from '../../withRouter';
import ButtonMailto from '../../components/ButtonMailto'

function mapStateToProps(state) {
  return { session: state.session, error: state.error }
}
export class ContactUs extends Component {

  //navigate from nav bar
  navigateTo = (path) => {
    this.props.navigate(path)
  }
  render() {
    return (
      <div className="cu_container-horizontal">
        <div className='cu_header'>
          <div className='cu_iconlist'
            onClick={() => { this.props.navigate('/') }}
          >
            <img className="logo-4" style={{ scale: '80%' }} src={require("../../static/img/left.svg").default} alt="logo" />
            {/* <img className='cu_hkcavvqlogo' style={{ scale: '80%' }} src={require("../../static/img/vq_logo.svg").default} alt="logo" />
            <img className='cu_divline' style={{ scale: '80%' }} src={require("../../static/img/line.svg").default} alt="logo" />
            <img className='cu_hkdchicon' style={{ scale: '80%' }} src={require("../../static/img/HKDCHub_Logo.svg").default} alt="logo" /> */}
          </div>
          <div className='cu_webcontents'>
            <NavNavMenu
              navListIem1Props={languageUtil('CONTACT_US', this.props.session.language)}
              navListIem2Props={languageUtil('FAQ', this.props.session.language)}
              navListIem3Props={languageUtil('ABOUT_US', this.props.session.language)}
              navigateTo={(path) => { this.navigateTo(path) }}
              language_selected={this.props.session.language}
            />
            <WebsiteLanguageSelection2 />
          </div>
        </div>
        <div className='cu_main_contain'>
          <div className="cu_title titilliumweb-black-martinique-64px">
          {this.props.session.language === 'english'
            ? <img
              className='cu_title_img'              
              src={require("./../../static/img/contact_page_title.svg").default}
              alt="contact-title"
              />
            :this.props.session.language === 'cantonese'
              ?<img
              className='cu_title_img'              
              src={require("./../../static/img/contact_page_title_hk.svg").default}
              alt="contact-title"
              />
              :<img
              className='cu_title_img'              
              src={require("./../../static/img/contact_page_title_cn.svg").default}
              alt="contact-title"
              />
            }
          </div>
          <div className='cu_content_container'>
            <div className='cu_body' >
              {languageUtil("CONTACT_US_DETAILS", this.props.session.language)}
              <div>
              {languageUtil("CONTACT_US_DETAILS2", this.props.session.language)}
              </div>         
            </div>
            <div className='cu_details'>
              <div className='cu_details_left'>

                <div className='cu_group_content'>
                  <div className='cu_field'>
                    {languageUtil("CONTACT_US_ADDRESS", this.props.session.language)}
                  </div>
                  <div className='cu_content'>
                    {languageUtil("CONTACT_US_ADDRESS_DETAIL", this.props.session.language)}
                  </div>
                </div>
                <div className='cu_group_content'>
                  <div className='cu_field'>
                    {languageUtil("CONTACT_US_TEL", this.props.session.language)}
                  </div>
                  <div className='cu_content'>
                    {languageUtil("CONTACT_US_TEL_DETAIL", this.props.session.language)}
                  </div>
                </div>
                <div className='cu_group_content'>
                  <div className='cu_field'>
                    {languageUtil("CONTACT_US_FAX", this.props.session.language)}
                  </div>
                  <div className='cu_content'>
                    {languageUtil("CONTACT_US_FAX_DETAIL", this.props.session.language)}
                  </div>
                </div>
                <div className='cu_group_content'>
                  <div className='cu_field'>
                    {languageUtil("CONTACT_US_EMAIL", this.props.session.language)}
                  </div>
                  <div className='cu_content'>
                    <ButtonMailto label={languageUtil("CONTACT_US_EMAIL_DETAIL_HYPER", this.props.session.language)} mailto="mailto:enquiry@hkdchub.edu.hk"/>
                  </div>
                </div>
                <div className='cu_group_content'>
                  <div className='cu_field'>
                    {languageUtil("CONTACT_US_TIME", this.props.session.language)}
                  </div>
                  <div>
                    <div className='cu_content' >
                      {languageUtil("CONTACT_US_TIME_DETAIL_1", this.props.session.language)}
                    </div>
                    <div className='cu_content' >
                      {languageUtil("CONTACT_US_TIME_DETAIL_2", this.props.session.language)}
                    </div>
                    <div className='cu_content' >
                      {languageUtil("CONTACT_US_TIME_DETAIL_3", this.props.session.language)}
                    </div>
                    {/*
                      <div className='cu_content' >
                      {languageUtil("CONTACT_US_TIME_DETAIL_4", this.props.session.language)}
                    </div>

                    */}
                    </div>
                </div>
              </div>

            </div>
            <div className='cu_location'>
              <iframe width="840" height="370" title="gmap_location" style={{ borderRadius: '15px' }}
                src={`https://maps.google.com/maps?q=Hong+Kong+Council+for+Accreditation+of+Academic+and+Vocational+Qualifications&z=18&output=embed`}
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default connect(mapStateToProps)(withRouter(ContactUs));