import React, { Component } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './routes/home';
import ManagementLogin from './routes/managementLogin';
import ManagementSignup from './routes/managementSignup';
import FAQ from './routes/faq';
import AboutUs from './routes/aboutUs';
import ContactUs from './routes/contactUs';
import ManagementChangePW from './routes/managementChangePW';
import ManagementForgotEmail from './routes/managementForgotEmail';
import ManagementForgotPW from './routes/managementForgotPW';
import Management from './routes/management';
import ManagementUploaded from './routes/managementUploaded';
import PrivateRoutes from './routes/PrivateRoute';
// import SelectFunctions from './routes/selectFunctions';
import ViewCredentials from './routes/viewCredentials';
import MakePortfolio from './routes/makePortfolio';
import MakePortfolioPreview from './routes/makePortfolioPreview';
import ShareCredentials from './routes/shareCredentials';

import Verification from './routes/verification';
import VerificationUploaded from './routes/verificationUploaded';
import VerificationView from './routes/verificationView';

import ConversionLogin from './routes/conversionLogin';
import Conversion from './routes/conversion';
import ConversionForgotPW from './routes/conversionForgotPW';
import ConversionChangePW from './routes/conversionChangePW';
import ConversionUploaded from './routes/conversionUploaded';

import PersonalEmailActive from './routes/personalEmailActive';
import BackupEmailActive from './routes/backupEmailActive';
import ExpiredActive from './routes/expiredActive';
import NotFound from './routes/notFound';

import ConversionPreview from './routes/conversionPreview';
import ConversionPreviewUpload from './routes/conversionPreviewUpload'

import OperatorLog from "./routes/operatorLog";

export default class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/ManagementLogin" element={<ManagementLogin />} />
          <Route exact path="/ManagementForgotEmail" element={<ManagementForgotEmail />} />
          <Route exact path="/ManagementForgotPW" element={<ManagementForgotPW />} />
          <Route exact path="/ManagementSignup" element={<ManagementSignup />} />
          <Route exact path="/Contact" element={<ContactUs />} />
          <Route exact path="/FAQ" element={<FAQ />} />
          <Route exact path="/AboutUs" element={<AboutUs />} />

          <Route exact path="/Verification" element={<Verification />} />
          <Route exact path="/VerificationUploaded" element={<VerificationUploaded />} />
          <Route exact path="/VerificationView" element={<VerificationView />} />

          <Route exact path="/ConversionLogin" element={<ConversionLogin />} />
          <Route exact path="/ConversionForgotPW" element={<ConversionForgotPW />} />
          {/* activated Eamil address */}
          <Route exact path="/PersonalEmailActive" element={<PersonalEmailActive />} />
          <Route exact path="/BackupEmailActive" element={<BackupEmailActive />} />
          <Route exact path="/ExpiredActive" element={<ExpiredActive />} />
          <Route exact path="/NotFound" element={<NotFound />} />

          <Route element={<PrivateRoutes />}>
            <Route exact path="/ManagementChangePW" element={<ManagementChangePW />} />
            <Route exact path="/ConversionChangePW" element={<ConversionChangePW />} />
            <Route exact path="/Management" element={<Management />} />
            <Route exact path="/Conversion" element={<Conversion />} />
            <Route exact path="/ManagementUploaded" element={<ManagementUploaded />} />
            <Route exact path="/ConversionUploaded" element={<ConversionUploaded />} />
            {/* <Route exact path="/SelectFunctions" element={<SelectFunctions />} /> */}
            <Route exact path="/ViewCredentials" element={<ViewCredentials />} />
            <Route exact path="/MakePortfolio" element={<MakePortfolio />} />
            <Route exact path="/MakePortfolioPreview" element={<MakePortfolioPreview />} />
            <Route exact path="/ShareCredentials" element={<ShareCredentials />} />

            <Route exact path="/ConversionPreview" element={<ConversionPreview />} />
            <Route exact path="/ConversionPreviewUpload" element={<ConversionPreviewUpload />} />
            <Route exact path="/OperatorLog" element={<OperatorLog />} />
          </Route>
        </Routes>
      </BrowserRouter>
    )
  }
}
