import "./HeaderWithHome.css";
import React from "react";
import { connect } from 'react-redux';
import { withRouter } from '../../withRouter';
import languageUtil from "../../lib/languageUtil";
import { setSession } from "../../actions/session";
import { Button,Menu,MenuItem } from "@mui/material";

function mapStateToProps(state) {
  return { session: state.session, error: state.error }
}

function mapDispatchToProps(dispatch) {
  return {
    setSession: (session) => dispatch(setSession(session))
  }
}

//select functions header 
function HeaderWithHome(props) {
  const { title } = props;
  const [anchorEl_lang, setAnchorEl_lang] = React.useState(null)    //open or close the selecting languages 
  const openLanguage = Boolean(anchorEl_lang);

  const handleChangeLanguage = (newValue) => {

    props.setSession({
      ...props.session,
      language: newValue
    })
    setAnchorEl_lang(null);
  }
  const handleLanguageClick = (event) => {
    setAnchorEl_lang(event.currentTarget);
  };
  const handleLanguageClose = () => {
    setAnchorEl_lang(null);
  };

  const handleGoBack = () => {
    switch (title) {
      case "VIEW":
      case "MAKE_PORTFOLIO":
      case "SHARE":
        props.navigate("/ManagementUploaded")
        break;
      default:
        break;
    }
  }

  return (
    <div className="header_selectFunction">
      <div className="overlap-group-1">
        {/* home button*/}
        {/* <div
          className="back_button"
          onClick={() => props.navigate("/ManagementUploaded")}
        >
          <img className="icon-back" src={require("../../static/img/home_button.svg").default} alt="icon" />
        </div> */}
        <img src={require("../../static/img/line@2x.svg").default} alt="icon" />
        <div
          className="back_button"
          onClick={handleGoBack}
        >
          <img className="icon-back" src={require("../../static/img/icon---back@2x.svg").default} alt="icon" />
        </div>
        <img src={require("../../static/img/line@2x.svg").default} alt="icon" />
        <div className="header_title titilliumweb-black-mine-shaft-18px">{languageUtil(title, props.session.language)}</div>
        <div className="button_container_home">
        <Button
            className="link-4"
            aria-controls={openLanguage ? 'language_select' : undefined}
            aria-haspopup="true"
            aria-expanded={openLanguage ? 'true' : undefined}
            onClick={handleLanguageClick}
          >
            <img className="icon-22" src={require("../../static/img/-icon@2x.svg").default} alt="icon" />
          </Button>
          <Menu
            id="language_select"
            anchorEl={anchorEl_lang}
            open={openLanguage}
            onClose={handleLanguageClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem value={'english'}
              onClick={() => handleChangeLanguage('english')}
            >
              <div className="menu_content_header">
                English
              </div>
            </MenuItem>
            <MenuItem value={'cantonese'}
              onClick={() => handleChangeLanguage('cantonese')}
            >
              <div className="menu_content_header">
                繁體中文
              </div>
            </MenuItem>
            <MenuItem value={'mandarin'}
              onClick={() => handleChangeLanguage('mandarin')}
            >
              <div className="menu_content_header">
                简体中文
              </div>
            </MenuItem>
          </Menu>
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(HeaderWithHome));
