import React from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';

function mapStateToProps(state) {
  return { session: state.session, error: state.error }
}

function ButtonMailto({mailto , label}) {
  return(
    <Link
            to='#'
            onClick={(e) => {
                window.location.href = mailto;
                e.preventDefault();
                
            }}>
            {label}
        </Link>
  )
}

export default connect(mapStateToProps)(ButtonMailto);
