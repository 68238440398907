import "./management.css";
import '../../overlayStyle.css';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Buffer } from "buffer";
import { withRouter } from '../../withRouter';
import { Link } from "react-router-dom";
import { Drawer } from '@mui/material';
import { setCookie, getCookie } from "../../util/cookie";
import Header from "../../components/Header";
import Steps from "../../components/Steps";
import languageUtil from "../../lib/languageUtil";
import errormsgUtil from "../../lib/errormsgUtil";
import axios from 'axios';
import ManagementSettingChangePW from "../../components/ManagementSettingChangePW";
import ManagementSettingChangeEmail from "../../components/ManagementSettingChangeEmail";
import ManageSettingBackupEmail from "../../components/ManagementSettingBackUpEmail";
import Dropzone from 'react-dropzone';

function mapStateToProps(state) {
    return { session: state.session, error: state.error }
}

export class Management extends Component {
    constructor(props) {
        super(props);
        this.onDrop = (uploadedFiles) => {
            this.onDropFiles(uploadedFiles)
        };
        this.state = {
            drawerToggle: false,
            whichSetting: ''
        };
        this.overlay = React.createRef();
    }

    //when file added by onDrop 
    onDropFiles = async (file) => {

        const checkingValidFile = new Promise(async (resolve) => {
            let decodedUploadFile = await new Promise((resolve) => {
                let fileReader = new FileReader();
                fileReader.onload = (e) => resolve(fileReader.result);
                fileReader.readAsText(file[0], "UTF-8");
            });

            try {
                const str = JSON.parse(Buffer.from(decodedUploadFile, "base64").toString("utf-8"));
                resolve(str.file_type.toUpperCase())
            } catch (error) {
                window.alert(languageUtil("INVALID_FILE", this.props.session.language))
            }

        })


        checkingValidFile
            .then(async (uploadedFileType) => {
                const fileTypesInUse = ["DCH", "PORTFOLIO", "PDF", "JPG", "JPEG", "PNG"];

                if (uploadedFileType && fileTypesInUse.includes(uploadedFileType.toUpperCase())) {
                    //save file_type attribute to cookie with the file name 
                    let tmpUploadedFilesType = [];
                    if (getCookie("uploadedFilesType")) {
                        tmpUploadedFilesType = JSON.parse(JSON.stringify(getCookie("uploadedFilesType")));
                    }
                    let tmpObjKey = file[0].name;
                    let fileNameType = {};
                    fileNameType[tmpObjKey] = uploadedFileType;
                    tmpUploadedFilesType.push(fileNameType)
                    if (tmpUploadedFilesType && tmpUploadedFilesType.length > 0) {
                        await setCookie("uploadedFilesType", tmpUploadedFilesType)
                    }

                    await this.sendRequestAddCred(file[0])
                } else {
                    window.alert(languageUtil("INVALID_FILE", this.props.session.language))
                }
            })
    }

    //check and save the backup email information.
    componentDidMount = async () => {
        if (!getCookie("backupEmail")) {
            this.showOverlay();
            await axios({
                method: 'post',
                url: `/api/auth/viewbackupemail`,
                headers: {
                    "Authorization": getCookie('accessToken')
                }
            }).then((response) => {
                if (response.data) {
                    if (response.data.error !== '000') {
                        // window.alert(errormsgUtil(response.data.error, this.props.session.language))
                    } else {
                        setCookie('backupEmail', response.data.partial_email)
                    }
                }
            }).catch((err) => {
                console.debug(err);
                if (err.response) {
                    console.debug(err.response);
                    console.debug(err.response.status);
                    console.debug(err.message);
                    console.debug(err.response.data);
                    if (err.response.status === 401) {
                        window.alert("Your login session has timeout.  Please re-login.");
                    } else {
                        window.alert("Error:" + err.message + ". Please report this error status to development team.")
                    }
                } else {
                    window.alert("Error:" + err.message + ". Please report this error status to development team.")
                    console.debug(err.message);
                }
            }).finally(this.hideOverlay);
        }
    }

    //loading image 
    showOverlay = () => {
        this.overlay.current.style.width = "100%";
    }
    hideOverlay = () => {
        if (this.overlay.current) this.overlay.current.style.width = "0%";
    }

    //open drawer for the account settings
    openDrawer = (settingItem) => {
        this.setState({
            drawerToggle: true,
            whichSetting: settingItem
        })
    }
    //close drawer to close the account settings 
    closeDrawer = () => {
        this.setState({
            drawerToggle: false,
            whichSetting: ''
        })
    }

    sendRequestAddCred = async (fileToAdd) => {
        var submitFormData = new FormData();
        submitFormData.append('file', fileToAdd)

        this.showOverlay();
        await axios({
            method: 'post',
            url: `/api/portal/addcredential`,
            data: submitFormData,
            headers: {
                "Authorization": getCookie('accessToken')
            }
        }).then((response) => {
            if (response.data) {
                if (response.data.error !== '000') {
                    window.alert(errormsgUtil(response.data.error, this.props.session.language))
                } else {
                    this.props.navigate('/ManagementUploaded')
                }

            }
        }).catch((err) => {
            console.debug(err);
            if (err.response) {
                console.debug(err.response);
                console.debug(err.response.status);
                console.debug(err.message);
                console.debug(err.response.data);
                if (err.response.status === 401) {
                    window.alert("Your login session has timeout.  Please re-login.");
                } else {
                    window.alert("Error:" + err.message + ". Please report this error status to development team.")
                }
            } else {
                window.alert("Error:" + err.message + ". Please report this error status to development team.")
                console.debug(err.message);
            }
        }).finally(this.hideOverlay);
    }

    //send new password information to the API server. 
    submitIndivChangePW = async (oldPassword, confirmPassword) => {
        const submitData = {
            'oldpassword': oldPassword,
            'newpassword': confirmPassword
        }

        this.closeDrawer();
        this.showOverlay();
        await axios({
            method: 'post',
            url: `/api/auth/changepassword`,
            data: submitData,
            headers: {
                "Authorization": getCookie('accessToken')
            }
        }).then((response) => {
            if (response.data) {
                if (response.data.error !== '000') {
                    window.alert(errormsgUtil(response.data.error, this.props.session.language))
                } else {
                    window.alert(languageUtil("SUCCEED_CHANGEPW", this.props.session.language))
                    this.closeDrawer();
                    this.props.navigate("/ManagementLogin");
                }
            }
        }).catch((err) => {
            console.debug(err);
            if (err.response) {
                console.debug(err.response);
                console.debug(err.response.status);
                console.debug(err.message);
                console.debug(err.response.data);
                if (err.response.status === 401) {
                    window.alert("Your login session has timeout.  Please re-login.");
                } else {
                    window.alert("Error:" + err.message + ". Please report this error status to development team.")
                }
            } else {
                window.alert("Error:" + err.message + ". Please report this error status to development team.")
                console.debug(err.message);
            }
        }).finally(this.hideOverlay);
    }

    //send new primary email to the API server. 
    submitIndivChangePrimaryEmail = async (oldEmail, newEmail) => {
        const submitData = {
            'oldemail': oldEmail,
            'newemail': newEmail
        }

        this.closeDrawer();
        this.showOverlay();
        await axios({
            method: 'post',
            url: `/api/auth/changeprimaryemail`,
            data: submitData,
            headers: {
                "Authorization": getCookie('accessToken')
            }
        }).then((response) => {
            if (response.data) {
                if (response.data.error !== '000') {
                    window.alert(errormsgUtil(response.data.error, this.props.session.language))
                } else {
                    window.alert(languageUtil("SUCCEED_CHANGE_EMAIL", this.props.session.language))
                    this.closeDrawer();
                    this.props.navigate("/ManagementLogin");
                }
            }
        }).catch((err) => {
            console.debug(err);
            if (err.response) {
                console.debug(err.response);
                console.debug(err.response.status);
                console.debug(err.message);
                console.debug(err.response.data);
                if (err.response.status === 401) {
                    window.alert("Your login session has timeout.  Please re-login.");
                } else {
                    window.alert("Error:" + err.message + ". Please report this error status to development team.")
                }
            } else {
                window.alert("Error:" + err.message + ". Please report this error status to development team.")
                console.debug(err.message);
            }
        }).finally(this.hideOverlay);
    }

    //send new backup email to the API server. 
    submitIndivBackupEmail = async (backupEmail) => {
        const submitData = {
            'backup_email': backupEmail
        }

        this.closeDrawer();
        this.showOverlay();
        await axios({
            method: 'post',
            url: `/api/auth/setbackupemail`,
            data: submitData,
            headers: {
                "Authorization": getCookie('accessToken')
            }
        }).then((response) => {
            if (response.data) {
                if (response.data.error !== '000') {
                    window.alert(errormsgUtil(response.data.error, this.props.session.language))
                } else {
                    window.alert(languageUtil("SUCCEED_BACKUP_EMAIL", this.props.session.language))
                    this.closeDrawer();
                }
            }
        }).catch((err) => {
            console.debug(err);
            if (err.response) {
                console.debug(err.response);
                console.debug(err.response.status);
                console.debug(err.message);
                console.debug(err.response.data);
                if (err.response.status === 401) {
                    window.alert("Your login session has timeout.  Please re-login.");
                } else {
                    window.alert("Error:" + err.message + ". Please report this error status to development team.")
                }
            } else {
                window.alert("Error:" + err.message + ". Please report this error status to development team.")
                console.debug(err.message);
            }
        }).finally(this.hideOverlay);
    }


    render() {
        return (
            <div className="management">
                <div ref={this.overlay} className="overlay_cover">
                    <div className="spinner">
                        <div className="bounce1"></div>
                        <div className="bounce2"></div>
                        <div className="bounce3"></div>
                    </div>
                </div>
                <Header
                    openDrawer={this.openDrawer}
                    isIndiv={true}
                />
                <div className="management_page_info titilliumweb-bold-black-24px">
                    {languageUtil("CREDENTIALS_MANAGEMENT", this.props.session.language)}
                </div>
                <Dropzone
                    noClick={true}
                    onDrop={this.onDrop}
                    style={{ width: '100%', heigth: '100%' }}
                >
                    {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps({ className: 'dropzone' })} className="upload_-field-1">
                            <input {...getInputProps({ className: 'dropzone' })} />
                            <img style={{width:'200px', height:'200px', opacity:'0.2'}} src={require("../../static/img/drag-drop.svg").default} alt="dragDropImg" />
                            <Link to="/ManagementUploaded">
                                <div className="button_-upload-1">
                                    <div className="upload-13 valign-text-middle titilliumweb-normal-white-18px">
                                        {languageUtil("UPLOAD", this.props.session.language)}
                                    </div>
                                </div>
                            </Link>
                        </div>
                    )}
                </Dropzone>
                <Steps
                    stepDetail1Props={{
                        receive: languageUtil("UPLOAD", this.props.session.language),
                        receviedDigitalCre: languageUtil("UPLOAD_STEP_DETAIL", this.props.session.language),
                        step1: languageUtil("STEP_1", this.props.session.language)
                    }}
                    stepDetail2Props={{
                        receive: languageUtil("SELECT_FUNCTION", this.props.session.language),
                        receviedDigitalCre: languageUtil("SELECT_FUNCTION_STEP_DETAIL", this.props.session.language),
                        step1: languageUtil("STEP_2", this.props.session.language)
                    }}
                    stepDetail3Props={{
                        receive: languageUtil("PROCESS", this.props.session.language),
                        receviedDigitalCre: languageUtil("PROCESS_STEP_DETAIL", this.props.session.language),
                        step1: languageUtil("STEP_3", this.props.session.language)
                    }}
                    isIndividual={true}
                />
                <Drawer
                    anchor='right'
                    open={this.state.drawerToggle}
                    onClose={this.closeDrawer}
                >
                    {this.state.whichSetting === 'reset_password' && (
                        <ManagementSettingChangePW
                            closeDrawer={this.closeDrawer}
                            submitIndivChangePW={this.submitIndivChangePW}
                            indivChangePW={true}
                        />
                    )}
                    {this.state.whichSetting === 'reset_personal_email' && (
                        <ManagementSettingChangeEmail
                            closeDrawer={this.closeDrawer}
                            submitIndivChangePrimaryEmail={this.submitIndivChangePrimaryEmail}
                        />
                    )}
                    {this.state.whichSetting === 'reset_backup_email' && (
                        <ManageSettingBackupEmail
                            closeDrawer={this.closeDrawer}
                            submitIndivBackupEmail={this.submitIndivBackupEmail}
                        />
                    )}

                </Drawer>
            </div>
        )
    }
}
export default connect(mapStateToProps)(withRouter(Management))