import './home.css'
import React, { Component } from 'react'
import NavNavMenu from "../../components/NavNavMenu";
import WebsiteLanguageSelection2 from "../../components/WebsiteLanguageSelection2";
import Function4 from "../../components/Function4";     //Credentials Management Card
import Function5 from "../../components/Function5";     //Credentials Verification Card
import Function6 from "../../components/Function6";     //Credentials Conversion Card
import Background from "../../static/img/bg-1@1x.png";
import languageUtil from "../../lib/languageUtil";
import { removeCookie } from '../../util/cookie';
import { connect } from 'react-redux';
import { withRouter } from '../../withRouter';

function mapStateToProps(state) {
  return { session: state.session, error: state.error }
}

class Home extends Component {

  componentDidMount = () => {
    removeCookie('accessToken')
    removeCookie('backupEmail')
    removeCookie('uploadedFiles')
    removeCookie('previewSelected')
    removeCookie('operatorType')
    removeCookie('uploadedFilesType')
    removeCookie('uploadedFilesType')
  }

  //navigate from nav bar
  navigateTo = (path) => {
    this.props.navigate(path)
  }

  render() {
    return (
      <div className="container_home">
        <form className="home" style={{ backgroundImage: `url(${Background})` }} name="form25" action="form25" method="post">
          <div className="nav-nav-header-1">
            {/* <div className="iconlist">
              <img className="logo-4" style={{ scale: '80%' }} src={require("../../static/img/vq_logo.svg").default} alt="logo" />
              <img className="line2" style={{ scale: '80%' }} src={require("../../static/img/line.svg").default} alt="logo" />
              <img className="hkdchicon" style={{ scale: '80%' }} src={require("../../static/img/HKDCHub_Logo.svg").default} alt="logo" />
            </div> */}
            <div>
              <img className="logo-4" style={{ scale: '80%' }} src={require("../../static/img/left.svg").default} alt="logo" />
            </div>
            <div className="link-9">
              <NavNavMenu
                navListIem1Props={languageUtil('CONTACT_US', this.props.session.language)}
                navListIem2Props={languageUtil('FAQ', this.props.session.language)}
                navListIem3Props={languageUtil('ABOUT_US', this.props.session.language)}
                language_selected={this.props.session.language}
                navigateTo={(path) => { this.navigateTo(path) }}
              />
              <WebsiteLanguageSelection2 />
            </div>
          </div>
          <div className="overlap-group-65">
            <div className="digital-credentials-hub-1 titilliumweb-black-martinique-64px">
              {this.props.session.language === 'english'
                ? <div>
                  <div>HONG KONG</div>
                  <div>DIGITAL CREDENTIALS HUB</div>
                </div>
                : <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                  {languageUtil("DIGITAL_CREDENTIALS_HUB", this.props.session.language)}
                </div>
              }
            </div>
            <div className="digital-credentials-1 titilliumweb-normal-scarpa-flow-18px">
              {languageUtil("MAIN_SUBTITLE", this.props.session.language)}
            </div>
            <div className="function-container-1">
              <Function4 />
              <Function5 />
              <Function6 />
            </div>
          </div>
        </form>
      </div>
    )
  }
}

export default connect(mapStateToProps)(withRouter(Home));