import "./managementForgotEmail.css";
import '../../overlayStyle.css';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from '../../withRouter';
import Group1332 from "../../components/Group1332";
import WebsiteInputFieldLarge from "../../components/WebsiteInputFieldLarge";
import WebsiteButtonS1B from "../../components/WebsiteButtonS1B";
import backgroundImage from "../../static/img/bg-1@1x.png";
import languageUtil from "../../lib/languageUtil";
import errormsgUtil from "../../lib/errormsgUtil";
import axios from 'axios';
import NavNavMenu from "../../components/NavNavMenu";
import WebsiteLanguageSelection2 from "../../components/WebsiteLanguageSelection2";

function mapStateToProps(state) {
    return { session: state.session, error: state.error }
}

export class ManagementForgotEmail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userEmail: '',
            disableConfirm: true
        }
        this.overlay = React.createRef();
    }

    componentDidUpdate = () => {
        //updating disableConfirm
        if (this.state.userEmail) {
            if (this.state.disableConfirm) this.setState({ disableConfirm: false })
        } else {
            if (!this.state.disableConfirm) this.setState({ disableConfirm: true })
        }
    }

    //loading image 
    showOverlay = () => {
        this.overlay.current.style.width = "100%";
    }
    hideOverlay = () => {
        if (this.overlay.current) this.overlay.current.style.width = "0%";
    }

    //update from the child components 
    updateUserInfo = async (fieldName, updatedValue) => {
        //input: state field
        //output: set state with updated value
        let stateObj = {};
        stateObj[fieldName] = updatedValue;
        this.setState(stateObj);
    }

    //send post request to the API server for finding the email. 
    submitIndivForgotEmail = async () => {
        const submitData = {
            'backup_email': this.state.userEmail
        }

        this.showOverlay();
        await axios({
            method: 'post',
            url: `/api/auth/forgotemail`,
            data: submitData
        })
            .then((response) => {
                if (response.data) {
                    if (response.data.error !== '000') {
                        window.alert(errormsgUtil(response.data.error, this.props.session.language))
                    } else {
                        window.alert(languageUtil("SUCCEED_FORGOT_EMAIL", this.props.session.language))
                        this.props.navigate("/ManagementLogin")
                    }
                }
            })
            .catch((err) => {
                console.debug(err);
                if (err.response) {
                    console.debug(err.response);
                    console.debug(err.response.status);
                    console.debug(err.message);
                    console.debug(err.response.data);
                    if (err.response.status === 401) {
                        window.alert("Your login session has timeout.  Please re-login.");
                    } else {
                        window.alert("Error:" + err.message + ". Please report this error status to development team.")
                    }
                } else {
                    window.alert("Error:" + err.message + ". Please report this error status to development team.")
                    console.debug(err.message);
                }
            }).finally(this.hideOverlay);
    }

    navigateTo = (path) => {
        this.props.navigate(path)
    }

    render() {
        return (
            <div className="container-center-horizontal">
                <div ref={this.overlay} className="overlay_cover">
                    <div className="spinner">
                        <div className="bounce1"></div>
                        <div className="bounce2"></div>
                        <div className="bounce3"></div>
                    </div>
                </div>
                <form className="management_forgot_email screen" style={{ backgroundImage: `url(${backgroundImage})` }}>
                    <div className="indiv-forgetemail-header">
                        <div className="indiv-forgetemail-iconlist">
                            <img className="logo-4" style={{ scale: '80%' }} src={require("../../static/img/left.svg").default} alt="logo" />
                        </div>
                        <div className="indiv-forgetemail-link">
                            <NavNavMenu
                                navListIem1Props={languageUtil('CONTACT_US', this.props.session.language)}
                                navListIem2Props={languageUtil('FAQ', this.props.session.language)}
                                navListIem3Props={languageUtil('ABOUT_US', this.props.session.language)}
                                language_selected={this.props.session.language}
                                navigateTo={(path) => { this.navigateTo(path) }}
                            />
                            <WebsiteLanguageSelection2 />
                        </div>
                    </div>
                    <div className="card-5">
                        <Group1332
                            title={languageUtil("FORGOT_EMAIL", this.props.session.language)}
                            subtitle={languageUtil("LOGIN_FORGOT_EMAIL_STATEMENT", this.props.session.language)}
                            navigate={(path) => this.props.navigate(path)}
                            className="title-45"
                        />
                        <WebsiteInputFieldLarge
                            inputType="email"
                            inputPlaceholder="BACKUP_EMAIL"
                            className="input_-field-5"
                            userEmail={this.state.userEmail}
                            updateUserInfo={this.updateUserInfo}
                        />

                        <WebsiteButtonS1B
                            btnText={languageUtil("CONFIRM", this.props.session.language)}
                            disableConfirm={this.state.disableConfirm}
                            submitIndivForgotEmail={this.submitIndivForgotEmail}
                            indivForgotEmail={true}
                        />
                    </div>
                </form>
            </div>
        )
    }
}

export default connect(mapStateToProps)(withRouter(ManagementForgotEmail))
