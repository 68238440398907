import './faq.css'
import React, { Component } from 'react'
import languageUtil from '../../lib/languageUtil'
import WebsiteLanguageSelection2 from '../../components/WebsiteLanguageSelection2'
import NavNavMenu from '../../components/NavNavMenu'
// import ContactUs from '../../components/ContactUs';
// import { Drawer } from '@mui/material';
import { connect } from 'react-redux';
import { withRouter } from '../../withRouter';

function mapStateToProps(state) {
  return { session: state.session, error: state.error }
}

export class FAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSelection: 'general'
    };
  }

  displayGeneral = () => {
    return (
      <div className='faq_content_display'>
        <div className='faq_content_question'>
          {languageUtil('FAQ_GENERAL_Q1', this.props.session.language)}
        </div>
        <div className='faq_content_text titilliumweb-normal-scarpa-flow-18px'>
          {languageUtil('FAQ_GENERAL_A1', this.props.session.language)}
        </div>
        <div className='faq_content_question'>
          {languageUtil('FAQ_GENERAL_Q2', this.props.session.language)}
        </div>
        <div className='faq_content_text titilliumweb-normal-scarpa-flow-18px'>
          {languageUtil('FAQ_GENERAL_A2', this.props.session.language)}
        </div>
        <div className='faq_content_question'>
          {languageUtil('FAQ_GENERAL_Q3', this.props.session.language)}
        </div>
        <div className='faq_content_text titilliumweb-normal-scarpa-flow-18px'>
          {languageUtil('FAQ_GENERAL_A3', this.props.session.language)}
        </div>
        <div className='faq_content_question'>
          {languageUtil('FAQ_GENERAL_Q4', this.props.session.language)}
        </div>
        <div className='faq_content_text titilliumweb-normal-scarpa-flow-18px'>
          {languageUtil('FAQ_GENERAL_A4', this.props.session.language)}
        </div>
        <div className='faq_content_question'>
          {languageUtil('FAQ_GENERAL_Q5', this.props.session.language)}
        </div>
        <div className='faq_content_text titilliumweb-normal-scarpa-flow-18px'>
          {languageUtil('FAQ_GENERAL_A5', this.props.session.language)}
        </div>
      </div>
    )
  }
  displayOperator = () => {
    return (
      <div className='faq_content_display'>
        <div className='faq_content_question'>
          {languageUtil('FAQ_OPERATORS_Q1', this.props.session.language)}
        </div>
        <div className='faq_content_text titilliumweb-normal-scarpa-flow-18px'>
          {languageUtil('FAQ_OPERATORS_A1', this.props.session.language)}
        </div>
        <div className='faq_content_question'>
          {languageUtil('FAQ_OPERATORS_Q2', this.props.session.language)}
        </div>
        <div className='faq_content_text titilliumweb-normal-scarpa-flow-18px'>
          {languageUtil('FAQ_OPERATORS_A2', this.props.session.language)}
        </div>
        <div className='faq_content_question'>
          {languageUtil('FAQ_OPERATORS_Q3', this.props.session.language)}
        </div>
        <div className='faq_content_text titilliumweb-normal-scarpa-flow-18px'>
          {languageUtil('FAQ_OPERATORS_A3', this.props.session.language)}
        </div>
        <div className='faq_content_question'>
          {languageUtil('FAQ_OPERATORS_Q4', this.props.session.language)}
        </div>
        <div className='faq_content_text titilliumweb-normal-scarpa-flow-18px'>
          {languageUtil('FAQ_OPERATORS_A4', this.props.session.language)}
        </div>
        <div className='faq_content_question'>
          {languageUtil('FAQ_OPERATORS_Q5', this.props.session.language)}
        </div>
        <div className='faq_content_text titilliumweb-normal-scarpa-flow-18px'>
          {languageUtil('FAQ_OPERATORS_A5', this.props.session.language)}
          <u className='faq_underline' onClick={() => { this.props.navigate('/Contact') }}>
            {languageUtil('FAQ_OPERATORS_A5_H', this.props.session.language)}
          </u>
          {languageUtil('FAQ_OPERATORS_A5_1', this.props.session.language)}
        </div>
      </div>
    )
  }
  displayCorporate = () => {
    return (
      <div className='faq_content_display'>
        <div className='faq_content_question'>
          {languageUtil('FAQ_CORPORATE_Q1', this.props.session.language)}
        </div>
        <div className='faq_content_text titilliumweb-normal-scarpa-flow-18px'>
          {languageUtil('FAQ_CORPORATE_A1', this.props.session.language)}
        </div>
        <div className='faq_content_question'>
          {languageUtil('FAQ_CORPORATE_Q2', this.props.session.language)}
        </div>
        <div className='faq_content_text titilliumweb-normal-scarpa-flow-18px'>
          {languageUtil('FAQ_CORPORATE_A2', this.props.session.language)}
        </div>
        <div className='faq_content_question'>
          {languageUtil('FAQ_CORPORATE_Q3', this.props.session.language)}
        </div>
        <div className='faq_content_text titilliumweb-normal-scarpa-flow-18px'>
          {languageUtil('FAQ_CORPORATE_A3', this.props.session.language)}
        </div>
        <div className='faq_content_question'>
          {languageUtil('FAQ_CORPORATE_Q4', this.props.session.language)}
        </div>
        <div className='faq_content_text titilliumweb-normal-scarpa-flow-18px'>
          {languageUtil('FAQ_CORPORATE_A4', this.props.session.language)}
        </div>
        <div className='faq_content_question'>
          {languageUtil('FAQ_CORPORATE_Q5', this.props.session.language)}
        </div>
        <div className='faq_content_text titilliumweb-normal-scarpa-flow-18px'>
          {languageUtil('FAQ_CORPORATE_A5', this.props.session.language)}
          <u className='faq_underline' onClick={() => { this.props.navigate('/Contact') }} >
            {languageUtil('FAQ_CORPORATE_A5_H', this.props.session.language)}
          </u>
          {languageUtil('FAQ_CORPORATE_A5_1', this.props.session.language)}
        </div>
      </div>
    )
  }
  displayIndividual = () => {
    return (
      <div className='faq_content_display'>
        <div className='faq_content_question'>
          {languageUtil('FAQ_INDIVIDUAL_Q1', this.props.session.language)}
        </div>
        <div className='faq_content_text titilliumweb-normal-scarpa-flow-18px'>
          {languageUtil('FAQ_INDIVIDUAL_A1', this.props.session.language)}
        </div>
        <div className='faq_content_question'>
          {languageUtil('FAQ_INDIVIDUAL_Q2', this.props.session.language)}
        </div>
        <div className='faq_content_text titilliumweb-normal-scarpa-flow-18px'>
          {languageUtil('FAQ_INDIVIDUAL_A2', this.props.session.language)}
        </div>
        <div className='faq_content_question'>
          {languageUtil('FAQ_INDIVIDUAL_Q3', this.props.session.language)}
        </div>
        <div className='faq_content_text titilliumweb-normal-scarpa-flow-18px'>
          {languageUtil('FAQ_INDIVIDUAL_A3', this.props.session.language)}
        </div>
        <div className='faq_content_question'>
          {languageUtil('FAQ_INDIVIDUAL_Q4', this.props.session.language)}
        </div>
        <div className='faq_content_text titilliumweb-normal-scarpa-flow-18px'>
          {languageUtil('FAQ_INDIVIDUAL_A4', this.props.session.language)}
        </div>
        <div className='faq_content_question'>
          {languageUtil('FAQ_INDIVIDUAL_Q5', this.props.session.language)}
        </div>

        <div className='faq_content_text titilliumweb-normal-scarpa-flow-18px'>
          {languageUtil('FAQ_INDIVIDUAL_A5', this.props.session.language)}
        </div>

        <div className='faq_content_question'>
          {languageUtil('FAQ_INDIVIDUAL_Q6', this.props.session.language)}
        </div>

        <div className='faq_content_text titilliumweb-normal-scarpa-flow-18px'>
          {languageUtil('FAQ_INDIVIDUAL_A6', this.props.session.language)}
        </div>

        <div className='faq_content_question'>
          {languageUtil('FAQ_INDIVIDUAL_Q7', this.props.session.language)}
        </div>
        <div className='faq_content_text titilliumweb-normal-scarpa-flow-18px'>
          {languageUtil('FAQ_INDIVIDUAL_A7', this.props.session.language)}
        </div>
        <div className='faq_content_question'>
          {languageUtil('FAQ_INDIVIDUAL_Q8', this.props.session.language)}
        </div>


        <div className='faq_array_contents'>
          <div style={{ alignItems: 'center', justifyContent: 'center', height: '30px', lineHeight: '18px', paddingRight: '10px', marginBottom: 'auto', marginTop: '5px', scale: '50%' }}>●</div>
          <div className='faq_content_text_llist titilliumweb-normal-scarpa-flow-18px'>
            {languageUtil('FAQ_INDIVIDUAL_A8_1', this.props.session.language)}
          </div>
        </div>

        <div className='faq_array_contents'>
          <div style={{ alignItems: 'center', justifyContent: 'center', height: '30px', lineHeight: '18px', paddingRight: '10px', marginBottom: 'auto', marginTop: '5px', scale: '50%' }}>●</div>
          <div className='faq_content_text_llist titilliumweb-normal-scarpa-flow-18px'>
            {languageUtil('FAQ_INDIVIDUAL_A8_2', this.props.session.language)}
          </div>
        </div>

        <div className='faq_array_contents'>
          <div style={{ alignItems: 'center', justifyContent: 'center', height: '30px', lineHeight: '18px', paddingRight: '10px', marginBottom: 'auto', marginTop: '5px', scale: '50%' }}>●</div>
          <div className='faq_content_text_llist titilliumweb-normal-scarpa-flow-18px'>
            {languageUtil('FAQ_INDIVIDUAL_A8_3', this.props.session.language)}
          </div>
        </div>

        <div className='faq_content_question'>
          {languageUtil('FAQ_INDIVIDUAL_Q9', this.props.session.language)}
        </div>
        <div className='faq_content_text titilliumweb-normal-scarpa-flow-18px'>
          {languageUtil('FAQ_INDIVIDUAL_A9', this.props.session.language)}
        </div>
        <div className='faq_content_question'>
          {languageUtil('FAQ_INDIVIDUAL_Q10', this.props.session.language)}
        </div>
        <div className='faq_content_text titilliumweb-normal-scarpa-flow-18px'>
          {languageUtil('FAQ_INDIVIDUAL_A10', this.props.session.language)}
        </div>
        <div className='faq_content_question'>
          {languageUtil('FAQ_INDIVIDUAL_Q11', this.props.session.language)}
        </div>
        <div className='faq_content_text titilliumweb-normal-scarpa-flow-18px'>
          {languageUtil('FAQ_INDIVIDUAL_A11', this.props.session.language)}
        </div>
        <div className='faq_content_question'>
          {languageUtil('FAQ_INDIVIDUAL_Q12', this.props.session.language)}
        </div>
        <div className='faq_content_text titilliumweb-normal-scarpa-flow-18px'>
          {languageUtil('FAQ_INDIVIDUAL_A12', this.props.session.language)}
          <u className='faq_underline' onClick={() => { this.props.navigate('/Contact') }}>
            {languageUtil('FAQ_INDIVIDUAL_A12_H', this.props.session.language)}
          </u>
          {languageUtil('FAQ_INDIVIDUAL_A12_1', this.props.session.language)}
        </div>
        <div className='faq_content_question'>
          {languageUtil('FAQ_INDIVIDUAL_Q13', this.props.session.language)}
        </div>
        <div className='faq_content_text titilliumweb-normal-scarpa-flow-18px'>
          {languageUtil('FAQ_INDIVIDUAL_A13', this.props.session.language)}
        </div>
      </div>
    )
  }
  changeState = (value) => {
    this.setState({ currentSelection: value })
  }

  //navigate from nav bar
  navigateTo = (path) => {
    this.props.navigate(path)
  }

  render() {
    return (
      <div className='container-center-horizontal12'>
        <div className='faq_header'>
          <div className='faq_iconlist'
            onClick={() => { this.props.navigate('/') }}
          >
            <img className="logo-4" style={{ scale: '80%' }} src={require("../../static/img/left.svg").default} alt="logo" />
            {/* <img className='faq_hkcavvqlogo' style={{ scale: '80%' }} src={require("../../static/img/vq_logo.svg").default} alt="logo" />
            <img className='faq_divline' style={{ scale: '80%' }} src={require("../../static/img/line.svg").default} alt="logo" />
            <img className='faq_hkdchicon' style={{ scale: '80%' }} src={require("../../static/img/HKDCHub_Logo.svg").default} alt="logo" /> */}
          </div>
          <div className='faq_webcontents'>
            <NavNavMenu
              navListIem1Props={languageUtil('CONTACT_US', this.props.session.language)}
              navListIem2Props={languageUtil('FAQ', this.props.session.language)}
              navListIem3Props={languageUtil('ABOUT_US', this.props.session.language)}
              navigateTo={(path) => { this.navigateTo(path) }}
              language_selected={this.props.session.language}
            />
            <WebsiteLanguageSelection2 />
          </div>
        </div>
        <div className='faq_contentcontainer'>
          <div className='faq_title_contain'>
            {this.props.session.language === 'english'
              ? <img
                className='faq_title_img'
                src={require("./../../static/img/faq_title.svg").default}
                alt="faq-title"
              />
              : this.props.session.language === 'cantonese'
                ? <img
                  className='faq_title_img'
                  src={require("./../../static/img/faq_title_hk.svg").default}
                  alt="faq-title"
                />
                : <img
                  className='faq_title_img'
                  src={require("./../../static/img/faq_title_cn.svg").default}
                  alt="faq-title"
                />
            }
          </div>
          <div className='faq_context'>
            <div className='button_container'>
              <button className={this.state.currentSelection === 'general' ? 'active_round_button' : 'round_button'} onClick={() => { this.changeState('general') }}>
                <div className='faq_text_title titilliumweb-black-martinique-14px'>
                  {languageUtil("FAQ_GENERAL", this.props.session.language)}
                </div>
              </button>
              <button className={this.state.currentSelection === 'operator' ? 'active_round_button' : 'round_button'} onClick={() => { this.changeState('operator') }}>
                <div className='faq_text_title titilliumweb-black-martinique-14px'>
                  {languageUtil("FAQ_OPERATORS", this.props.session.language)}
                </div>
              </button>
              <button className={this.state.currentSelection === 'corp' ? 'active_round_button' : 'round_button'} onClick={() => { this.changeState('corp') }}>
                <div className='faq_text_title titilliumweb-black-martinique-14px'>
                  {languageUtil("FAQ_CORPORATE", this.props.session.language)}
                </div>
              </button>
              <button className={this.state.currentSelection === 'indiv' ? 'active_round_button' : 'round_button'} onClick={() => { this.changeState('indiv') }}>
                <div className='faq_text_title titilliumweb-black-martinique-14px'>
                  {languageUtil("FAQ_INDIVIDUAL", this.props.session.language)}
                </div>
              </button>
            </div>
            <div className='faq_contents'>
              {this.state.currentSelection === 'general'
                ? this.displayGeneral()
                : this.state.currentSelection === 'operator'
                  ? this.displayOperator()
                  : this.state.currentSelection === 'corp'
                    ? this.displayCorporate()
                    : this.state.currentSelection === 'indiv'
                      ? this.displayIndividual()
                      : undefined
              }
            </div>
          </div>
        </div>
      </div>

    )
  }
}
export default connect(mapStateToProps)(withRouter(FAQ));