import {Outlet, Navigate} from 'react-router-dom'
import { connect } from 'react-redux';
import {withRouter} from '../withRouter';
import { getCookie } from '../util/cookie';

function mapStateToProps (state) {
	return { session: state.session }
}

const PrivateRoutes = (props) => {
    return (
        (getCookie('accessToken')) ? <Outlet/> : <Navigate to="/"/>
    )
}

export default connect(mapStateToProps)(withRouter(PrivateRoutes))