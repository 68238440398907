// This component is being used for both individual user and operator

import "./ManagementSettingChangePW.css";
import React, { useRef, useEffect } from "react";
import { connect } from 'react-redux';
import WebsiteInputFieldSmall from "../WebsiteInputFieldSmall";
import WebsiteButtonS1B from "../WebsiteButtonS1B";
import languageUtil from "../../lib/languageUtil";

function mapStateToProps(state) {
  return { session: state.session, error: state.error }
}

function ManagementSettingChangePW(props) {
  const [originalPW, setOriginalPW] = React.useState('');
  const [newPW, setNewPW] = React.useState('');
  const [confirmPW, setConfirmPW] = React.useState('');
  const [passwordUnmatched, setPasswordUnmatched] = React.useState(false);
  const [passwordValid, setPasswordValid] = React.useState(false);
  //regex for checking valid password format 


  const mounted = useRef();
  useEffect(() => {
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
    if (!mounted.current) {
      // do componentDidMount logic
      mounted.current = true;
    } else {
      // do componentDidUpdate logic
      if ((newPW && confirmPW) && (newPW !== confirmPW)) {
        setPasswordUnmatched(true);
      } else setPasswordUnmatched(false)

      if (strongRegex.test(newPW) && strongRegex.test(confirmPW)) {
        setPasswordValid(true)
      }  else {
        setPasswordValid(false)
      }
    }
  }, [newPW, confirmPW]);

  return (
    <div>
      <div className="overlap-group-60">
        <div className="title-48 valign-text-middle titilliumweb-bold-black-18px">
          {languageUtil("CHANGE_PASSWORD", props.session.language)}
        </div>
        <img
          className="icon-24"
          src={require("../../static/img/-icon-7@2x.svg").default}
          alt='close'
          onClick={props.closeDrawer}
        />
      </div>
      <div className="setting_changePW_container">
        <WebsiteInputFieldSmall
          inputType='password'
          inputPlaceholder="ORIGINAL_PASSWORD"
          setOriginalPW={setOriginalPW}
          originalPW={originalPW}
        />
        <WebsiteInputFieldSmall
          inputType='password'
          inputPlaceholder="NEW_PASSWORD"
          setNewPW={setNewPW}
          newPassword={newPW}
        />
        <WebsiteInputFieldSmall
          inputType='password'
          inputPlaceholder="CONFIRM_PASSWORD"
          setConfirmPW={setConfirmPW}
          confirmPassword={confirmPW}
          passwordUnmatched={passwordUnmatched}
        />
        <div style={{ width:'100%', marginTop: 'auto', marginBottom: '20px' }}>
          <WebsiteButtonS1B
            disableConfirm={(!originalPW || !newPW || !confirmPW) 
              || (newPW && confirmPW && (passwordUnmatched || !passwordValid))}
            btnText={languageUtil("CONFIRM", props.session.language)}
            convertChangePW={props.convertChangePW ? true : false}
            indivChangePW={props.indivChangePW ? true : false}
            submitIndivChangePW={() => props.submitIndivChangePW(originalPW, newPW)}
            submitOperatorChangePW={() => props.submitOperatorChangePW(originalPW, newPW)}
          />
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(ManagementSettingChangePW);
