import "./ManagementSettingBackUpEmail.css";
import React, { useRef, useEffect } from "react";
import { connect } from 'react-redux';
import { getCookie } from '../../util/cookie';
import WebsiteInputFieldSmall from "../WebsiteInputFieldSmall";
import WebsiteButtonS1B from "../WebsiteButtonS1B";
import languageUtil from "../../lib/languageUtil";
import errormsgUtil from "../../lib/errormsgUtil";

function mapStateToProps(state) {
  return { session: state.session, error: state.error }
}

function ManagementSettingBackUpEmail(props) {
  const [newBackupEmail, setNewBackupEmail] = React.useState('');
  let [isEmailValid, setIsEmailValid] = React.useState(null);

  //input validation for userEmail(signup and signin)
  const inputValidationEmail = async () => {
    let regEx = /\S+@\S+\.\S+/;
    await setIsEmailValid(regEx.test(newBackupEmail));
  }

  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      // do componentDidMount logic
      mounted.current = true;
    } 
    else {
      // do componentDidUpdate logic
      inputValidationEmail()
    }
  });

  return (
    <>
      <div className="overlap-group-68">
        <div className="title-52 valign-text-middle titilliumweb-bold-black-18px">
          {languageUtil("CHANGE_BACKUP_EMAIL", props.session.language)}
        </div>
        <img
          className="icon-33"
          src={require("../../static/img/-icon-7@2x.svg").default}
          alt='close'
          onClick={props.closeDrawer}
        />
      </div>
      <div
        className="title-53"
      >
        {languageUtil("ORIGINAL_BACKUP_EMAIL", props.session.language)}
      </div>
      {!getCookie("backupEmail") 
        ? 
          <div
            className="title-54 titilliumweb-normal-matisse-16px"
          >
            {errormsgUtil("115", props.session.language)}
          </div>
        : 
          <div
            className="title-54 titilliumweb-normal-matisse-16px"
          >
            {getCookie("backupEmail")}
          </div>
      }
      <div className="block-1"></div>
      <div className="setting_backupPW_container">
        <WebsiteInputFieldSmall
          inputType='email'
          inputPlaceholder="NEW_BACKUP_EMAIL"
          setNewBackupEmail={setNewBackupEmail}
          newBackupEmail={newBackupEmail}
        />
        <div style={{ marginTop: 'auto', marginBottom: '20px' }}>
          <WebsiteButtonS1B
            disableConfirm={!newBackupEmail || !isEmailValid}
            className="button_-confirm-2"
            btnText={languageUtil("CONFIRM", props.session.language)}
            indivChangeBackupEmail={true}
            submitIndivBackupEmail={()=>props.submitIndivBackupEmail(newBackupEmail)}
          />
        </div>
      </div>
    </>
  );
}

export default connect(mapStateToProps)(ManagementSettingBackUpEmail);
