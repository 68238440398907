import './conversionLogin.css'
import '../../overlayStyle.css';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { withRouter } from '../../withRouter';
import WebsiteInputFieldLarge from "../../components/WebsiteInputFieldLarge";
import backgroundImage from "../../static/img/bg-1@1x.png";
import axios from 'axios';
import languageUtil from "../../lib/languageUtil";
import errormsgUtil from "../../lib/errormsgUtil";
import { setCookie, removeCookie } from '../../util/cookie';
import { Button, Typography } from '@mui/material'
import NavNavMenu from '../../components/NavNavMenu';
import WebsiteLanguageSelection2 from '../../components/WebsiteLanguageSelection2';

function mapStateToProps(state) {
  return { session: state.session, error: state.error }
}

class ConversionLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userid: '',
      password: '',
      disableConfirm: true
    }
    this.overlay = React.createRef();
  }

  //loading image 
  showOverlay = () => {
    this.overlay.current.style.width = "100%";
  }
  hideOverlay = () => {
    if (this.overlay.current) this.overlay.current.style.width = "0%";
  }

  componentDidUpdate = () => {
    //updating disableConfirm
    if (this.state.password && this.state.userid) {
      if (this.state.disableConfirm) this.setState({ disableConfirm: false })
    } else {
      if (!this.state.disableConfirm) this.setState({ disableConfirm: true })
    }
  }

  //update from the child components 
  updateLoginInfo = async (fieldName, updatedValue) => {
    //input: state field
    //output: set state with updated value
    let stateObj = {};
    stateObj[fieldName] = updatedValue;
    this.setState(stateObj);
  }

  //if enterkey pressed during the input, submit the login
  entersubmitConversionLogin = () => {
    if (this.state.userid && this.state.password) this.submitConversionLogin();
  }

  //send signup information to the API server. 
  submitConversionLogin = async () => {
    const submitData = {
      'userid': this.state.userid,
      'password': this.state.password
    }

    this.showOverlay();
    await axios({
      method: 'post',
      url: `/api/operator/signin`,
      data: submitData
    })
      .then((response) => {
        if (response.data) {

          if (response.data.error !== '000') {
            window.alert(errormsgUtil(response.data.error, this.props.session.language))
          } else {
            removeCookie('accessToken')
            removeCookie('backupEmail')
            removeCookie('uploadedFiles')
            removeCookie('previewSelected')
            removeCookie('operatorType')
            removeCookie('uploadedFilesType')
            removeCookie('operatorENName')
            removeCookie('operatorCNName')
            setCookie('operatorType', response.data.type)
            //set access token in cookie
            setCookie('accessToken', response.data.accessToken)
            if(response.data.operator_name_eng){
              setCookie('operatorENName',response.data.operator_name_eng+" ")
            }else setCookie('operatorENName',"")
            if(response.data.operator_name_chi){
              setCookie('operatorCNName',response.data.operator_name_chi)
            }else setCookie('operatorCNName',"")
            

            if (response.data.is_first_login) {
              this.props.navigate("/ConversionChangePW")
            } else {
              this.props.navigate("/Conversion")
            }
          }
        }
      })
      .catch((err) => {
        console.debug(err);
        if (err.response) {
          console.debug(err.response);
          console.debug(err.response.status);
          console.debug(err.message);
          console.debug(err.response.data);
          if (err.response.status === 401) {
            window.alert("Your login session has timeout.  Please re-login.");
          } else {
            window.alert("Error:" + err.message + ". Please report this error status to development team.")
          }
        } else {
          window.alert("Error:" + err.message + ". Please report this error status to development team.")
          console.debug(err.message);
        }
      }).finally(this.hideOverlay);
  }

  navigateTo = (path) => {
    this.props.navigate(path)
  }

  render() {

    return (
      <div className="container-center-horizontal">
        <div ref={this.overlay} className="overlay_cover">
          <div className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
        </div>
        <form
          className="home-management-login screen"
          style={{ backgroundImage: `url(${backgroundImage})` }}
          name="form35"
          action="form35"
          method="post"
        >
          <div className="op-login-header">
            <div className="op-login-iconlist">
              <img className="logo-4" style={{ scale: '80%' }} src={require("../../static/img/left.svg").default} alt="logo" />
            </div>
            <div className="op-login-link">
              <NavNavMenu
                navListIem1Props={languageUtil('CONTACT_US', this.props.session.language)}
                navListIem2Props={languageUtil('FAQ', this.props.session.language)}
                navListIem3Props={languageUtil('ABOUT_US', this.props.session.language)}
                language_selected={this.props.session.language}
                navigateTo={(path) => { this.navigateTo(path) }}
              />
              <WebsiteLanguageSelection2 />
            </div>
          </div>
          <div className="overlap-group-74">
            <div className="title-59">
              <div
                className="indicator-conversion"
                style={{ backgroundColor: 'var(--cherry-pie)' }}
              />
              <div className="credentials-management-6 titilliumweb-bold-martinique-24px">
                {languageUtil("CREDENTIALS_CONVERSION", this.props.session.language)}
              </div>
              <img
                className="cancel_op_login"
                src={require("../../static/img/-icon-7@2x.svg").default}
                alt='close'
                onClick={() => this.props.navigate("/")}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '30px' }}>
              <WebsiteInputFieldLarge
                inputType="userid"
                inputPlaceholder="USER_ID"
                userid={this.state.userid}
                updateLoginInfo={this.updateLoginInfo}
                conversionLogIn={true}
                entersubmitConversionLogin={this.entersubmitConversionLogin}
              />
              <WebsiteInputFieldLarge
                inputType="password"
                inputPlaceholder="PASSWORD"
                updateLoginInfo={this.updateLoginInfo}
                password={this.state.password}
                conversionLogin={true}
                entersubmitConversionLogin={this.entersubmitConversionLogin}
              />
            </div>
            <Link
              to='/conversionForgotPW'
            >
              <div
                className="forgot-password-conversion valign-text-middle titilliumweb-normal-white-16px"
                style={{ color: 'var(--cherry-pie)' }}
              >
                <Typography>
                  {languageUtil("FORGOT_PASSWORD?", this.props.session.language)}
                </Typography>
              </div>
            </Link>
            <Button
              variant='contained'
              onClick={this.submitConversionLogin}
              disabled={this.state.disableConfirm}
              sx={{
                width: '400px', padding: '15px 0px',
                textTransform: 'none',
                backgroundColor: 'var(--cherry-pie)',
                '&:hover': {
                  backgroundColor: 'var(--cherry-pie)'
                },
                '&:disabled': {
                  backgroundColor: 'var(--cherry-pie)',
                  color: 'white',
                  opacity: '0.6'
                }
              }}
            >
              {languageUtil("LOGIN", this.props.session.language)}
            </Button>
          </div>
        </form>
      </div>
    )
  }
}

export default connect(mapStateToProps)(withRouter(ConversionLogin))