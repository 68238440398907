import './managementSignup.css';
import '../../overlayStyle.css';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from '../../withRouter';
import WebsiteInputFieldLarge from "../../components/WebsiteInputFieldLarge";
import WebsiteUploadFile from "../../components/WebsiteUploadFile";
import WebsiteButtonS1B from "../../components/WebsiteButtonS1B";
import backgroundImage from "../../static/img/bg-1@1x.png";
import languageUtil from "../../lib/languageUtil";
import errormsgUtil from "../../lib/errormsgUtil";
import axios from 'axios';
import NavNavMenu from '../../components/NavNavMenu';
import WebsiteLanguageSelection2 from '../../components/WebsiteLanguageSelection2';
import Dropzone from 'react-dropzone';

function mapStateToProps(state) {
    return { session: state.session, error: state.error }
}

class ManagementSignup extends Component {
    constructor(props) {
        super(props)
        this.onDrop = (uploadedFiles) => {
            this.onDropFiles(uploadedFiles)
        };
        this.state = {
            vqid: '',
            userEmail: '',
            credFile: {},
            disableConfirm: true,
            isCreditValid: false
        };
        this.overlay = React.createRef();
    }


    //when file added by onDrop 
    onDropFiles = async (file) => {
        const tmpCredFile = file[0];

        if (tmpCredFile) {
        await this.updateUserInfo('credFile', tmpCredFile)

        if (tmpCredFile.name.includes('.dch')) {
            await this.setIsCreditValid(true)
        } else {
            await this.setIsCreditValid(false)
        }
        }
    }

    //loading image 
    showOverlay = () => {
        this.overlay.current.style.width = "100%";
    }
    hideOverlay = () => {
        if (this.overlay.current) this.overlay.current.style.width = "0%";
    }

    componentDidUpdate = () => {
        //updating disableConfirm
        if (this.state.vqid && this.state.userEmail && Object.keys(this.state.credFile)) {
            if (this.state.disableConfirm) this.setState({ disableConfirm: false })
        } else {
            if (!this.state.disableConfirm) this.setState({ disableConfirm: true })
        }
    }

    //empty credential file 
    emptyCredFile = () => {
        this.setState({ credFile: {} })
    }

    //update from the child components 
    updateUserInfo = (fieldName, updatedValue) => {
        //input: state field
        //output: set state with updated value
        let stateObj = {};
        stateObj[fieldName] = updatedValue;
        this.setState(stateObj);
    }

    setIsCreditValid = (newValue) => {
        this.setState({isCreditValid: newValue})
    }

    //send signup information to the API server. 
    submitIndivSignup = async () => {
        const submitFormData = new FormData();
        submitFormData.append('vqid', this.state.vqid);
        submitFormData.append('email', this.state.userEmail);
        submitFormData.append('file', this.state.credFile);

        this.showOverlay();
        await axios({
            method: 'post',
            url: `/api/auth/signup`,
            data: submitFormData,
            headers: {
                "Content-Type": `multipart/form-data;`,
            }
        })
            .then((response) => {
                if (response.data) {
                    if (response.data.error !== '000') {
                        window.alert(errormsgUtil(response.data.error, this.props.session.language))
                    } else {
                        this.props.navigate("/")
                        window.alert(languageUtil("SUCCEED_SIGNUP", this.props.session.language).replace('<emailAddress>', this.state.userEmail))
                    }
                }
            })
            .catch((err) => {
                console.debug(err);
                if (err.response) {
                    console.debug(err.response);
                    console.debug(err.response.status);
                    console.debug(err.message);
                    console.debug(err.response.data);
                    if (err.response.status === 401) {
                        window.alert("Your login session has timeout.  Please re-login.");
                        // this.props.history.push("/");
                    } else {
                        window.alert("Error:" + err.message + ". Please report this error status to development team.")
                    }
                } else {
                    window.alert("Error:" + err.message + ". Please report this error status to development team.")
                    console.debug(err.message);
                }
            }).finally(this.hideOverlay);
    }
    navigateTo = (path) => {
        this.props.navigate(path)
    }

    render() {
        //props passed to the child components 

        return (
            <div className="container-center-horizontal">
                <div ref={this.overlay} className="overlay_cover">
                    <div className="spinner">
                        <div className="bounce1"></div>
                        <div className="bounce2"></div>
                        <div className="bounce3"></div>
                    </div>
                </div>
                <form className="home-management-signup screen" name="form1" action="form1" method="post">
                    <div className="indiv-sign-header">
                        <div className="indiv-sign-iconlist">
                            <img className="logo-4" style={{ scale: '80%' }} src={require("../../static/img/left.svg").default} alt="logo" />
                        </div>
                        <div className="indiv-sign-link">
                            <NavNavMenu
                                navListIem1Props={languageUtil('CONTACT_US', this.props.session.language)}
                                navListIem2Props={languageUtil('FAQ', this.props.session.language)}
                                navListIem3Props={languageUtil('ABOUT_US', this.props.session.language)}
                                language_selected={this.props.session.language}
                                navigateTo={(path) => { this.navigateTo(path) }}
                            />
                            <WebsiteLanguageSelection2 />
                        </div>
                    </div>
                    <div className="overlap-group2" style={{ backgroundImage: `url(${backgroundImage})` }}>
                        <div className="card">
                            <div className="title">
                                <div className="indicator" />
                                <div className="sign-up titilliumweb-bold-martinique-24px">{languageUtil("SIGN_UP", this.props.session.language)}</div>
                                <img
                                    className="cancel_indiv_signin"
                                    src={require("../../static/img/-icon-7@2x.svg").default}
                                    alt='close'
                                    onClick={() => this.props.navigate("/")}
                                />
                            </div>
                            <div className="frame-1">
                                <WebsiteInputFieldLarge
                                    inputType="vqid"
                                    inputPlaceholder="VQID"
                                    vqid={this.state.vqid}
                                    updateUserInfo={this.updateUserInfo}
                                />
                                <WebsiteInputFieldLarge
                                    inputType="email"
                                    inputPlaceholder="PERSONAL_EMAIL"
                                    userEmail={this.state.userEmail}
                                    updateUserInfo={this.updateUserInfo}
                                />
                                <Dropzone
                                    noClick={true}
                                    onDrop={this.onDrop}
                                    style={{ width: '100%', heigth: '100%' }}
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps({ className: 'dropzone' })}>
                                            <input {...getInputProps({ className: 'dropzone' })} />
                                            <WebsiteUploadFile
                                                warning={languageUtil("INVALID_FILE_FORMAT", this.props.session.language)}
                                                title={languageUtil("UPLOAD_CREDENTIAL_FILE", this.props.session.language)}
                                                credFile={this.state.credFile}
                                                emptyCredFile={this.emptyCredFile}
                                                updateUserInfo={this.updateUserInfo}
                                                isCreditValid={this.state.isCreditValid}
                                                setIsCreditValid={this.setIsCreditValid}
                                            />
                                        </div>
                                    )}
                                </Dropzone>
                            </div>

                            <div style={{ width: '400px' }}>
                                <WebsiteButtonS1B
                                    btnText={languageUtil("CONFIRM", this.props.session.language)}
                                    submitIndivSignup={this.submitIndivSignup}
                                    disableConfirm={this.state.disableConfirm}
                                    indivSignUp={true}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

export default connect(mapStateToProps)(withRouter(ManagementSignup))