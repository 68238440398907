import "./conversion.css";
import '../../overlayStyle.css';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from '../../withRouter';
import { Drawer } from '@mui/material';
import { getCookie } from "../../util/cookie";
import { setSession } from "../../actions/session";
import Header from "../../components/Header";
import Steps from "../../components/Steps";
import languageUtil from "../../lib/languageUtil";
import errormsgUtil from "../../lib/errormsgUtil";
import axios from 'axios';
import ManagementSettingChangePW from "../../components/ManagementSettingChangePW";
import Dropzone from 'react-dropzone';

function mapStateToProps(state) {
    return { session: state.session, error: state.error }
}

function mapDispatchToProps(dispatch) {
    return {
        setSession: (session) => dispatch(setSession(session))
    }
}

export class Conversion extends Component {
    constructor(props) {
        super(props);
        this.onDrop = (uploadedFiles) => {
            this.onDropFiles(uploadedFiles)
        };
        this.state = {
            drawerToggle: false,
            whichSetting: '',
        };
        this.overlay = React.createRef();
    }

    //when file added by onDrop 
    onDropFiles = async (uploadingfiles) => {
        await this.props.navigate('/ConversionUploaded', {state:{uploadingfile: [uploadingfiles[0]]}})
    }

    //loading image 
    showOverlay = () => {
        this.overlay.current.style.width = "100%";
    }
    hideOverlay = () => {
        if (this.overlay.current) this.overlay.current.style.width = "0%";
    }

    //open drawer for the account settings
    openDrawer = (settingItem) => {
        this.setState({
            drawerToggle: true,
            whichSetting: settingItem
        })
    }
    //close drawer to close the account settings 
    closeDrawer = () => {
        this.setState({
            drawerToggle: false,
            whichSetting: ''
        })
    }

    //send new password information to the API server. 
    submitOperatorChangePW = async (oldPassword, confirmPassword) => {
        const submitData = {
            'oldpassword': oldPassword,
            'newpassword': confirmPassword
        }

        this.closeDrawer();
        this.showOverlay();
        await axios({
            method: 'post',
            url: `/api/operator/changepassword`,
            data: submitData,
            headers: {
                "Authorization": getCookie('accessToken')
            }
        })
            .then((response) => {
                if (response.data) {
                    if (response.data.error !== '000') {
                        window.alert(errormsgUtil(response.data.error, this.props.session.language))
                    } else {
                        window.alert(languageUtil("SUCCEED_CHANGEPW", this.props.session.language))
                        this.closeDrawer();
                        this.props.navigate("/ConversionLogin");
                    }
                }
            })
            .catch((err) => {
                console.debug(err);
                if (err.response) {
                    console.debug(err.response);
                    console.debug(err.response.status);
                    console.debug(err.message);
                    console.debug(err.response.data);
                    if (err.response.status === 401) {
                        window.alert("Your login session has timeout.  Please re-login.");
                    } else {
                        window.alert("Error:" + err.message + ". Please report this error status to development team.")
                    }
                } else {
                    window.alert("Error:" + err.message + ". Please report this error status to development team.")
                    console.debug(err.message);
                }
            }).finally(this.hideOverlay);
    }

    render() {
        return (
            <div className="conversion">
                <div ref={this.overlay} className="overlay_cover">
                    <div className="spinner">
                        <div className="bounce1"></div>
                        <div className="bounce2"></div>
                        <div className="bounce3"></div>
                    </div>
                </div>
                <Header
                    openDrawer={this.openDrawer}
                    isConversion={true}
                    isConversionLanding={true}
                />
                <div className="cv_operator_name">
                    <div className="titilliumweb-bold-black-24px">
                        {languageUtil("WELCOME",this.props.session.language)+"  "+ getCookie('operatorENName') + getCookie('operatorCNName')
                        /*(getCookie('operatorENName')===""&& getCookie('operatorCNName')===""
                        ?   "Operator"
                        : getCookie('operatorENName') + getCookie('operatorCNName'))*/
                           }
                    </div>
                </div>
                <div className="conversion_page_info">
                    <div className="titilliumweb-bold-black-24px" style={{color:'black', display:'flex', alignItems:'center'}}>
                        {languageUtil("CREDENTIALS_CONVERSION", this.props.session.language)}
                    </div>
                    
                    {/* Conversion Preview Button*/}
                    <button 
                        onClick={() => this.props.navigate('/ConversionPreviewUpload')}
                        className="cv_preview_upload_btn titilliumweb-normal-white-18px"
                        variant="outlined">
                        {languageUtil("UPLOAD_FOR_PREVIEW", this.props.session.language)}
                    </button>

                    <button 
                        onClick={() => this.props.navigate('/OperatorLog')}
                        className="cv_activity_log_btn titilliumweb-normal-white-18px"
                        variant="outlined">
                        {languageUtil("ACTIVITY_LOG", this.props.session.language)}
                    </button>
                </div>
                <Dropzone
                    noClick={true}
                    onDrop={this.onDrop}
                    style={{ width: '100%', heigth: '100%' }}
                >
                    {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps({ className: 'dropzone' })} className="upload_field_conversion">
                            <input {...getInputProps({ className: 'dropzone' })} />
                            <img style={{width:'200px', height:'200px', opacity:'0.2'}} src={require("../../static/img/drag-drop.svg").default} alt="dragDropImg" />
                            <div
                                className="button_upload_conversion"
                                style={{zIndex:'1'}}
                                onClick={() => this.props.navigate('/ConversionUploaded')}
                            >
                                <div className="upload-conversion valign-text-middle titilliumweb-normal-white-18px">
                                    {languageUtil("UPLOAD", this.props.session.language)}
                                </div>
                            </div>
                        </div>
                    )}
                </Dropzone>
                <Steps
                    stepDetail1Props={{
                        receive: languageUtil("UPLOAD", this.props.session.language),
                        receviedDigitalCre: languageUtil("CONVERT_UPLOAD_STATEMENT", this.props.session.language),
                        step1: languageUtil("STEP_1", this.props.session.language)
                    }}
                    stepDetail2Props={{
                        receive: languageUtil("PROCESS", this.props.session.language),
                        receviedDigitalCre: languageUtil("CONVERT_PROCESS_STATEMENT", this.props.session.language),
                        step1: languageUtil("STEP_2", this.props.session.language)
                    }}
                    stepDetail3Props={{
                        receive: languageUtil("DOWNLOAD", this.props.session.language),
                        receviedDigitalCre: languageUtil("CONVERT_DOWNLOAD_STATEMENT", this.props.session.language),
                        step1: languageUtil("STEP_3", this.props.session.language)
                    }}
                    isConversion={true}
                />
                <Drawer
                    anchor='right'
                    open={this.state.drawerToggle}
                    onClose={this.closeDrawer}
                // onOpen={toggleDrawer(anchor, true)}
                >
                    {this.state.whichSetting === 'reset_password' && (
                        <ManagementSettingChangePW
                            closeDrawer={this.closeDrawer}
                            submitOperatorChangePW={this.submitOperatorChangePW}
                            convertChangePW={true}
                        />
                    )}

                </Drawer>
            </div>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Conversion))