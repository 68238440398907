import "./Header.css";
import React from "react";
import { connect } from 'react-redux';
import { withRouter } from '../../withRouter';
import { clearSession, setSession } from "../../actions/session";
import { Button, Menu, MenuItem } from "@mui/material";
import { getCookie, removeCookie } from "../../util/cookie";
import languageUtil from "../../lib/languageUtil";
import errormsgUtil from "../../lib/errormsgUtil";
import axios from 'axios';

function mapStateToProps(state) {
  return { session: state.session, error: state.error }
}

function mapDispatchToProps(dispatch) {
  return {
    clearSession: () => dispatch(clearSession()),
    setSession: (session) => dispatch(setSession(session))
  }
}

function Header(props) {

  const [anchorEl_lang, setAnchorEl_lang] = React.useState(null)    //open or close the selecting languages 
  const [anchorEl_acc, setAnchorEl_acc] = React.useState(null)    //open or close the account setting 
  const openLanguage = Boolean(anchorEl_lang);
  const openAccount = Boolean(anchorEl_acc);

  const handleLanguageClick = (event) => {
    setAnchorEl_lang(event.currentTarget);
  };
  const handleLanguageClose = () => {
    setAnchorEl_lang(null);
  };

  const handleAccClick = (event) => {
    setAnchorEl_acc(event.currentTarget);
  };
  const handleAccClose = () => {
    setAnchorEl_acc(null);
  };

  const handleChangeLanguage = (newValue) => {

    props.setSession({
      ...props.session,
      language: newValue
    })
    setAnchorEl_lang(null);
  }

  const handleAccountSetting = (settingItem) => {
    props.openDrawer(settingItem);
    setAnchorEl_acc(null);
  }

  const handleSignOut = async () => {
    if (props.isConversion) {
      removeCookie('accessToken')
      removeCookie('backupEmail')
      removeCookie('uploadedFiles')
      removeCookie('previewSelected')
      removeCookie('operatorType')
      removeCookie('uploadedFilesType')
      props.clearSession()
      props.navigate("/")
    } else {
      await axios({
        method: 'post',
        url: `/api/portal/signout`,
        headers: {
          "Authorization": getCookie('accessToken')
        }
      })
        .then((response) => {
          if (response.data) {
            if (response.data.error !== '000') {
              window.alert(errormsgUtil(response.data.error, props.session.language))
            } else {
              removeCookie('accessToken')
              removeCookie('backupEmail')
              removeCookie('uploadedFiles')
              removeCookie('previewSelected')
              removeCookie('operatorType')
              removeCookie('uploadedFilesType')
              props.clearSession()
              props.navigate("/")
            }
          }
        })
        .catch((err) => {
          console.debug(err);
          if (err.response) {
            console.debug(err.response);
            console.debug(err.response.status);
            console.debug(err.message);
            console.debug(err.response.data);
            if (err.response.status === 401) {
              window.alert("Your login session has timeout.  Please re-login.");
            } else {
              window.alert("Error:" + err.message + ". Please report this error status to development team.")
            }
          } else {
            window.alert("Error:" + err.message + ". Please report this error status to development team.")
            console.debug(err.message);
          }
        })
    }
  }


  return (
    <div className="header-22">
      <div className="header-23">
        {/*<img className="logo-1" src={require("../../static/img/HKDCHub_Logo.svg").default} alt="logo" />*/}
        <img className="logo-1" src={require("../../static/img/left.svg").default} style={{scale: '90%'}} alt="logo" />
        <div className="funtions-2">
          {(props.isConversion && !props.isConversionLanding) &&(
            <>
            <div
          className="back_button"
          onClick={() => props.navigate("/Conversion")}
        >
          <img className="icon-back" src={require("../../static/img/home_button.svg").default} alt="icon" />
        </div>
            </>
          )}

          {/* language setting */}
          <Button
            className="link-4"
            aria-controls={openLanguage ? 'language_select' : undefined}
            aria-haspopup="true"
            aria-expanded={openLanguage ? 'true' : undefined}
            onClick={handleLanguageClick}
          >
            <img className="icon-22" src={require("../../static/img/-icon@2x.svg").default} alt="icon" />
          </Button>
          <img className="line-29" src={require("../../static/img/line@2x.svg").default} alt="icon" />
          <Menu
            id="language_select"
            anchorEl={anchorEl_lang}
            open={openLanguage}
            onClose={handleLanguageClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            //move containter on top
            // anchorOrigin={{
            //   vertical: 'bottom',
            //   horizontal: 'center',
            // }}
            // transformOrigin={{
            //   vertical: 'bottom',
            //   horizontal: 'center',
            // }}
          >
            <MenuItem value={'english'}
              onClick={() => handleChangeLanguage('english')}
            >
              <div className="menu_content_header">
                English
              </div>
            </MenuItem>
            <MenuItem value={'cantonese'}
              onClick={() => handleChangeLanguage('cantonese')}
            >
              <div className="menu_content_header">
                繁體中文
              </div>              
            </MenuItem>
            <MenuItem value={'mandarin'}
              onClick={() => handleChangeLanguage('mandarin')}
            >
              <div className="menu_content_header">
                简体中文
              </div>
            </MenuItem>
          </Menu>

          {/* account setting */}
          {(props.isIndiv || props.isConversion) && (
            <>
              <img className="line-29" src={require("../../static/img/line@2x.svg").default} alt="icon" />
              <Button
                className="link-4"
                aria-controls={openAccount ? 'account_setting' : undefined}
                aria-haspopup="true"
                aria-expanded={openAccount ? 'true' : undefined}
                onClick={handleAccClick}
              >
                <img className="icon-22" src={require("../../static/img/-icon-1@2x.svg").default} alt="icon" />
              </Button>
            </>
          )}
          <Menu
            id="account_setting"
            anchorEl={anchorEl_acc}
            open={openAccount}
            onClose={handleAccClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem value={'reset_password'}
              onClick={() => handleAccountSetting('reset_password')}
            >
              {languageUtil("RESET_PASSWORD", props.session.language)}
            </MenuItem>
            {props.isIndiv && (
              <div>
                <MenuItem value={'reset_personal_email'}
                  onClick={() => handleAccountSetting('reset_personal_email')}
                >
                  {languageUtil("RESET_PERSONAL_EMAIL", props.session.language)}
                </MenuItem>
                <MenuItem value={'reset_backup_email'}
                  onClick={() => handleAccountSetting('reset_backup_email')}
                >
                  {languageUtil("RESET_BACKUP_EMAIL", props.session.language)}
                </MenuItem>
              </div>
            )}
          </Menu>
          <img className="line-29" src={require("../../static/img/line@2x.svg").default} alt="icon" />


          {!props.isVerification ? (      //logout
            <div
              style={{ cursor: 'pointer' }}
              // onClick={() => props.clearSession()}
              onClick={() => { if (window.confirm(languageUtil("LOGOUT_CONFIRM_ALERT", props.session.language))) handleSignOut() }}
            >
              <Button className="link-4">
                <img className="icon-22" src={require("../../static/img/-icon-2@2x.svg").default} alt="icon" />
              </Button>
            </div>
          ) : (                            //home button for verification
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                removeCookie('accessToken')
                removeCookie('backupEmail')
                removeCookie('uploadedFiles')
                removeCookie('previewSelected')
                removeCookie('operatorType')
                removeCookie('uploadedFilesType')
                props.clearSession();
                props.navigate("/")
              }}
            >
              <Button className="link-4">
                <img className="icon-back" src={require("../../static/img/-icon-2@2x.svg").default} alt="icon" />
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
