import React from "react";
import "./WebsiteButtonS1B.css";

function WebsiteButtonS1B(props) {
  const { btnText, disableConfirm } = props;

  const handleOnClick = () => {
    if (props.indivSignUp) props.submitIndivSignup();
    else if (props.indivLogIn) props.submitIndivLogin();
    else if (props.indivChangePW) props.submitIndivChangePW();
    else if (props.indivForgotPW) props.submitIndivForgotPW();
    else if (props.indivForgotEmail) props.submitIndivForgotEmail();
    else if (props.indivChangePersonalEmail) props.submitIndivChangePrimaryEmail();
    else if (props.indivChangeBackupEmail) props.submitIndivBackupEmail();
    else if (props.convertChangePW) props.submitOperatorChangePW();
  }

  const indivButton = props.indivChangePW || props.indivChangePersonalEmail || props.indivChangeBackupEmail;

  return (
    <div
      style={props.convertChangePW
        ? { backgroundColor: 'var(--cherry-pie)', width: '355px' }
        : indivButton ? { backgroundColor: 'var(--matisse)', width: '355px' } : { backgroundColor: 'var(--matisse)', width: '400px' }
      }
      className={(disableConfirm || props.disableConfirm) ? 'button_disabled' : 'button'}
      onClick={e => {
        if (!disableConfirm && !props.disableConfirm) {
          handleOnClick()
        }
      }}>
      <div
        className="confirmSingup valign-text-middle titilliumweb-semi-bold-white-16px">
        {btnText}
      </div>
    </div>
  );
}

export default WebsiteButtonS1B;
