import './makePortfolio.css';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from '../../withRouter';
import WebsiteHeaderWithHome from "../../components/HeaderWithHome";
import languageUtil from "../../lib/languageUtil";
import ListUploadedFiles from '../../components/ListUploadedFiles';
import { getCookie, setCookie } from '../../util/cookie';

function mapStateToProps(state) {
  return { session: state.session, error: state.error }
}

export class MakePortfolio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFiles: []
    };
    this.overlay = React.createRef();
  }

  addSelectedFileID = (fileID) => {
    let tempSelectedFiles = JSON.parse(JSON.stringify(this.state.selectedFiles));
    tempSelectedFiles.push(fileID);
    this.setState({ selectedFiles: tempSelectedFiles });
  }

  delSelectedFIleID = (fileID) => {
    let tempSelectedFiles = JSON.parse(JSON.stringify(this.state.selectedFiles));
    tempSelectedFiles.splice(tempSelectedFiles.indexOf(fileID), 1);
    this.setState({ selectedFiles: tempSelectedFiles });
  }

  render() {
    return (
      <div className="container-center-horizontal">
        <div className="portfolioSelection">
          
          <WebsiteHeaderWithHome 
            title="MAKE_PORTFOLIO" 
          />
          <div style={{
            width:'100%', minHeight:'calc(100vh - 60px)', height:'auto', 
            overflowY:'auto'
          }}>
            <div className="select_in_order">
              <div className="make_portfolio_title valign-text-middle titilliumweb-bold-black-24px">
                {languageUtil("SELECT_IN_ORDER", this.props.session.language)}
              </div>
              <div
                className="clear_selection_btn"
                onClick={() => this.setState({ selectedFiles: [] })}
              >
                <div className="clear_selection titilliumweb-semi-bold-pomegranate-14px">
                  {languageUtil("CLEAR_SELECTION", this.props.session.language)}
                </div>
              </div>
            </div>
            <div className='uploaded_list'>
              <ListUploadedFiles
                isMakingPortfolio={true}
                addSelectedFileID={this.addSelectedFileID}
                delSelectedFileID={this.delSelectedFIleID}
                selectedFiles={this.state.selectedFiles}
                fileList={getCookie("uploadedFiles")}
              />
            </div>
            <div className="flex-row-btn1">
              <div className="button_next3"
                onClick={() => {
                  if(this.state.selectedFiles.length > 1) {
                    setCookie('previewSelected', [...this.state.selectedFiles])
                    this.props.navigate("/MakePortfolioPreview")
                  }
                }}
                style={this.state.selectedFiles.length > 1 
                    ? {backgroundColor:'var(--bluemain)', cursor:'pointer'}
                    : {backgroundColor:'var(--grey)', cursor:'default'}
                }
              >
                <div className="next3 valign-text-middle titilliumweb-normal-white-18px">
                  {languageUtil("NEXT", this.props.session.language)}
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    )
  }
}

export default connect(mapStateToProps)(withRouter(MakePortfolio))