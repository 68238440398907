import "./ShareCredentialsDrawer.css";
import React from "react";
import { Button, Switch, Typography } from "@mui/material";
import { connect } from 'react-redux';
import languageUtil from "../../lib/languageUtil";
import CalendarPicker from "../CalendarPicker";

function mapStateToProps(state) {
  return { session: state.session, error: state.error }
}

function ShareCredentialsDrawer(props) {

  return (
    <div className="share_drawer_container">
      <div className="share_drawer_header">
        <div className="title-drawer valign-text-middle titilliumweb-bold-black-18px">
          {languageUtil("DETAIL", props.session.language)}
        </div>
        <img
          className="icon-drawer"
          src={require("../../static/img/-icon-7@2x.svg").default}
          alt='close'
          onClick={props.closeDrawer}
        />
      </div>
      <div className="share_drawer_content">
        <div className="share_email_info">
          <Typography sx={{ width: '100px' }}>
            {languageUtil("FROM", props.session.language)}
          </Typography>
          <input
            id="share_inputs"
            className="email_info_input"
            placeholder={languageUtil("FROM_STATEMENT", props.session.language)}
            value={props.senderName}
            onChange={e => props.senderNameValidation(e.target.value)}
            autoComplete='off'
          />
        </div>
        <div className="share_email_info">
          <Typography sx={{ width: '100px' }}>
            {languageUtil("TO", props.session.language)}
          </Typography>
          <input
            id="share_inputs"
            className="email_info_input"
            placeholder={languageUtil("TO_STATEMENT", props.session.language)}
            value={props.receiverEmail}
            onChange={e => props.emailValidation(e.target.value)}
            autoComplete='off'
          />
        </div>
        <div style={{ height: '100%' }}>
          <textarea
            id="share_inputs"
            className="email_content_input"
            placeholder={languageUtil("MESSAGE_TO_RECEIVER", props.session.language)}
            value={props.messageContent}
            onChange={(e)=>props.messageValidation(e.target.value)}
            autoComplete='off'
          />
        </div>
      </div>

      <div className="share_drawer_option_container">
        <div      //expired day option container
          className="share_options"
        >
          <div
            className="share_options_toggle"
          >
            <Typography
              id="share_options_statement"
              sx={{ width: 'calc(100% - 100px)' }}
            >
              {languageUtil("EXPIRED_DAY_OF_CREDENTIALS", props.session.language)}
            </Typography>
            <Switch 
              checked={props.expdayBtn}
              onChange={()=> props.handleOptionSwitch('expday')}
            />
          </div>
          {props.expdayBtn && (
            <div
              className="share_options_input"
            >
              {/* <input
                id="share_inputs"
                style={{ 
                  outline: 'none', border: '1px solid #ECECEC', 
                  height: '55px', width: '90%',
                  paddingLeft:'10px'
                }}
                placeholder={languageUtil("EXPDAY_PLACEHOLDER", props.session.language)}
                value={props.expdayOption}
                onChange={e => props.expdayValidation(e.target.value)}
                autoComplete='off'
              /> */}
              <CalendarPicker
                style={{ 
                  outline: 'none', border: '1px solid #ECECEC', 
                  height: '55px', width: '100%',
                  paddingLeft:'10px'
                }}
                value={props.expdayOption}
                handleChange={value => props.expdayValidation(value)}
                autoComplete='off'
                placeholder={languageUtil("EXPDAY_PLACEHOLDER", props.session.language)}
              />
            </div>
          )}
        </div>
        <div      //number of view option container
          className="share_options"
        >
          <div
            className="share_options_toggle"
          >
            <Typography
              id="share_options_statement"
              sx={{ width: 'calc(100% - 100px)' }}
            >
              {languageUtil("NUMBER_OF_VIEWS", props.session.language)}
            </Typography>
            <Switch 
              checked={props.viewnumberBtn}
              onChange={()=> props.handleOptionSwitch('viewnumber')}
            />
          </div>
          {props.viewnumberBtn && (
            <div
              className="share_options_input"
            >
              <input
                id="share_inputs"
                style={{ 
                  outline: 'none', border: '1px solid #ECECEC', 
                  minHeight: '55px', width: '90%',
                  paddingLeft:'10px'
                }}
                placeholder={languageUtil("INPUT_NUMBER_PLACEHOLDER", props.session.language)}
                value={props.viewnumberOption}
                onChange={e => props.viewnumberValidation(e.target.value)}
                autoComplete='off'
              />
            </div>
          )}
        </div>
        <div
          className="share_send_btn"
          // style={{ marginTop: 'auto', marginBottom: '20px', padding: '0px 5%' }}
        >
          <Button
            variant='contained'
            disabled={Boolean(!props.senderName || !props.receiverEmail || (props.expdayOption && props.expdayOption.length < 10))}
            onClick={props.sendShareAPI}
            sx={{
              backgroundColor: 'var(--bluemain)',
              marginLeft: 'auto',
              marginRight: 'auto',
              borderRadius: '10px',
              minHeight: '60px',
              width: '100%',
              textTransform: 'none'
            }}
          >
            <Typography sx={{ color: 'white' }}>
              {languageUtil("SEND", props.session.language)}
            </Typography>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(ShareCredentialsDrawer);
