import "./WebsiteHeaderS2.css";
import React from "react";
import { Button, Typography } from "@mui/material";
import { Menu , MenuItem } from '@mui/material'
import { connect } from 'react-redux';
import { withRouter } from '../../withRouter';
import languageUtil from "../../lib/languageUtil";
import errormsgUtil from "../../lib/errormsgUtil";
import { setSession } from "../../actions/session";

function mapStateToProps(state) {
  return { session: state.session, error: state.error }
}

function mapDispatchToProps(dispatch) {
  return {
    setSession: (session) => dispatch(setSession(session))
  }
}
//select functions header 
function WebsiteHeaderS2(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { title } = props;
  const [anchorEl_lang, setAnchorEl_lang] = React.useState(null)    //open or close the selecting languages 
  const openLanguage = Boolean(anchorEl_lang);

  const handleChangeLanguage = (newValue) => {
    props.setSession({
      ...props.session,
      language: newValue
    })
    setAnchorEl_lang(null);
  }
  const handleLanguageClick = (event) => {
    setAnchorEl_lang(event.currentTarget);
  };
  const handleLanguageClose = () => {
    setAnchorEl_lang(null);
  };



  const handleClickErrorList = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleCloseErorList = () => {
    setAnchorEl(null);
  }

  const handleGoBack = () => {
    switch (title) {
      case "SELECT_FUNCTION":
        props.navigate("/ManagementUploaded");
        break;
      case "VIEW":
      case "MAKE_PORTFOLIO":
      case "SHARE":
        if (props.isVerification) {
          props.navigate("/Verification")
        } else {
          props.navigate("/ManagementUploaded")
        }
        break;
      case "PREVIEW":
        props.navigate("/MakePortfolio")
        break;
      case "CONVERSION_PREVIEW":
        props.navigate("/Conversion")
        break;
      default:
        break;
    }
  }

  const handleReturnErrorMsg = (invalidFile) => {
    return (
      <div
        style={{
          height: 'auto', maxWidth: 'auto',
          display: 'flex', flexDirection: 'row',
          padding: '10px', alignItems: 'center', 
        }}
      >
        {invalidFile.error === '302' && (
          <img
            style={{ height: '20px', width: '20px', marginRight:'10px' }}
            src={require('../../static/img/expired_file.svg').default}
            alt='expired_file'
          />
        )}
        {invalidFile.error === '303' && (
          <img
            style={{ height: '20px', width: '20px', marginRight:'10px' }}
            src={require('../../static/img/max_view_file.svg').default}
            alt='max_view_file'
          />
        )}
        {(invalidFile.error === '300' || invalidFile.error === '301' || invalidFile.error === '304') && (
          <img
            style={{ height: '20px', width: '20px', marginRight:'10px' }}
            src={require('../../static/img/invalid_file.svg').default}
            alt='invalid_file'
          />
        )}
        <div style={{display:'flex', flexDirection:'column'}}>
          <Typography variant="caption" fontWeight="bold">{invalidFile.filename}</Typography>
          <Typography variant="caption">
              {errormsgUtil(invalidFile.error, props.session.language).includes('<filename>')  
                ? errormsgUtil(invalidFile.error, props.session.language).replace('<filename>', invalidFile.filename)
                : errormsgUtil(invalidFile.error, props.session.language)}
          </Typography>
        </div>
      </div>
    )
  }

  return (
    <div className="header_selectFunction">

      <div className="title-4">
        <div className="back_line">
          <div className="overlap-group-1">
            <div
              className="back"
              onClick={handleGoBack}
            >
              <img className="icon-back" src={require("../../static/img/icon---back@2x.svg").default} alt="icon" />
            </div>
            <img className="line" src={require("../../static/img/line@2x.svg").default} alt="icon" />
          </div>
        </div>
        <div className="title-5 valign-text-middle titilliumweb-black-mine-shaft-18px">
          {languageUtil(title, props.session.language)}
        </div>
      </div>
      <div className="button_content">
      <Button
            className="link-4"
            aria-controls={openLanguage ? 'language_select' : undefined}
            aria-haspopup="true"
            aria-expanded={openLanguage ? 'true' : undefined}
            onClick={handleLanguageClick}
          >
            <img className="icon-22" src={require("../../static/img/-icon@2x.svg").default} alt="icon" />
          </Button>
          <Menu
            id="language_select"
            anchorEl={anchorEl_lang}
            open={openLanguage}
            onClose={handleLanguageClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem value={'english'}
              onClick={() => handleChangeLanguage('english')}
            >
              <div className="menu_content_header">
                English
              </div>
            </MenuItem>
            <MenuItem value={'cantonese'}
              onClick={() => handleChangeLanguage('cantonese')}
            >
              <div className="menu_content_header">
                繁體中文
              </div>
            </MenuItem>
            <MenuItem value={'mandarin'}
              onClick={() => handleChangeLanguage('mandarin')}
            >
              <div className="menu_content_header">
                简体中文
              </div>
            </MenuItem>
          </Menu>


      {props.isVerification && props.invalidFiles.length > 0  && (      //open the invalid file list 
        <>
          <Button
            aria-controls="error_list"
            sx={{
              width: '24px', height: '100%',
              margin: 'auto 0px',
              marginLeft: 'auto', marginRight: '0px',

            }}
            onClick={handleClickErrorList}
            disabled={props.invalidFiles.length === 0}
          >
            {props.invalidFiles.length > 0
              ? (
                <img        //when there are invalid/corrupted files in the list
                  className="icon-bell"
                  src={require("../../static/img/bell_notification_red.svg").default}
                  alt="icon"
                />
              )
              : undefined
            }

          </Button>
          <Menu
            id="error_list"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseErorList}
          >
            {props.invalidFiles.map((invalidFile, idx) => {
              return (
                <div key={idx} disabled>
                  {handleReturnErrorMsg(invalidFile)}
                </div>
              )
            })}
          </Menu>
        </>
      )}
      {props.isPortfolioPreview && (
        <Button
          variant="contained"
          sx={{
            backgroundColor: 'var(--bluemain)',
            marginRight: '0px',
            marginLeft: 'auto',
            borderRadius: '0px',
            height: '100%',
            width: '160px',
            textTransform: 'none'
          }}
          onClick={props.handleOpenDrawer}
        >
          {languageUtil("SAVE_AS_DRAFT", props.session.language)}
        </Button>
      )}
        </div> 
      

    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WebsiteHeaderS2));
