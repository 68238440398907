import { applyMiddleware, compose } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from '../reducers';
import thunk from 'redux-thunk';

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = configureStore({
    reducer: rootReducer, 
    enhancers: [storeEnhancers(applyMiddleware(thunk))]
});

export default store;