import './makePortfolioPreview.css';
import '../../overlayStyle.css';
import { Buffer } from "buffer";
import React, { Component } from 'react';
import { Divider, Typography, Drawer } from '@mui/material';
import { connect } from 'react-redux';
import { withRouter } from '../../withRouter';
import { getCookie, setCookie } from "../../util/cookie";
import languageUtil from "../../lib/languageUtil";
import errormsgUtil from "../../lib/errormsgUtil";
import WebsiteHeaderS2 from "../../components/WebsiteHeaderS2";
import SavePortfolio from '../../components/SavePortfolio';
import WaterMarkVQ from '../../static/img/VQWatermark.svg';
import axios from 'axios';
import { arrayItemToLast, placeCrpPosition } from '../../lib/arrayUtil';

function mapStateToProps(state) {
  return { session: state.session, error: state.error }
}

export class MakePortfolioPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewSelected: getCookie('previewSelected'),
      previewDetails: [],
      drawerToggle: false,
      portfolioName: '',
      uploadedFiles: [],
    };
    this.overlay = React.createRef();
  }

  promisedSetState = (newState) => new Promise(resolve => this.setState(newState, resolve));
  //loading image 
  showOverlay = () => {
    this.overlay.current.style.width = "100%";
  }
  hideOverlay = () => {
    if (this.overlay.current) this.overlay.current.style.width = "0%";
  }

  //call view api to read & comine each selected dch file 
  sendPreviewAPI = async (previewSelected) => {
    this.showOverlay();
    for (let i = 0; i < previewSelected.length; i++) {
      let submitData = {
        'fileid': previewSelected[i]
      }
      await axios({
        method: 'post',
        url: `/api/portal/viewcredential`,
        data: submitData,
        headers: {
          "Authorization": getCookie('accessToken')
        }
      })
        .then((response) => {
          if (response.data) {
            if (response.data.error !== '000') {
              window.alert(errormsgUtil(response.data.error, this.props.session.language))
            } else {
              let tempPreviewDetails = JSON.parse(JSON.stringify(this.state.previewDetails))
              tempPreviewDetails.push(response.data.file.data[0])
                this.setState({ previewDetails: tempPreviewDetails })
              //prevention to group duplicated files 
              // if (!tempPreviewDetails.includes(response.data.file.data[0])) {
              //   tempPreviewDetails.push(response.data.file.data[0])
              //   this.setState({ previewDetails: tempPreviewDetails })
              // }
            }
          }
        })
        .catch((err) => {
          console.debug(err);
          if (err.response) {
            console.debug(err.response);
            console.debug(err.response.status);
            console.debug(err.message);
            console.debug(err.response.data);
            if (err.response.status === 401) {
              window.alert("Your login session has timeout.  Please re-login.");
            } else {
              window.alert("Error:" + err.message + ". Please report this error status to development team.")
            }
          } else {
            window.alert("Error:" + err.message + ". Please report this error status to development team.")
            console.debug(err.message);
          }
        })
    }
    this.hideOverlay();
  }

  //check the new portfolio name is duplicated. if dudplicated, set a new name 
  checkDuplicatedFilename = (newPortfolioName) => {
    let newFilename = newPortfolioName + '.portfolio';
    let existingFilenames = [];
    let returningObj = {};

    for (let i = 0; i < getCookie("uploadedFilesType").length; i++) {
      let objFilename = Object.keys(getCookie("uploadedFilesType")[i]);
      existingFilenames.push(...objFilename);
    }
    if (existingFilenames.includes(newFilename)) {
      let duplicatedNum = 1;
      while (existingFilenames.includes(newFilename)) {
        newFilename = newPortfolioName + `_${duplicatedNum}.portfolio`;
        duplicatedNum ++; 
      }

    }

    returningObj[newFilename] = 'PORTFOLIO';

    return (returningObj)
  }

  //send post request to server when user click save button to create portfolio
  sendGroupAPI = async () => {
    const submitData = {
      "files": this.state.previewSelected,
      "return_file": 1,
      "name": this.state.portfolioName
    }

    this.closeDrawer();
    this.showOverlay();

    let portfolioFileID = []
    for (let i = 0; i < this.state.previewSelected.length; i++) {
      portfolioFileID.push(this.state.previewSelected[i])
    }

    let createObj = {}
    createObj[portfolioFileID] = this.state.portfolioName + '.portfolio'

    let tempUpload = getCookie("uploadedFiles")
    let orginialUploadList = getCookie("uploadedFiles")
    tempUpload.push(createObj)
    await setCookie("uploadedFiles", tempUpload)
    await this.promisedSetState({ uploadedFiles: getCookie("uploadedFiles") });
    
    if (this.state.uploadedFiles.length < tempUpload.length) {
      window.alert(languageUtil("COOKIE_CANNOT_UPDATE", this.props.session.language))
      await setCookie("uploadedFiles", orginialUploadList)
      this.hideOverlay()
      this.props.navigate("/ManagementUploaded")
    }else{      
      await axios({
      method: 'post',
        url: `/api/portal/groupcredential`,
        data: submitData,
        // responseType: 'blob',
        headers: {
          "Authorization": getCookie('accessToken')
        }
      })
        .then((response) => {
          if (response.data) {
            if (response.data.error && response.data.error !== '000') {
              window.alert(errormsgUtil(response.data.error, this.props.session.language))
            } else {
              let tmpUploadedFilesType = getCookie('uploadedFilesType');

              let tmpnewFilestype = this.checkDuplicatedFilename(this.state.portfolioName);
              // tmpnewFilestype[this.state.portfolioName + ".portfolio"] = 'PORTFOLIO';
              tmpUploadedFilesType.push(tmpnewFilestype)

              setCookie('uploadedFilesType', tmpUploadedFilesType);
              //create file link in browser's memory
              const href = URL.createObjectURL(new Blob([response.data], { type: 'text/plain;charset=utf-8' }));
              //create "a" HTML element with href to file & click
              const link = document.createElement('a');
              link.href = href;
              link.setAttribute('download', this.state.portfolioName + '.portfolio');
              document.body.appendChild(link);
              link.click();
  
              window.alert(languageUtil("SUCCEED_GROUP_PORTFOLIO", this.props.session.language))
              //clean up "a" element & remove ObjectURL
              document.body.removeChild(link);
              URL.revokeObjectURL(href);
              this.closeDrawer();
              this.props.navigate("/ManagementUploaded")
            }
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              window.alert("Your login session has timeout.  Please re-login.");
            } else {
              window.alert("Error:" + err.message + ". Please report this error status to development team.")
            }
          } else {
            window.alert("Error:" + err.message + ". Please report this error status to development team.")
            console.debug(err.message);
          }
        }).finally(this.hideOverlay);
    }

  }

  /** let newPortfolio = this.checkDuplicatedFilename(this.state.portfolioName)
    let tempUpload = getCookie("uploadedFiles")
    tempUpload.push(newPortfolio)
    await setCookie("uploadedFiles",tempUpload)
    console.log("check")
    if (getCookie("uploadedFiles").length < tempUpload.length){
      window.alert(languageUtil("COOKIE_CANNOT_UPDATE", this.props.session.language))
      this.hideOverlay()
      this.props.navigate("/ManagementUploaded")
    }else{      
      await axios({
      method: 'post',
        url: `/api/portal/groupcredential`,
        data: submitData,
        // responseType: 'blob',
        headers: {
          "Authorization": getCookie('accessToken')
        }
      })
        .then((response) => {
          if (response.data) {
            if (response.data.error && response.data.error !== '000') {
              window.alert(errormsgUtil(response.data.error, this.props.session.language))
            } else {
              let tmpUploadedFilesType = getCookie('uploadedFilesType');
              let tmpnewFilestype = this.checkDuplicatedFilename(this.state.portfolioName);
              // tmpnewFilestype[this.state.portfolioName + ".portfolio"] = 'PORTFOLIO';
              tmpUploadedFilesType.push(tmpnewFilestype)
              setCookie('uploadedFilesType', tmpUploadedFilesType);
              //create file link in browser's memory
              const href = URL.createObjectURL(new Blob([response.data], { type: 'text/plain;charset=utf-8' }));
              //create "a" HTML element with href to file & click
              const link = document.createElement('a');
              link.href = href;
              link.setAttribute('download', this.state.portfolioName + '.portfolio');
              document.body.appendChild(link);
              link.click();
  
              window.alert(languageUtil("SUCCEED_GROUP_PORTFOLIO", this.props.session.language))
              //clean up "a" element & remove ObjectURL
              document.body.removeChild(link);
              URL.revokeObjectURL(href);
              this.closeDrawer();
              this.props.navigate("/ManagementUploaded")
            }
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              window.alert("Your login session has timeout.  Please re-login.");
            } else {
              window.alert("Error:" + err.message + ". Please report this error status to development team.")
            }
          } else {
            window.alert("Error:" + err.message + ". Please report this error status to development team.")
            console.debug(err.message);
          }
        }).finally(this.hideOverlay);
    }
 */

  displayArrayContent = (arrayContent) => {
    return (
      arrayContent.map((contentItem, contentIdx) => {
        return (
          <Typography key={contentIdx} style={{ fontWeight: 'bold' }}>
            {contentItem}
          </Typography>
        )
      })
    )
  }

  //display the credential object one by one 
  displayCredObj = () => {
    let decodedFileData = [];
    if (this.state.previewDetails.length > 1) {
      for (let i = 0; i < this.state.previewDetails.length; i++) {
        let decodedRequestBodyString = Buffer.from(this.state.previewDetails[i], "base64");
        decodedFileData.push(JSON.parse(decodedRequestBodyString));
      }
    }
    //able to display QA
    return (
      <div
        style={{
          width: 'calc(100% - 100px)', height: 'auto',
          minWidth: '100%', margin: '50px 0px'
        }}
      >
        {decodedFileData.map((fileObj, fileIdx) => {
          let CredKeys = Object.keys(fileObj);
          let isQR = fileObj.source === 'QR';
          let isQA = fileObj.source === 'QA';

          if (isQA && !CredKeys.includes('qf_level')) {
            if (!CredKeys.includes('student_name')) {
              CredKeys.unshift("qf_level")
            } else {
              CredKeys.splice(1, 0, "qf_level")
            }
          }

          //re-ordering the attributes 
          if (CredKeys.includes('assessment_outcomes')) CredKeys = arrayItemToLast(CredKeys, 'assessment_outcomes')
          if (CredKeys.includes('educational_qualifications_considered')) CredKeys = arrayItemToLast(CredKeys, 'educational_qualifications_considered')
          if (CredKeys.includes('note')) CredKeys = arrayItemToLast(CredKeys, 'note')
          if (CredKeys.includes('crp') && isQR) CredKeys = placeCrpPosition(CredKeys);

          return (
            <div
              key={fileIdx}
              style={{
                minWidth: '100%'
              }}
            >
              {fileIdx > 0 && (
                <Divider style={{ width: '100%', margin: '20px 0px' }} />
              )}
              <Typography variant="h5" style={{ fontWeight: 'bold' }} >
                {fileObj.qualification_title && fileObj.qualification_title.toUpperCase()}
                {fileObj.report_title && languageUtil("QA_REPORT_TITLE", this.props.session.language)}
              </Typography>
              <div
                className='listing_credentials'
                style={{
                  backgroundImage: `url(${WaterMarkVQ})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize: '25%'
                }}>
                {CredKeys.map((cred_key, cred_idx) => {
                  let notDisplayKeys = ['qualification_title', 'report_title', 'source'];
                  let fullDisplayKeys = ['assessment_outcomes', 'educational_qualifications_considered', 'note'];
                  // if (cred_key !== 'qualification_title' && cred_key !== 'report_title' && cred_key !== 'source') {
                  if (!notDisplayKeys.includes(cred_key) && !fullDisplayKeys.includes(cred_key)) {
                    return (
                      <div key={cred_idx} style={{ minWidth: '50%', marginTop: '20px' }}>
                        <Typography style={{ color: 'grey' }}>
                          {languageUtil(cred_key.toUpperCase(), this.props.session.language)}
                        </Typography>
                        {Array.isArray(fileObj[cred_key]) && fileObj[cred_key].length > 1
                          ? this.displayArrayContent(fileObj[cred_key])
                          : <Typography style={{ fontWeight: 'bold', textAlign: 'justify' }}>
                            {cred_key === "region"
                              ? this.props.session.language === "english"
                                ? fileObj[cred_key].includes("Hong Kong") && ("Hong Kong")
                                : fileObj[cred_key].includes("香港") && ("香港")
                              : cred_key === "qf_level" && fileObj[cred_key] === undefined
                                ? "/"
                                : fileObj[cred_key]
                            }
                          </Typography>
                        }
                      </div>
                    )
                  } else if (fullDisplayKeys.includes(cred_key)) {
                    return (
                        <div key={cred_idx} style={{ minWidth: '100%', marginTop: '20px' }}>
                            <Typography style={{ color: 'grey' }}>
                                {languageUtil(cred_key.toUpperCase(), this.props.session.language)}
                            </Typography>
                            {Array.isArray(fileObj[cred_key]) && fileObj[cred_key].length > 1
                                ? this.displayArrayContent(fileObj[cred_key])
                                : <Typography style={{ fontWeight: 'bold' ,textAlign : 'justify'}}>
                                    {cred_key === "region"
                                        ? this.props.session.language === "english"
                                            ? fileObj[cred_key].includes("Hong Kong") && ("Hong Kong")
                                            : fileObj[cred_key].includes("香港") && ("香港")
                                        : cred_key === "qf_level" && fileObj[cred_key] === undefined
                                            ? "/"
                                            : fileObj[cred_key]
                                    }
                                </Typography>
                            }
                        </div>
                    )
                } else {
                    return undefined
                  }
                })}
              </div>
              {isQR && (
                <div style={{ width: '100%' }}>
                  <img style={{
                    scale: '120%',
                    marginLeft: '95%',
                    marginRight: '0px'
                  }}
                    src={require("../../static/img/qf_logo.svg").default}
                    alt="qf_logo"
                  />
                </div>
              )}
            </div>
          )
        })}
      </div>
    )
  }

  //toggle switch for opening the drawer to save the portfolio
  handleOpenDrawer = () => {
    this.setState({ drawerToggle: true })
  }
  //close drawer for the account settings 
  closeDrawer = () => {
    this.setState({ drawerToggle: false })
  }
  //change portfolio name
  changePortfolioName = async (newValue) => {
    let regEx = /^(?!\s)[0-9a-zA-Z _()-]+$/;

    if (newValue.length < 30 && regEx.test(newValue)) {
      await this.setState({ portfolioName: newValue })
    }

    if (newValue === '') {
      await this.setState({ portfolioName: '' })
    }
  }

  //call the view api for preview by getting the detail info 
  componentDidMount = () => {
    this.sendPreviewAPI(this.state.previewSelected);
  }

  render() {

    return (
      <div className="container-center-horizontal">
        <div ref={this.overlay} className="overlay_cover">
          <div className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
        </div>
        <div className="preview_container">
          <WebsiteHeaderS2
            title="PREVIEW"
            isPortfolioPreview={true}
            handleOpenDrawer={this.handleOpenDrawer}
          />
          <div className="preview_page">
            <div
              style={{
                height: '130px', width: '100%', minWidth: '402px',
                display: 'flex', flexDirection: 'column', gap: '20px'
              }}
            >
              <div
                style={{
                  margin: 'auto auto', display: 'flex',
                }}
              >
                <img style={{ scale: '130%' }} src={require("../../static/img/vq_logo.svg").default} alt="vq_logo" />
              </div>
              <div
                style={{
                  margin: 'auto auto',
                  padding: '7px',
                  backgroundColor: '#F7FAFC',
                }}
              >
                <Typography variant='caption' style={{ color: '#125D98', fontWeight: 'bold' }}>
                  {languageUtil("PORTFOLIO_STATEMENT", this.props.session.language)}
                </Typography>
              </div>
            </div>
            <Divider style={{ width: '100%' }} />
            {this.displayCredObj()}
          </div>
        </div>
        <Drawer
          anchor='right'
          open={this.state.drawerToggle}
          onClose={this.closeDrawer}
        >
          <SavePortfolio
            closeDrawer={this.closeDrawer}
            portfolioName={this.state.portfolioName}
            changePortfolioName={this.changePortfolioName}
            sendGroupAPI={this.sendGroupAPI}
          />
        </Drawer>
      </div>
    )
  }

}

export default connect(mapStateToProps)(withRouter(MakePortfolioPreview))