import "./verification.css";
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from '../../withRouter';
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import Steps from "../../components/Steps";
import languageUtil from "../../lib/languageUtil";
import Dropzone from 'react-dropzone';

function mapStateToProps(state) {
    return { session: state.session, error: state.error }
}

export class Verification extends Component {
    constructor(props) {
        super(props);
        this.onDrop = (uploadedFiles) => {
            this.onDropFiles(uploadedFiles)
        };
        this.state = {
            drawerToggle: false,
            whichSetting: ''
        };
        this.overlay = React.createRef();
    }

    //when file added by onDrop 
    onDropFiles = async (uploadingfiles) => {
        await this.props.navigate('/VerificationUploaded', {state:{uploadingfiles: uploadingfiles}})
    }

    render() {
        return (
            <div className="verification">
                <Header
                    openDrawer={this.openDrawer}
                    isVerification={true}
                />
                <div className="verification_page_info titilliumweb-bold-black-24px">
                    {
                        (this.props.session.language === 'english')
                            ? languageUtil("CREDENTIALS", this.props.session.language) + ' '
                            + languageUtil("VERIFICATION", this.props.session.language)
                            : languageUtil("CREDENTIALS", this.props.session.language) + languageUtil("VERIFICATION", this.props.session.language)
                    }

                </div>
                
                <Dropzone
                    noClick={true}
                    onDrop={this.onDrop}
                    style={{ width: '100%', heigth: '100%' }}
                >
                    {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps({ className: 'dropzone' })} className="upload_-field-7">
                            <input {...getInputProps({ className: 'dropzone' })} />
                            <img style={{width:'200px', height:'200px', opacity:'0.2'}} src={require("../../static/img/drag-drop.svg").default} alt="dragDropImg" />
                            <Link to="/VerificationUploaded">
                                <div className="button_-upload-7">
                                    <div className="upload-19 valign-text-middle titilliumweb-normal-white-18px">
                                        {languageUtil("UPLOAD", this.props.session.language)}
                                    </div>
                                </div>
                            </Link>
                        </div>
                    )}
                </Dropzone>
                <Steps
                    stepDetail1Props={{
                        receive: languageUtil("RECEIVE", this.props.session.language),
                        receviedDigitalCre: languageUtil("VERIFY_RECEIVE_STATEMENT", this.props.session.language),
                        step1: languageUtil("STEP_1", this.props.session.language)
                    }}
                    stepDetail2Props={{
                        receive: languageUtil("UPLOAD", this.props.session.language),
                        receviedDigitalCre: languageUtil("VERIFY_UPLOAD_STATEMENT", this.props.session.language),
                        step1: languageUtil("STEP_2", this.props.session.language)
                    }}
                    stepDetail3Props={{
                        receive: languageUtil("PROCESS", this.props.session.language),
                        receviedDigitalCre: languageUtil("VERIFY_PROCESS_STATEMENT", this.props.session.language),
                        step1: languageUtil("STEP_3", this.props.session.language)
                    }}
                    isCorporate={true}
                />
            </div>
        )
    }
}
export default connect(mapStateToProps)(withRouter(Verification))