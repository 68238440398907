import React from "react";
import "./Group1332.css";
import { removeCookie } from '../../util/cookie';

function Group1332(props) {
  const { title, subtitle, className } = props;

  return (
    <div className={`title-43 ${className || ""}`}>
      <div className="indicator-2"></div>
      <div className="flex-col-14">
        <div style={{display:'flex', flexDirection:'row', width:'100%'}}>
          <div className="fogot-password titilliumweb-bold-martinique-24px">
            {title}
          </div>
          <img
            className="cancel_indiv_changePW"
            src={require("../../static/img/-icon-7@2x.svg").default}
            alt='close'
            onClick={() => {
              props.navigate("/managementLogin")
              removeCookie('accessToken')
            }}
          />
        </div>
        <p className="please-enter-persona titilliumweb-normal-mountain-mist-14px">{subtitle}</p>
      </div>
    </div>
  );
}

export default Group1332;
