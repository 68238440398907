import './ListUploadedFiles.css'
import { React } from 'react'
import { connect } from 'react-redux';
import { Divider, Typography, Button } from '@mui/material';
import { getCookie, setCookie } from "../../util/cookie";
import languageUtil from "../../lib/languageUtil";
import errormsgUtil from "../../lib/errormsgUtil";
import axios from 'axios';

function mapStateToProps(state) {
  return { session: state.session, error: state.error }
}

function ListUploadedFiles(props) {
  //handling delete the uploaded file 
  const handleDeleteFile = async (fileID, filename) => {
    const submitData = {
      'files': [fileID]
    }

    await axios({
      method: 'post',
      url: `/api/portal/deletecredential`,
      data: submitData,
      headers: {
        "Authorization": getCookie('accessToken')
      }
    })
      .then((response) => {
        if (response.data) {
          if (response.data.error !== '000') {
            window.alert(errormsgUtil(response.data.error, props.session.language))
          } else {
            let newUploadedFiles = getCookie("uploadedFiles");
            let newUploadedFilesType = getCookie("uploadedFilesType")
            for (let i = 0; i < newUploadedFiles.length; i++) {
              if (Object.keys(newUploadedFiles[i])[0] === fileID) {
                newUploadedFiles.splice(i, 1)
              }
            };
            for (let j = 0; j < newUploadedFilesType.length; j++) {
              if (Object.keys(newUploadedFilesType[j])[0] === filename) {
                newUploadedFilesType.splice(j, 1)
              }
            };
            setCookie("uploadedFiles", [...newUploadedFiles]);
            setCookie("uploadedFilesType", [...newUploadedFilesType]);
            props.refreshList();
          }
        }
      })
      .catch((err) => {
        console.debug(err);
        if (err.response) {
          console.debug(err.response);
          console.debug(err.response.status);
          console.debug(err.message);
          console.debug(err.response.data);
          if (err.response.status === 401) {
            window.alert("Your login session has timeout.  Please re-login.");
          } else {
            window.alert("Error:" + err.message + ". Please report this error status to development team.")
          }
        } else {
          window.alert("Error:" + err.message + ". Please report this error status to development team.")
          console.debug(err.message);
        }
      })
  }

  //handle selecting files to create portfolio
  const handleSelectFile = (fileID) => {
    if (props.selectedFiles.includes(fileID)) {
      props.delSelectedFileID(fileID)
    } else {
      props.addSelectedFileID(fileID)
    }
  }

  //display Icon based on the file_type of the file
  const displayIcon = (file) => {
    let fileName = Object.values(file)[0];
    let filesWithTypes = getCookie('uploadedFilesType');
    let filetype = '';
    if (filesWithTypes && filesWithTypes.length > 0) {
      for (let fileWithType of filesWithTypes) {
        if (Object.keys(fileWithType)[0] === fileName) filetype = Object.values(fileWithType)[0]
      }
    }


    let whichIcon = '';
    let isSelected = false;
    if (props.selectedFiles && props.selectedFiles.length > 0) {
      isSelected = props.selectedFiles.includes(Object.keys(file)[0]);
    }

    if (filetype === 'DCH') {
      whichIcon = 'DCH.png';
    } else if (filetype === 'PORTFOLIO') {
      whichIcon = 'Portfolio.png';
    } else {
      whichIcon = 'Attachment.png';
    }

    const showNumberSelected = (fileID) => {
      let selectedIdx = '';
      if (props.selectedFiles.includes(fileID)) {
        selectedIdx = props.selectedFiles.indexOf(fileID) + 1;
      } else {
        selectedIdx = '';
      }
      return (
        <>{selectedIdx}</>
      )
    }

    const makePortfolioDisplayBtn = () => {
      return (
        <button
          style={{
            width: '100%', height: '100%',
            border: 'none', background: 'none',
            cursor: 'pointer'
          }}
          onClick={() => handleSelectFile(Object.keys(file)[0])}
        >
          <div
            style={isSelected
              ? {
                maxWidth: '23px', height: '23px', borderRadius: '23px',
                backgroundColor: 'black', border: '1px solid grey',
                margin: 'auto', display:'flex', paddingTop:'2px'
              }
              : {
                maxWidth: '23px', height: '23px', borderRadius: '23px',
                backgroundColor: 'white', border: '1px solid grey',
                margin: 'auto', display:'flex'
              }
            }
          >
            <Typography
              variant='caption'
              style={
                isSelected
                  ? { color: 'white', margin: 'auto' }
                  : { color: 'black', margin: 'auto' }
              }
            >
              {showNumberSelected(Object.keys(file)[0])}
            </Typography>
          </div>
        </button>
      )
    }

    return (
      <>
        <div className='icon_container'>
          <img
            src={require('../../static/img/' + whichIcon)}
            alt="icon"
          />
          <div className='uploaded_filename'>
            <Typography variant='caption'>
              {fileName}
            </Typography>
          </div>
        </div>

        <Divider style={{ width: '100%' }} />
        {props.isMakingPortfolio || props.isShareCred
          ? (
            <div
              style={{
                display: 'flex', alignItems: 'center', justifyContent: 'center',
                width: '100%', minHeight: '30px'
              }}

            >
              {/* display button & numbering for selecting dch files when making portfolio */}
              {props.isMakingPortfolio && makePortfolioDisplayBtn()}
              {/* display button & check icon for sharing files */}
              {props.isShareCred && (
                <button
                  style={{
                    width: '100%', height: '100%',
                    border: 'none', background: 'none',
                    cursor: 'pointer'
                  }}
                  onClick={() => handleSelectFile(Object.keys(file)[0])}
                >
                  <div
                    style={isSelected
                      ? {
                        maxWidth: '23px', height: '23px', borderRadius: '23px',
                        backgroundColor: 'black', border: '1px solid grey',
                        margin: 'auto'
                      }
                      : {
                        maxWidth: '23px', height: '23px', borderRadius: '23px',
                        backgroundColor: 'white', border: '1px solid grey',
                        margin: 'auto'
                      }
                    }
                  >
                    <Typography
                      variant='caption'
                      style={
                        isSelected
                          ? { color: 'white' }
                          : { color: 'black' }
                      }
                    >
                      <img src={require('../../static/img/checked_white.svg').default} alt='checked' />
                    </Typography>
                  </div>
                </button>
              )}
            </div>
          )
          : (
            <Button
              variant='text'
              style={{ width: '100%', minHeight: '30px', textTransform: 'none' }}
              onClick={() => handleDeleteFile(Object.keys(file)[0], Object.values(file)[0])}
            >
              <Typography
                variant='body2'
                sx={{ color: 'red', display: 'inline', textDecoration: 'underline' }}
                display='inline'
              >
                {languageUtil("DELETE", props.session.language)}
              </Typography>
            </Button>
          )
        }
      </>
    )
  }

  const returnFileCardItem = (file, idx) => {
    return (
      <div
        className='file_card'
        key={idx}
      >
        {Object.values(file)[0] && displayIcon(file)}
      </div>
    )
  }

  const displayFileItemCard = (file, idx) => {
    if (!props.isMakingPortfolio) {
      return (returnFileCardItem(file, idx))
    } else {
      let fileItemName = Object.values(file)[0];
      let savedFileTypeList = getCookie("uploadedFilesType");
      if (savedFileTypeList && savedFileTypeList.length > 0) {
        for (let fileTypeItem of savedFileTypeList) {
          if (Object.keys(fileTypeItem)[0] === fileItemName && Object.values(fileTypeItem)[0] === "DCH") {
            return (returnFileCardItem(file, idx))
          }
        }
      }
    }
  }
  return (
    <>
      {(Array.isArray(getCookie("uploadedFiles")) && getCookie("uploadedFiles").length > 0) 
        && getCookie("uploadedFiles").map((file, idx) => {
        return (displayFileItemCard(file, idx))
      })}
    </>
  )
}

export default connect(mapStateToProps)(ListUploadedFiles)