// import { Cookies } from 'react-cookie';
// const cookies = new Cookies()
import cookies from 'js-cookie';

const parseName = ["uploadedFiles", "previewSelected", "uploadedFilesType"]
export const setCookie = (name, value) => {
    // return cookies.set(name, value,{
    //     path: "/",
    //     secure:true,
    //     sameSite:"none"
    // })

    if (parseName.includes(name)) {
        return cookies.set(name, JSON.stringify(value), {
            // path: "/",
            //secure:true,
            // sameSite:"none"
        })
    } else {
        return cookies.set(name, value, {
            // path: "/",
            //secure:true,
            // sameSite:"none"
        })
    }
}

export const getCookie = (name, option) => {
    // return cookies.get(name, {...option})
    if (parseName.includes(name) && cookies.get(name, {...option})) {
        return JSON.parse(cookies.get(name, {...option}))
    } else {
        return cookies.get(name, {...option})
    }
}

export const removeCookie = (name) => {
    return cookies.remove(name)
}