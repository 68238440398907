import "./WebsiteInputFieldSmall.css";
import React, { useState } from "react";
import { connect } from 'react-redux';
import languageUtil from "../../lib/languageUtil";
import { Typography } from "@mui/material"

function mapStateToProps(state) {
  return { session: state.session, error: state.error }
}

function WebsiteInputFieldSmall(props) {
  const { inputType, inputPlaceholder, className } = props;
  let [isPWValid, setIsPWValid] = useState(null);
  let [isEmailValid, setIsEmailValid] = useState(null);

  //input validation for userEmail(signup and signin)
  const inputValidationEmail = async (newValue) => {
    newValue = newValue.replace(/\s/g, '');
    let regEx = /\S+@\S+\.\S+/;
    await setIsEmailValid(regEx.test(newValue));

    if (newValue.length <= 64) {
      if (inputPlaceholder === 'ORIGINAL_PERSONAL_EMAIL') {
        props.setOldPersonalEmail(newValue)
      }
      if (inputPlaceholder === 'NEW_PERSONAL_EMAIL') {
        props.setNewPersonalEmail(newValue)
      }
      if (inputPlaceholder === 'NEW_BACKUP_EMAIL') {
        props.setNewBackupEmail(newValue)
      }
    }
  }

  //input validation for password
  const inputValidationPassword = async (newValue, inputPlaceholder) => {
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");

    //to chagne password: pass the password to the parent 
    switch (inputPlaceholder) {
      case 'ORIGINAL_PASSWORD':
        props.setOriginalPW(newValue);
        break;
      case 'NEW_PASSWORD':
        props.setNewPW(newValue)
        break;
      case 'CONFIRM_PASSWORD':
        props.setConfirmPW(newValue)
        break;
      default:
        break;
    }

    // if(e.target.value.length >= 5) {
    if (strongRegex.test(newValue)) {
      setIsPWValid(true);
    } else {
      setIsPWValid(false);
    }
  }

  const handleInputChange = (newValue) => {
    switch (inputPlaceholder) {
      case 'ORIGINAL_PASSWORD':
      case 'NEW_PASSWORD':
      case 'CONFIRM_PASSWORD':
        inputValidationPassword(newValue, inputPlaceholder);
        break;
      case 'ORIGINAL_PERSONAL_EMAIL':
      case 'NEW_PERSONAL_EMAIL':
      case 'NEW_BACKUP_EMAIL':
        inputValidationEmail(newValue, inputPlaceholder);
        break;
      default:
        break;
    }
  }

  return (

    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div
        className={`website-input_field-small ${className || ""}`}
        style={(!isPWValid && (props.newPassword || props.confirmPassword))
          || (!isEmailValid && (props.newPersonalEmail || props.oldPersonalEmail || props.newBackupEmail))
          ? { borderColor: 'red' }
          : { borderColor: 'var(--line)' }}
      >
        <input
          className="input_-value-4 titilliumweb-normal-mountain-mist-16px"
          name="input_value"
          placeholder={languageUtil(inputPlaceholder, props.session.language)}
          type={inputType}
          onChange={e => handleInputChange(e.target.value)}
          value={
            inputPlaceholder === 'ORIGINAL_PASSWORD'
              ? props.originalPW
              : inputPlaceholder === 'NEW_PASSWORD'
                ? props.newPW
                : inputPlaceholder === 'CONFIRM_PASSWORD'
                  ? props.confirmPW
                  : inputPlaceholder === 'ORIGINAL_PERSONAL_EMAIL'
                    ? props.oldPersonalEmail
                    : inputPlaceholder === 'NEW_PERSONAL_EMAIL'
                      ? props.newPersonalEmail
                      : inputPlaceholder === 'NEW_BACKUP_EMAIL'
                        ? props.newBackupEmail
                        : undefined
          }
        />
      </div>
      <Typography
        className="AccSettingInputWarning"
        variant="caption"
      >
        {(inputType === 'password' && !isPWValid && !props.passwordUnmatched && (props.newPassword || props.confirmPassword))
          && languageUtil("INVALID_PASSWORD", props.session.language)}
        {props.passwordUnmatched
          && languageUtil("CONFIRM_PASSWORD_ERROR", props.session.language)}
        {(inputType === 'email' && !isEmailValid && (props.newPersonalEmail || props.oldPersonalEmail || props.newBackupEmail))
          && languageUtil("INVALID_EMAIL", props.session.language)}
      </Typography>
    </div>
  );
}

export default connect(mapStateToProps)(WebsiteInputFieldSmall);
