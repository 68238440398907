import "./operatorLog.css";
import '../../overlayStyle.css';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from '../../withRouter';
import { getCookie } from "../../util/cookie";
// import { setSession } from "../../actions/session";
import Header from "../../components/Header";
import languageUtil from "../../lib/languageUtil";
import errormsgUtil from "../../lib/errormsgUtil";
import axios from 'axios';
import { Drawer } from '@mui/material';
import ManagementSettingChangePW from "../../components/ManagementSettingChangePW";
import CalendarPicker from "../../components/CalendarPicker";
import OperatorLogTable from "../../components/OperatorLogTable";

const tzoffset = (new Date()).getTimezoneOffset() * 60000;
const initCurrentDate = new Date(new Date() - tzoffset).toISOString().split('T')[0];
const newFormCurrentDate = initCurrentDate.split('-')[2] + "/" + initCurrentDate.split('-')[1] + "/" + initCurrentDate.split('-')[0];

function mapStateToProps(state) {
    return { session: state.session, error: state.error }
}

// function mapDispatchToProps(dispatch) {
//     return {
//         setSession: (session) => dispatch(setSession(session))
//     }
// }

export class OperatorLog extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            drawerToggle: false,
            whichSetting: '',
            rangeStart: newFormCurrentDate,
            rangeEnd: newFormCurrentDate,
            logList: []
        };
        this.overlay = React.createRef();
    }

    //do when the page is mounted
    componentDidMount = () => {
        this.getActivityLog();
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevState.rangeStart !== this.state.rangeStart || prevState.rangeEnd !== this.state.rangeEnd) {
            this.getActivityLog();
        }
    }

    //loading image 
    showOverlay = () => {
        this.overlay.current.style.width = "100%";
    }
    hideOverlay = () => {
        if (this.overlay.current) this.overlay.current.style.width = "0%";
    }

    //open drawer for the account settings
    openDrawer = (settingItem) => {
        this.setState({
            drawerToggle: true,
            whichSetting: settingItem
        })
    }
    //close drawer to close the account settings 
    closeDrawer = () => {
        this.setState({
            drawerToggle: false,
            whichSetting: ''
        })
    }

    //api request to get the activity log 
    getActivityLog = async () => {
        let {rangeStart, rangeEnd} = this.state;
        let fromDateValue = rangeStart.split("/")[2] + "-" + rangeStart.split("/")[1] + "-" + rangeStart.split("/")[0];
        let toDateValue = rangeEnd.split("/")[2] + "-" + rangeEnd.split("/")[1] + "-" + rangeEnd.split("/")[0];

        this.showOverlay();
        await axios({
            method: 'get',
            url: `/api/operator/operatorlog/${fromDateValue}/${toDateValue}`,
            headers: {
                "Authorization": getCookie('accessToken')
            }
        })
            .then((response) => {
                if (response.data) {
                    if (response.data.error !== '000') {
                        window.alert(errormsgUtil(response.data.error, this.props.session.language))
                    } else {
                        this.setState({logList: response.data.log})
                    }
                }
            })
            .catch((err) => {
                console.debug(err);
                if (err.response) {
                    console.debug(err.response);
                    console.debug(err.response.status);
                    console.debug(err.message);
                    console.debug(err.response.data);
                    if (err.response.status === 401) {
                        window.alert("Your login session has timeout.  Please re-login.");
                    } else {
                        window.alert("Error:" + err.message + ". Please report this error status to development team.")
                    }
                } else {
                    window.alert("Error:" + err.message + ". Please report this error status to development team.")
                    console.debug(err.message);
                }
            }).finally(this.hideOverlay);
        
    }

    //send new password information to the API server. 
    submitOperatorChangePW = async (oldPassword, confirmPassword) => {
        const submitData = {
            'oldpassword': oldPassword,
            'newpassword': confirmPassword
        }

        this.closeDrawer();
        this.showOverlay();
        await axios({
            method: 'post',
            url: `/api/operator/changepassword`,
            data: submitData,
            headers: {
                "Authorization": getCookie('accessToken')
            }
        })
            .then((response) => {
                if (response.data) {
                    if (response.data.error !== '000') {
                        window.alert(errormsgUtil(response.data.error, this.props.session.language))
                    } else {
                        window.alert(languageUtil("SUCCEED_CHANGEPW", this.props.session.language))
                        this.closeDrawer();
                        this.props.navigate("/ConversionLogin");
                    }
                }
            })
            .catch((err) => {
                console.debug(err);
                if (err.response) {
                    console.debug(err.response);
                    console.debug(err.response.status);
                    console.debug(err.message);
                    console.debug(err.response.data);
                    if (err.response.status === 401) {
                        window.alert("Your login session has timeout.  Please re-login.");
                    } else {
                        window.alert("Error:" + err.message + ". Please report this error status to development team.")
                    }
                } else {
                    window.alert("Error:" + err.message + ". Please report this error status to development team.")
                    console.debug(err.message);
                }
            }).finally(this.hideOverlay);
    }

    render() {
        return (
            <div className="oplog_container">
                <div ref={this.overlay} className="overlay_cover">
                    <div className="spinner">
                        <div className="bounce1"></div>
                        <div className="bounce2"></div>
                        <div className="bounce3"></div>
                    </div>
                </div>
                <Header
                    openDrawer={this.openDrawer}
                    isConversion={true}
                />
                <div className="oplog_page_info">
                    <div className="titilliumweb-bold-black-24px" style={{ color: 'black', display: 'flex' }}>
                        {languageUtil("ACTIVITY_LOG", this.props.session.language)}
                    </div>
                    <div className="oplog_range_container titilliumweb-bold-black-18px">
                        {languageUtil("DATE_RANGE", this.props.session.language)}
                        <CalendarPicker
                            isOperatorLog={true}
                            style={{
                                outline: 'none', border: '1px solid #ECECEC',
                                height: '40px', width: '100px',
                                paddingLeft: '10px'
                            }}
                            value={this.state.rangeStart}
                            handleChange={value => this.setState({rangeStart: value})}
                            autoComplete='off'
                        />
                        -
                        <CalendarPicker
                            isOperatorLog={true}
                            style={{
                                outline: 'none', border: '1px solid #ECECEC',
                                height: '40px', width: '100px',
                                paddingLeft: '10px'
                            }}
                            value={this.state.rangeEnd}
                            handleChange={value => this.setState({rangeStart: value})}
                            autoComplete='off'
                        />
                    </div>
                </div>
                <div className="oplog_logtable_container">
                    <OperatorLogTable 
                        logList = {this.state.logList}
                    />
                </div>

                <Drawer
                    anchor='right'
                    open={this.state.drawerToggle}
                    onClose={this.closeDrawer}
                // onOpen={toggleDrawer(anchor, true)}
                >
                    {this.state.whichSetting === 'reset_password' && (
                        <ManagementSettingChangePW
                            closeDrawer={this.closeDrawer}
                            submitOperatorChangePW={this.submitOperatorChangePW}
                            convertChangePW={true}
                        />
                    )}

                </Drawer>
            </div>
        )
    }
}

export default connect(mapStateToProps)(withRouter(OperatorLog))
