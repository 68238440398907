import './ListUploadingFiles.css'
import { React } from 'react'
import { connect } from 'react-redux';
import { Divider, Typography, Button } from '@mui/material';
import languageUtil from "../../lib/languageUtil";

function mapStateToProps(state) {
  return { session: state.session, error: state.error }
}

function ListUploadingFiles(props) {
  let uploadingFiles = props.uploadedFiles;

  //display Icon based on the extension of the file
  const displayIcon = (file,idx) => {
    const fileName = file.name;
    let fileType = "";

    if (!props.isConversion) {
      for (let tempFilesType of props.session.uploadedFilesType) {
        if(Object.keys(tempFilesType)[0] === fileName) {
          fileType = Object.values(tempFilesType)[0];
        }
      }
    } else {
      fileType = "ZIP"
    }
    
    
    let whichIcon = '';

    if (fileType === 'DCH') {
      whichIcon = 'DCH.png';
    } else if (fileType === 'PORTFOLIO') {
      whichIcon = 'Portfolio.png';
    } else {
      whichIcon = 'Attachment.png';
    }
    
    return (
      <>
        <div className='icon_container'>
          <img
            className='file_icon'
            src={require('../../static/img/' + whichIcon)}
            alt="icon"
          />
          <div className='uploaded_filename'>
            <Typography variant='caption'>
              {fileName}
            </Typography>
          </div>
        </div>

        <Divider style={{ width: '100%'}} />
        <Button
          variant='text'
          style={{ width: '100%', minHeight: '30px', textTransform: 'none' }}
          onClick={() => props.handleDeleteItem(idx)}
        >
          <Typography
            variant='body2'
            sx={{ color: 'red', display: 'inline', textDecoration: 'underline' }}
            display='inline'
          >
            {languageUtil("DELETE", props.session.language)}
          </Typography>
        </Button>
      </>
    )
  }
  
  return (
    <>
      {uploadingFiles && uploadingFiles.map((file, idx) => {
        return (
          <div
            className='file_card'
            key={idx}
          >
            {displayIcon(file,idx)}
          </div>
        )
      })}
    </>
  )
}

export default connect(mapStateToProps)(ListUploadingFiles)