import './shareCredentials.css';
import '../../overlayStyle.css';
import React, { Component } from 'react';
import { Drawer } from '@mui/material';
import { connect } from 'react-redux';
import { withRouter } from '../../withRouter';
import WebsiteHeaderWithHome from "../../components/HeaderWithHome";
import languageUtil from "../../lib/languageUtil";
import errormsgUtil from "../../lib/errormsgUtil";
import ListUploadedFiles from '../../components/ListUploadedFiles';
import { getCookie } from '../../util/cookie';
import ShareCredentialsDrawer from '../../components/ShareCredentialsDrawer';
import axios from 'axios';

function mapStateToProps(state) {
  return { session: state.session, error: state.error }
}

export class ShareCredentials extends Component {
  constructor(props) {
    super(props)
    this.state = {
      drawerToggle: false,
      selectedFiles: [],
      senderName: '',
      receiverEmail: '',
      messageContent: '',
      expdayBtn: false,
      expdayOption: '',
      viewnumberBtn: false,
      viewnumberOption: '',
    }
    this.overlay = React.createRef();
  }

  componentDidUpdate = async (prevSession, prevState) => {
    if (this.state.expdayOption && (prevState.expdayOption !== this.state.expdayOption)) {
      if (prevState.expdayOption.length < 10 &&
        (this.state.expdayOption.length === 10 &&
          this.state.expdayOption[2] === '/' &&
          this.state.expdayOption[5] === '/')) {
        if (!this.isValidDate()) {
          window.alert(languageUtil("INVALID_DATE_STATEMENT", this.props.session.language))
          this.setState({ expdayOption: '' })
        }
        if (this.isYesterday(this.state.expdayOption)) {
          window.alert(this.state.expdayOption + " : " + languageUtil("INVALID_DATE_STATEMENT", this.props.session.language))
          this.setState({ expdayOption: '' })
        }
      }
    }
  }

  //check if the expiring date is set as older than yesterday 
  isYesterday = (expDate) => {
    const yesterday = new Date(Date.now() - 864e5).toLocaleString().split(',')[0];

    let expDateDay = expDate.split('/')[0];
    let expDateMonth = expDate.split('/')[1];
    let expDateYear = expDate.split('/')[2];

    const expiringdate = new Date(expDateYear+'-'+expDateMonth+'-'+expDateDay).toLocaleString().split(',')[0];

    if(new Date(expiringdate) <= new Date(yesterday)) {
      return true
    } else return false
  }

  //function to validate date form 
  isValidDate = () => {
    var dateSplitted = this.state.expdayOption.split('/').reverse()
    var dateTest = new Date(dateSplitted.join('/'));

    return !isNaN(dateTest) &&
      dateTest.getFullYear() === parseInt(dateSplitted[0], 10) &&
      dateTest.getMonth() === (parseInt(dateSplitted[1], 10) - 1) &&
      dateTest.getDate() === parseInt(dateSplitted[2], 10)
  }

  //loading image 
  showOverlay = () => {
    this.overlay.current.style.width = "100%";
  }
  hideOverlay = () => {
    if (this.overlay.current) this.overlay.current.style.width = "0%";
  }

  //send api request to share credentials 
  sendShareAPI = async () => {
    let regEx = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (regEx.test(this.state.receiverEmail) && this.state.receiverEmail.length <= 320) {
      let submitData = {
        "files": this.state.selectedFiles,
        "sender_name": this.state.senderName.trim(),
        "receiver_email": this.state.receiverEmail.trim(),
        "message": this.state.messageContent
      }

      if (this.state.expdayBtn && this.state.expdayOption) {
        let newExpDate = this.state.expdayOption.split('/')[2] + '-' +
          this.state.expdayOption.split('/')[1] + '-' +
          this.state.expdayOption.split('/')[0];

        Object.assign(submitData, { "expiry_date": newExpDate })
      }

      if (this.state.viewnumberBtn && this.state.viewnumberOption) {
        Object.assign(submitData, { "max_view": this.state.viewnumberOption })
      }

      this.closeDrawer();
      this.showOverlay();
      await axios({
        method: 'post',
        url: `/api/portal/sharecredential`,
        data: submitData,
        headers: {
          "Authorization": getCookie('accessToken')
        }
      })
        .then((response) => {
          if (response.data) {
            if (response.data.error !== '000') {
              if (response.data.error === '203') {
                window.alert(errormsgUtil(response.data.error, this.props.session.language).replace('<filename>', response.data.message))
              } else {
                window.alert(errormsgUtil(response.data.error, this.props.session.language))
              }
            } else {
              window.alert(languageUtil("SUCCEED_SHARE_CREDENTIALS", this.props.session.language))
            }
          }
        })
        .catch((err) => {
          console.debug(err);
          if (err.response) {
            console.debug(err.response);
            console.debug(err.response.status);
            console.debug(err.message);
            console.debug(err.response.data);
            if (err.response.status === 401) {
              window.alert("Your login session has timeout.  Please re-login.");
            } else {
              window.alert("Error:" + err.message + ". Please report this error status to development team.")
            }
          } else {
            window.alert("Error:" + err.message + ". Please report this error status to development team.")
            console.debug(err.message);
          }
        }).finally(this.hideOverlay);
    } else {
      window.alert(languageUtil("INVALID_EMAIL", this.props.session.language))
    }

  }

  //close drawer for sharing credentials 
  closeDrawer = () => {
    this.setState({ drawerToggle: false })
  }
  addSelectedFileID = (fileID) => {
    let tempSelectedFiles = JSON.parse(JSON.stringify(this.state.selectedFiles));
    tempSelectedFiles.push(fileID);
    this.setState({ selectedFiles: tempSelectedFiles });
  }

  delSelectedFIleID = (fileID) => {
    let tempSelectedFiles = JSON.parse(JSON.stringify(this.state.selectedFiles));
    tempSelectedFiles.splice(tempSelectedFiles.indexOf(fileID), 1);
    this.setState({ selectedFiles: tempSelectedFiles });
  }

  handleOptionSwitch = (switchName) => {
    if (switchName === 'expday') {
      this.setState({ expdayBtn: !this.state.expdayBtn })
    }
    if (switchName === 'viewnumber') {
      this.setState({ viewnumberBtn: !this.state.viewnumberBtn })
    }
  }

  //sender's name validation
  senderNameValidation = (newValue) => {
    newValue = newValue.trimStart();
    this.setState({ senderName: newValue })
  }
  //receiver's email validation 
  emailValidation = async (newValue) => {
    newValue = newValue.replace(/\s/g, '');
    //let regEx = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;   //email validation regEx
    this.setState({ receiverEmail: newValue })
  }
  //email content validation 
  messageValidation = (newValue) => {
    this.setState({ messageContent: newValue })
  }
  //expired day option validation (weak validation)
  expdayValidation = (newValue) => {
    if (newValue.length < this.state.expdayOption.length) {
      this.setState({ expdayOption: newValue })

    } else {
      if (newValue.includes(' ')) {
        this.setState({ expdayOption: '' })
      } else if (newValue.length < 3) {
        if (newValue <= 31 && !isNaN(newValue)) {
          if (newValue.length === 2) {
            if (newValue > 0 && newValue.length === 2) {
              newValue = newValue + '/';
            } else {
              newValue = '';
            }
          }
          this.setState({ expdayOption: newValue })
        }
      } else if (newValue.length < 6) {
        if (newValue.split('/')[1] <= 12 && !isNaN(newValue.split('/')[1]) && !isNaN(newValue[3])) {
          if (newValue.length === 5) {
            if (newValue.split('/')[1] > 0 && newValue.split('/')[1].length === 2) {
              newValue = newValue + '/';
            } else {
              newValue = '';
            }
          }
          this.setState({ expdayOption: newValue })
        }

      } else if (newValue.length <= 10) {
        if (newValue.split('/')[2] <= 2099 && !isNaN(newValue.split('/')[2]) && !isNaN(newValue[6])) {
          if (newValue.length === 10) {
            if (newValue.split('/')[2] === 0 || isNaN(newValue.split('/')[2])) {
              newValue = '';
            }
          }
          this.setState({ expdayOption: newValue })
        }
      }
    }
  }

  //view number option validation
  viewnumberValidation = (newNumber) => {
    let regEx = /^[0-9]*$/;
    if (regEx.test(newNumber) && newNumber <= 9999 && newNumber > 0) {
      this.setState({ viewnumberOption: newNumber })
    } else if (newNumber === '') {
      this.setState({ viewnumberOption: '' })
    }
  }

  render() {
    return (
      <div className="container-center-horizontal">
        <div ref={this.overlay} className="overlay_cover">
          <div className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
        </div>
        <div className="shareSelection">
          <WebsiteHeaderWithHome title="SHARE" />
          <div style={{
            width: '100%', minHeight: 'calc(100vh - 60px)', height: 'auto',
            overflowY: 'auto'
          }}>
            <div className="share_subcontainer">
              <div className="share_files_title valign-text-middle titilliumweb-bold-black-24px">
                {languageUtil("SELECT_FILES", this.props.session.language)}
              </div>
              <div
                className="share_clear_select"
                onClick={() => this.setState({ selectedFiles: [] })}
              >
                <div className="share_clear_select_btn titilliumweb-semi-bold-pomegranate-14px">
                  {languageUtil("CLEAR_SELECTION", this.props.session.language)}
                </div>
              </div>
            </div>
            <div className='uploaded_list_share'>
              <ListUploadedFiles
                isShareCred={true}
                addSelectedFileID={this.addSelectedFileID}
                delSelectedFileID={this.delSelectedFIleID}
                selectedFiles={this.state.selectedFiles}
                fileList={getCookie("uploadedFiles")}
              />
            </div>
            <div className="flex-row-btn2">
              <div className="button_next4"
                onClick={() => {
                  if (this.state.selectedFiles.length > 0) {
                    this.setState({ drawerToggle: true })
                  }
                }}
                style={this.state.selectedFiles.length > 0
                  ? { backgroundColor: 'var(--bluemain)', cursor: 'pointer' }
                  : { backgroundColor: 'var(--grey)', cursor: 'default' }
                }
              >
                <div className="next4 valign-text-middle titilliumweb-normal-white-18px">
                  {languageUtil("NEXT", this.props.session.language)}
                </div>
              </div>
            </div>
          </div>
          <Drawer
            anchor='right'
            open={this.state.drawerToggle}
            onClose={this.closeDrawer}
          >
            <ShareCredentialsDrawer
              closeDrawer={this.closeDrawer}
              portfolioName={this.state.portfolioName}
              changePortfolioName={this.changePortfolioName}
              sendGroupAPI={this.sendGroupAPI}

              senderName={this.state.senderName}
              senderNameValidation={this.senderNameValidation}

              receiverEmail={this.state.receiverEmail}
              emailValidation={this.emailValidation}

              messageContent={this.state.messageContent}
              messageValidation={this.messageValidation}

              expdayBtn={this.state.expdayBtn}
              expdayOption={this.state.expdayOption}
              expdayValidation={this.expdayValidation}

              viewnumberBtn={this.state.viewnumberBtn}
              viewnumberOption={this.state.viewnumberOption}
              viewnumberValidation={this.viewnumberValidation}

              handleOptionSwitch={this.handleOptionSwitch}

              sendShareAPI={this.sendShareAPI}
            />
          </Drawer>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps)(withRouter(ShareCredentials))