import React from "react";
import "./StepDetail.css";

function StepDetail(props) {
  const { receive, receviedDigitalCre, step1, stepSVG } = props;
  return (
    <div className={`step_-detail-8`}>
      <div style={{display:'flex', flexDirection:'row', width: '100%'}}>
        <img className="iconrecevie" src={stepSVG} alt="icon" />
        <div className="step titilliumweb-black-martinique-48px">{step1}</div>
      </div>
      <div className="receive valign-text-middle titilliumweb-bold-black-24px">{receive}</div>
      <div className="recevied-digital-cre valign-text-middle titilliumweb-normal-mountain-mist-16px">
        {receviedDigitalCre}
      </div>
    </div>
  );
}

export default StepDetail;
