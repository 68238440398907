import "./managementUploaded.css";
import '../../overlayStyle.css';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Buffer } from "buffer";
import { withRouter } from '../../withRouter';
import { Drawer } from '@mui/material';
import { setCookie, getCookie } from "../../util/cookie";
import Dropzone from 'react-dropzone';
import Header from "../../components/Header";
import ListUploadedFiles from "../../components/ListUploadedFiles";
import languageUtil from "../../lib/languageUtil";
import errormsgUtil from "../../lib/errormsgUtil";
import axios from 'axios';
import ManagementSettingChangePW from "../../components/ManagementSettingChangePW";
import ManagementSettingChangeEmail from "../../components/ManagementSettingChangeEmail";
import ManageSettingBackupEmail from "../../components/ManagementSettingBackUpEmail";
import DragDropImg from "../../static/img/drag-drop.svg";

function mapStateToProps(state) {
    return { session: state.session, error: state.error }
}

export class ManagementUploaded extends Component {
    constructor(props) {
        super(props);
        this.onDrop = (uploadedFiles) => {
            this.onDropFiles(uploadedFiles)
        };
        this.state = {
            drawerToggle: false,
            whichSetting: '',
            uploadedFiles: [],
            selectedFunction: ''
        };
        this.overlay = React.createRef();
        this.fileUpload = React.createRef(null);
    }

    //awaitable setstate function
    promisedSetState = (newState) => new Promise(resolve => this.setState(newState, resolve));

    //loading image 
    showOverlay = () => {
        this.overlay.current.style.width = "100%";
    }
    hideOverlay = () => {
        if (this.overlay.current) this.overlay.current.style.width = "0%";
    }

    refreshList = () => {
        this.setState({ uploadedFiles: getCookie('uploadedFiles') })
    }

    //open drawer for the account settings
    openDrawer = (settingItem) => {
        this.setState({
            drawerToggle: true,
            whichSetting: settingItem
        })
    }
    //close drawer to close the account settings 
    closeDrawer = () => {
        this.setState({
            drawerToggle: false,
            whichSetting: ''
        })
    }

    //check and list previously uploaded files.
    componentDidMount = async () => {
        this.listingUploadedFiles();
    }

    //handling selecting function for the next step
    handleSelectFunction = (whichFunction) => {
        if (this.state.selectedFunction) {
            if (this.state.selectedFunction === whichFunction) {
                this.setState({ selectedFunction: '' })
            } else {
                this.setState({ selectedFunction: whichFunction })
            }
        } else {
            this.setState({ selectedFunction: whichFunction })
        }
    }

    //handling redirecting the route
    handleClickNext = (route) => {
        switch (route) {
            case "view":
                this.props.navigate("/ViewCredentials");
                break;
            case "make_portfolio":
                this.props.navigate("/MakePortfolio");
                break;
            case "share":
                this.props.navigate("/ShareCredentials");
                break;
            default:
                break;
        }
    }

    //check if there are duplicated names, rename it if duplicated
    checkDuplicatedNames = (responseFiles) => {
        let tmpUploadingFiles = [];
        let uploadedFileNames = [];

        for (let i = 0; i < responseFiles.length; i++) {
            //if there are duplicated in the previous item 
            if (uploadedFileNames.includes(Object.values(responseFiles[i])[0])) {
                let newFileName = Object.values(responseFiles[i])[0];
                let duplicatedNum = 1;
                let tmpFile = JSON.parse(JSON.stringify(responseFiles[i]));

                while (uploadedFileNames.includes(newFileName)) {
                    newFileName = Object.values(responseFiles[i])[0].split('.')[0]
                        + `_${duplicatedNum}.`
                        + Object.values(responseFiles[i])[0].split('.')[1];
                    duplicatedNum += 1;

                    tmpFile[Object.keys(tmpFile)[0]] = newFileName;
                }

                tmpUploadingFiles.push(tmpFile);
                uploadedFileNames.push(newFileName);
            } else {
                tmpUploadingFiles.push(responseFiles[i]);
                uploadedFileNames.push(Object.values(responseFiles[i])[0]);
            }
        }
        return tmpUploadingFiles
    }

    matchServerUploadedFiles = (serverUploadedFiles) => {
        let filenames_uploaded = [];
        // let new_filesType = [];

        for (let i = 0; i < getCookie("uploadedFiles").length; i++) {
            filenames_uploaded.push(Object.values(getCookie("uploadedFiles")[i])[0]);
        }

        //delete the file failed to be uploaded on the serverside
        for (let k = 0; k < serverUploadedFiles.length; k++) {
            let failedfileid = Object.keys(serverUploadedFiles[k])[0];
            let failedfilename = Object.values(serverUploadedFiles[k])[0];
    
            if (!filenames_uploaded.includes(failedfilename)) {
                // console.log(`${Object.values(serverUploadedFiles[k])[0]} is only uploaded to server`)
                this.handleDeleteFile(failedfileid, failedfilename)
            }
        } 
    }

    listingUploadedFiles = async () => {
        const submitData = {
            'view': 1
        }

        this.showOverlay();
        await axios({
            method: 'post',
            url: `/api/portal/listcredential`,
            data: submitData,
            headers: {
                "Authorization": getCookie('accessToken')
            }
        })
            .then(async (response) => {
                if (response.data) {
                    if (response.data.error !== '000') {
                        window.alert(errormsgUtil(response.data.error, this.props.session.language))
                    } else {
                        //handling if there is duplicated name of file 
                        var tmpListingFiles = this.checkDuplicatedNames(response.data.files)
                        
                        if (tmpListingFiles && tmpListingFiles.length > 0) {
                            await setCookie("uploadedFiles", tmpListingFiles)
                            await this.promisedSetState({ uploadedFiles: getCookie("uploadedFiles") });
                            
                            if (this.state.uploadedFiles.length < tmpListingFiles.length) {
                                window.alert(languageUtil("COOKIE_CANNOT_UPDATE", this.props.session.language))
                                await this.matchServerUploadedFiles(tmpListingFiles)
                            }
                        }
                    }
                }
            })
            .catch((err) => {
                console.debug(err);
                if (err.response) {
                    console.debug(err.response);
                    console.debug(err.response.status);
                    console.debug(err.message);
                    console.debug(err.response.data);
                    if (err.response.status === 401) {
                        window.alert("Your login session has timeout.  Please re-login.");
                    } else {
                        window.alert("Error:" + err.message + ". Please report this error status to development team.")
                    }
                } else {
                    window.alert("Error:" + err.message + ". Please report this error status to development team.")
                    console.debug(err.message);
                }
            }).finally(
                this.hideOverlay
            );
    }

    //send new password informatione to the API server. 
    submitIndivChangePW = async (oldPassword, confirmPassword) => {
        const submitData = {
            'oldpassword': oldPassword,
            'newpassword': confirmPassword
        }

        this.showOverlay();
        await axios({
            method: 'post',
            url: `/api/auth/changepassword`,
            data: submitData,
            headers: {
                "Authorization": getCookie('accessToken')
            }
        })
            .then((response) => {
                if (response.data) {
                    if (response.data.error !== '000') {
                        window.alert(errormsgUtil(response.data.error, this.props.session.language))
                    } else {
                        window.alert(languageUtil("SUCCEED_CHANGEPW", this.props.session.language))
                        this.closeDrawer();
                        this.props.navigate("/ManagementLogin");
                    }
                }
            })
            .catch((err) => {
                console.debug(err);
                if (err.response) {
                    console.debug(err.response);
                    console.debug(err.response.status);
                    console.debug(err.message);
                    console.debug(err.response.data);
                    if (err.response.status === 401) {
                        window.alert("Your login session has timeout.  Please re-login.");
                    } else {
                        window.alert("Error:" + err.message + ". Please report this error status to development team.")
                    }
                } else {
                    window.alert("Error:" + err.message + ". Please report this error status to development team.")
                    console.debug(err.message);
                }
            }).finally(this.hideOverlay);
    }

    //send new primary email to the API server. 
    submitIndivChangePrimaryEmail = async (oldEmail, newEmail) => {
        const submitData = {
            'oldemail': oldEmail,
            'newemail': newEmail
        }

        this.showOverlay();
        await axios({
            method: 'post',
            url: `/api/auth/changeprimaryemail`,
            data: submitData,
            headers: {
                "Authorization": getCookie('accessToken')
            }
        })
            .then((response) => {
                if (response.data) {
                    if (response.data.error !== '000') {
                        window.alert(errormsgUtil(response.data.error, this.props.session.language))
                    } else {
                        window.alert(languageUtil("SUCCEED_CHANGE_EMAIL", this.props.session.language))
                        this.closeDrawer();
                        this.props.navigate("/ManagementLogin");
                    }
                }
            })
            .catch((err) => {
                console.debug(err);
                if (err.response) {
                    console.debug(err.response);
                    console.debug(err.response.status);
                    console.debug(err.message);
                    console.debug(err.response.data);
                    if (err.response.status === 401) {
                        window.alert("Your login session has timeout.  Please re-login.");
                    } else {
                        window.alert("Error:" + err.message + ". Please report this error status to development team.")
                    }
                } else {
                    window.alert("Error:" + err.message + ". Please report this error status to development team.")
                    console.debug(err.message);
                }
            }).finally(this.hideOverlay);
    }

    //send new backup email to the API server. 
    submitIndivBackupEmail = async (backupEmail) => {
        const submitData = {
            'backup_email': backupEmail
        }

        this.showOverlay();
        await axios({
            method: 'post',
            url: `/api/auth/setbackupemail`,
            data: submitData,
            headers: {
                "Authorization": getCookie('accessToken')
            }
        })
            .then((response) => {
                if (response.data) {
                    if (response.data.error !== '000') {
                        window.alert(errormsgUtil(response.data.error, this.props.session.language))
                    } else {
                        window.alert(languageUtil("SUCCEED_BACKUP_EMAIL", this.props.session.language))
                        this.closeDrawer();
                    }
                }
            })
            .catch((err) => {
                console.debug(err);
                if (err.response) {
                    console.debug(err.response);
                    console.debug(err.response.status);
                    console.debug(err.message);
                    console.debug(err.response.data);
                    if (err.response.status === 401) {
                        window.alert("Your login session has timeout.  Please re-login.");
                    } else {
                        window.alert("Error:" + err.message + ". Please report this error status to development team.")
                    }
                } else {
                    window.alert("Error:" + err.message + ". Please report this error status to development team.")
                    console.debug(err.message);
                }
            }).finally(this.hideOverlay);
    }

    handleUploadFile = () => {
        this.fileUpload.current.click();
    }

    sendRequestAddCred = async (fileToAdd) => {
        var submitFormData = new FormData();
        submitFormData.append('file', fileToAdd)

        this.showOverlay();
        await axios({
            method: 'post',
            url: `/api/portal/addcredential`,
            data: submitFormData,
            headers: {
                "Authorization": getCookie('accessToken')
            }
        }).then((response) => {
            if (response.data) {
                if (response.data.error !== '000') {
                    window.alert(errormsgUtil(response.data.error, this.props.session.language))
                } else {
                    this.listingUploadedFiles();
                    // setTimeout(refreshPage, 500)
                }

            }
        }).catch((err) => {
            console.debug(err);
            if (err.response) {
                console.debug(err.response);
                console.debug(err.response.status);
                console.debug(err.message);
                console.debug(err.response.data);
                if (err.response.status === 401) {
                    window.alert("Your login session has timeout.  Please re-login.");
                } else {
                    window.alert("Error:" + err.message + ". Please report this error status to development team.")
                }
            } else {
                window.alert("Error:" + err.message + ". Please report this error status to development team.")
                console.debug(err.message);
            }
        }).finally(this.hideOverlay);
    }

    checkDuplicatedFileNameType = (newFileName, newFileType, fileNameTypeList) => {
        let uploadedFileNames = [];
        let newFileNameType = {};

        if (fileNameTypeList && fileNameTypeList.length > 0) {
            for (let i = 0; i < fileNameTypeList.length; i++) {
                uploadedFileNames.push(Object.keys(fileNameTypeList[i])[0]);
            }

            let duplicatedNum = 1;
            let tmpNewFileName = newFileName;
            if (uploadedFileNames.includes(tmpNewFileName)) {
                while (uploadedFileNames.includes(tmpNewFileName)) {
                    tmpNewFileName = newFileName;
                    tmpNewFileName = tmpNewFileName.split('.')[0]
                        + `_${duplicatedNum}.` + tmpNewFileName.split('.')[1];

                    duplicatedNum += 1;
                }
                newFileNameType[tmpNewFileName] = newFileType;
            } else {
                newFileNameType[newFileName] = newFileType
            }

        } else {
            newFileNameType[newFileName] = newFileType;
        }

        return (newFileNameType)
    }

    //when file added by onDrop 
    onDropFiles = async (file) => {

        const checkingValidFile = new Promise(async (resolve) => {
            let decodedUploadFile = await new Promise((resolve) => {
                let fileReader = new FileReader();
                fileReader.onload = (e) => resolve(fileReader.result);
                fileReader.readAsText(file[0], "UTF-8");
            });

            try {
                const str = JSON.parse(Buffer.from(decodedUploadFile, "base64").toString("utf-8"));
                resolve(str.file_type.toUpperCase())
            } catch (error) {
                window.alert(languageUtil("INVALID_FILE", this.props.session.language))
            }

        })


        checkingValidFile
            .then(async (uploadedFileType) => {
                const fileTypesInUse = ["DCH", "PORTFOLIO", "PDF", "JPG", "JPEG", "PNG"];

                if (uploadedFileType && fileTypesInUse.includes(uploadedFileType.toUpperCase())) {
                    //save file_type attribute to cookie with the file name 
                    let tmpUploadedFilesType = [];
                    if (getCookie("uploadedFilesType")) {
                        tmpUploadedFilesType = JSON.parse(JSON.stringify(getCookie("uploadedFilesType")));
                    }
                    let tmpObjKey = file[0].name;
                    //fileNameType[tmpObjKey] = uploadedFileType;
                    let fileNameType = this.checkDuplicatedFileNameType(tmpObjKey, uploadedFileType, tmpUploadedFilesType);
                    tmpUploadedFilesType.push(fileNameType)
                    if (tmpUploadedFilesType && tmpUploadedFilesType.length > 0) {
                        await setCookie("uploadedFilesType", tmpUploadedFilesType)
                    }

                    await this.sendRequestAddCred(file[0])
                } else {
                    window.alert(languageUtil("INVALID_FILE", this.props.session.language))
                }
            })
    }

    //when the file is uploaded check the 'file_type' attribute after decoding.
    handleUploadChange = async (e) => {
        //promise to get the file_type of the uploaded file
        const checkingValidFile = new Promise(async (resolve) => {
            let decodedUploadFile = await new Promise((resolve) => {
                let fileReader = new FileReader();
                fileReader.onload = (e) => resolve(fileReader.result);
                fileReader.readAsText(e.target.files[0], "UTF-8");
            });

            try {
                const str = JSON.parse(Buffer.from(decodedUploadFile, "base64").toString("utf-8"));
                resolve(str.file_type.toUpperCase())
            } catch (error) {
                window.alert(languageUtil("INVALID_FILE", this.props.session.language))
            }

        })

        checkingValidFile
            .then(async (uploadedFileType) => {
                const fileTypesInUse = ["DCH", "PORTFOLIO", "PDF", "JPG", "JPEG", "PNG"];

                if (uploadedFileType && fileTypesInUse.includes(uploadedFileType.toUpperCase())) {
                    //save file_type attribute to cookie with the file name 
                    let tmpUploadedFilesType = [];
                    if (getCookie("uploadedFilesType")) {
                        tmpUploadedFilesType = JSON.parse(JSON.stringify(getCookie("uploadedFilesType")));
                    }
                    let tmpObjKey = e.target.files[0].name;
                    //fileNameType[tmpObjKey] = uploadedFileType;
                    let fileNameType = this.checkDuplicatedFileNameType(tmpObjKey, uploadedFileType, tmpUploadedFilesType);
                    tmpUploadedFilesType.push(fileNameType)
                    if (tmpUploadedFilesType && tmpUploadedFilesType.length > 0) {
                        await setCookie("uploadedFilesType", tmpUploadedFilesType)
                    }

                    await this.sendRequestAddCred(e.target.files[0])
                } else {
                    window.alert(languageUtil("INVALID_FILE", this.props.session.language))
                }
            })
    }

    handleDeleteFile = async (fileID, filename) => {
        const submitData = {
          'files': [fileID]
        }
    
        await axios({
          method: 'post',
          url: `/api/portal/deletecredential`,
          data: submitData,
          headers: {
            "Authorization": getCookie('accessToken')
          }
        })
          .then((response) => {
            if (response.data) {
              if (response.data.error !== '000') {
                window.alert(errormsgUtil(response.data.error, this.props.session.language))
              } else {
                let newUploadedFiles = getCookie("uploadedFiles");
                let newUploadedFilesType = getCookie("uploadedFilesType")
                for (let i = 0; i < newUploadedFiles.length; i++) {
                  if (Object.keys(newUploadedFiles[i])[0] === fileID) {
                    newUploadedFiles.splice(i, 1)
                  }
                };
                for (let j = 0; j < newUploadedFilesType.length; j++) {
                  if (Object.keys(newUploadedFilesType[j])[0] === filename) {
                    newUploadedFilesType.splice(j, 1)
                  }
                };
                setCookie("uploadedFiles", [...newUploadedFiles]);      
                setCookie("uploadedFilesType", [...newUploadedFilesType]);          
                // refreshPage();
              }
            }
          })
          .catch((err) => {
            console.debug(err);
            if (err.response) {
              console.debug(err.response);
              console.debug(err.response.status);
              console.debug(err.message);
              console.debug(err.response.data);
              if (err.response.status === 401) {
                window.alert("Your login session has timeout.  Please re-login.");
              } else {
                window.alert("Error:" + err.message + ". Please report this error status to development team.")
              }
            } else {
              window.alert("Error:" + err.message + ". Please report this error status to development team.")
              console.debug(err.message);
            }
          })
      }

    handleDeleteAllFiles = async () => {
        const fileID = [];

        for (let i = 0; i < getCookie('uploadedFiles').length; i++) {
            fileID.push(Object.keys(getCookie('uploadedFiles')[i])[0])
        }

        const submitData = {
            'files': fileID
        }

        await this.showOverlay();
        await axios({
            method: 'post',
            url: `/api/portal/deletecredential`,
            data: submitData,
            headers: {
                "Authorization": getCookie('accessToken')
            }
        })
            .then((response) => {
                if (response.data) {
                    if (response.data.error !== '000') {
                        window.alert(errormsgUtil(response.data.error, this.props.session.language))
                    } else {
                        setCookie("uploadedFiles", [])
                        setCookie("uploadedFilesType", [])
                        this.setState({ uploadedFiles: [] })
                        this.props.navigate("/Management")
                    }
                }
            })
            .catch((err) => {
                console.debug(err);
                if (err.response) {
                    console.debug(err.response);
                    console.debug(err.response.status);
                    console.debug(err.message);
                    console.debug(err.response.data);
                    if (err.response.status === 401) {
                        window.alert("Your login session has timeout.  Please re-login.");
                    } else {
                        window.alert("Error:" + err.message + ". Please report this error status to development team.")
                    }
                } else {
                    window.alert("Error:" + err.message + ". Please report this error status to development team.")
                    console.debug(err.message);
                }
            }).finally(this.hideOverlay);
    }

    // check how many dch file is uploaded
    enablePortfolio = () =>{
        let array = getCookie('uploadedFilesType')       
        var index = 0
        if (this.state.uploadedFiles && this.state.uploadedFiles.length >0){
            for (let fileItemName of this.state.uploadedFiles){
                for (let fileItem of array){
                    if (Object.keys(fileItem)[0] ===Object.values(fileItemName)[0] &&Object.values(fileItem)[0] === "DCH"){
                        index++               
                    }
                }
            }
        }
        return index;
    }

    render() {

        return (
            <div className="uploaded-indiv">
                <div ref={this.overlay} className="overlay_cover">
                    <div className="spinner">
                        <div className="bounce1"></div>
                        <div className="bounce2"></div>
                        <div className="bounce3"></div>
                    </div>
                </div>
                <Header
                    openDrawer={this.openDrawer}
                    isIndiv={true}
                />
                <div style={{
                    display: 'flex', flexDirection: 'row', width: '100%', height: '100vh', minHeight: '676px',
                }}
                >
                    <Dropzone
                        noClick={true}
                        onDrop={this.onDrop}
                        style={{ width: '100%', heigth: '100%' }}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <>
                                <div
                                    {...getRootProps({ className: 'dropzone' })}
                                    className="contentUploaded-indiv"
                                    style={{
                                        backgroundImage: `url(${DragDropImg})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: "200px",
                                        backgroundPosition: "center"
                                    }}
                                >
                                    <div className="flex-row-8-indiv">
                                        <div className="title-46-indiv valign-text-middle titilliumweb-bold-black-24px">
                                            {languageUtil("UPLOADED_FILES", this.props.session.language)}
                                            {this.state.uploadedFiles.length > 0 && ` (${this.state.uploadedFiles.length})`}
                                        </div>
                                        <div
                                            className="website-button-s2-b-indiv"
                                            onClick={this.handleUploadFile}
                                        >
                                            <input {...getInputProps({ className: 'dropzone' })} />
                                            <input
                                                type="file"
                                                style={{ display: "none" }} ref={this.fileUpload}
                                                onChange={this.handleUploadChange}
                                                onClick={(e) => e.target.value = null}
                                            // accept=".attachment, .portfolio, .dch, .transcript, .testimonial, .certificate"
                                            />
                                            <img
                                                className="icon-add-indiv"
                                                src={require("../../static/img/icon---add@2x.svg").default}
                                                alt='icon-add'
                                            />
                                            <div
                                                className="add-indiv valign-text-middle titilliumweb-semi-bold-white-14px"
                                            >
                                                {languageUtil("ADD", this.props.session.language)}
                                            </div>
                                        </div>
                                        <div
                                            className="website-button-s2-b-1-indiv"
                                            onClick={() => {
                                                if (getCookie('uploadedFiles') && getCookie('uploadedFiles').length > 0) {
                                                    if (window.confirm(languageUtil('DELETE_CONFIRM_ALERT', this.props.session.language))) this.handleDeleteAllFiles()
                                                }
                                            }}
                                        >
                                            <img
                                                className="icon-trash-indiv"
                                                src={require("../../static/img/icon---trash-full@2x.svg").default}
                                                alt='icon-trash'
                                            />
                                            <div className="delete-all-indiv titilliumweb-semi-bold-pomegranate-14px">
                                                {languageUtil("DELETE_ALL", this.props.session.language)}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="flex-row-9-indiv"
                                    >
                                        <ListUploadedFiles
                                            fileList={this.state.uploadedFiles}
                                            refreshList={this.refreshList}
                                        />
                                    </div>
                                </div>

                            </>
                        )}

                    </Dropzone>
                    <div className="select-function-section">
                        <div className="select-function-title titilliumweb-bold-black-24px">
                            {languageUtil("SELECT_FUNCTION", this.props.session.language)}
                        </div>
                        <div className="select-functions">
                            <div
                                className="select-function-item1"
                                onClick={() => {
                                    if (getCookie('uploadedFiles') && getCookie('uploadedFiles').length > 0) {
                                        this.handleClickNext('view')
                                    }
                                }
                                }
                                style={getCookie('uploadedFiles')
                                && getCookie('uploadedFiles').length > 0
                                ?{ backgroundColor: 'var(--white)', borderColor: 'var(--mercury)' }
                                :{ backgroundColor: 'var(--lightgrey)', cursor: 'default'}}
                            >
                                <div className="view valign-text-middle titilliumweb-bold-black-18px">
                                    <img className="item1-icon" src={require("../../static/img/view_cred.svg").default} alt="icon" />
                                    {languageUtil("VIEW", this.props.session.language)}
                                </div>
                                <img className="line-16" src={require("../../static/img/vector-28@2x.svg").default} alt="icon" />
                                <div className="item1-desc titilliumweb-normal-mountain-mist-18px">{languageUtil("VIEW_EXPLAIN", this.props.session.language)}</div>
                            </div>
                            <div
                                className="select-function-item2"
                                onClick={() => {
                                    if ((getCookie('uploadedFiles') && getCookie('uploadedFiles').length > 0)&&this.enablePortfolio() >1) {
                                        this.handleClickNext('make_portfolio')                       
                                    }
                                }
                                }

                                style={(getCookie('uploadedFiles')
                                && getCookie('uploadedFiles').length > 0) &&this.enablePortfolio() >1
                                ?{ backgroundColor: 'var(--white)', borderColor: 'var(--mercury)' }                                  
                                :{ backgroundColor: 'var(--blue)', cursor: 'default'}
                            }
                            >
                                <div className="make-portfolio valign-text-middle titilliumweb-bold-black-18px">
                                    <img className="item1-icon" src={require("../../static/img/merge_cred.svg").default} alt="icon" />
                                    {languageUtil("MAKE_PORTFOLIO", this.props.session.language)}
                                </div>
                                <img className="line-16" src={require("../../static/img/vector-28@2x.svg").default} alt="icon" />
                                <div className="item2-desc titilliumweb-normal-mountain-mist-18px">{languageUtil("MAKE_PORTFOLIO_EXPLAIN", this.props.session.language)}</div>
                            </div>
                            <div className="select-function-item3"
                                onClick={() => {
                                    if (getCookie('uploadedFiles') && getCookie('uploadedFiles').length > 0) {
                                        this.handleClickNext('share')
                                    }
                                }}
                                style={getCookie('uploadedFiles')
                                && getCookie('uploadedFiles').length > 0
                                ?{ backgroundColor: 'var(--white)', borderColor: 'var(--mercury)' }
                                :{ backgroundColor: 'var(--lightgrey)', cursor: 'default'}}
                                
                            >
                                <div className="share valign-text-middle titilliumweb-bold-black-18px">
                                    <img className="item1-icon" src={require("../../static/img/share_cred.svg").default} alt="icon" />
                                    {languageUtil("SHARE", this.props.session.language)}
                                </div>
                                <img className="line-16" src={require("../../static/img/vector-28@2x.svg").default} alt="icon" />
                                <div className="item3-desc titilliumweb-normal-mountain-mist-18px">{languageUtil("SHARE_EXPLAIN", this.props.session.language)}</div>
                            </div>
                        </div>
                    </div>

                </div>

                <Drawer
                    anchor='right'
                    open={this.state.drawerToggle}
                    onClose={this.closeDrawer}
                >
                    {this.state.whichSetting === 'reset_password' && (
                        <ManagementSettingChangePW
                            closeDrawer={this.closeDrawer}
                            submitIndivChangePW={this.submitIndivChangePW}
                            indivChangePW={true}
                        />
                    )}
                    {this.state.whichSetting === 'reset_personal_email' && (
                        <ManagementSettingChangeEmail
                            closeDrawer={this.closeDrawer}
                            submitIndivChangePrimaryEmail={this.submitIndivChangePrimaryEmail}
                        />
                    )}
                    {this.state.whichSetting === 'reset_backup_email' && (
                        <ManageSettingBackupEmail
                            closeDrawer={this.closeDrawer}
                            submitIndivBackupEmail={this.submitIndivBackupEmail}
                        />
                    )}

                </Drawer>
            </div>
        )
    }
}

export default connect(mapStateToProps)(withRouter(ManagementUploaded))