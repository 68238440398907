import { React } from 'react'
import Flatpickr from 'react-flatpickr';
import './CalendarPicker.css'
import 'flatpickr/dist/themes/material_blue.css';


const flatPickrOptions = (defaultDate) => {
    return {
        'altInput': true,
        // 'allowInput': true,
        'altFormat': "d/m/Y",
        'dateFormat':'d/m/Y', 
        'defaultDate':defaultDate, 
        'minDate': 'today',
        'disableMobile': 'true'
    };   
}

const flatPickrOptionsOplog = (defaultDate) => {
    return {
        'altInput': true,
        // 'allowInput': true,
        'altFormat': "d/m/Y",
        'dateFormat':'d/m/Y', 
        'defaultDate':defaultDate, 
        'maxDate': 'today',
        'disableMobile': 'true'
    };   
}

const SearchDate = function (props) {
    return (
        <div className="search-date" id={props.id}>
            <Flatpickr
                className={props.datePickerStyle}
                style={{color:'#ECECEC'}}
                onChange={e => props.handleChange(e)}
                placeholder={props.placeholder} 
                options={props.isOperatorLog ?flatPickrOptionsOplog(props.initialValue) :flatPickrOptions()}
            />
        </div>
    );
}

function CalendarPicker(props) {
    let CalendarContainerStyle = props.isOperatorLog ? "calendarTextFieldContain_oplog" : "calendarTextFieldContain"
    let DatePickerStyle = props.isOperatorLog ? "datePicker_oplog" :"datePicker"

    const handleDateChange = async (date) => {
        let tzoffset = (new Date()).getTimezoneOffset() * 60000;
        let newFormDate = "";
        //if the date is invalid, (empty) return empty string
        try {
            newFormDate = new Date(new Date(date) - tzoffset).toISOString().split('T')[0];
            newFormDate = newFormDate.split('-')[2] + "/" + newFormDate.split('-')[1] + "/" + newFormDate.split('-')[0]
        } catch (e) {
            console.log(e)
        }

        await props.handleChange(newFormDate)
    };

    return (
        <div className = {CalendarContainerStyle}>
            <SearchDate 
                id="search-calendar" 
                text="Requested Date From" 
                datePickerStyle={DatePickerStyle}
                handleChange={date => handleDateChange(date)} 
                placeholder={props.placeholder}
                isOperatorLog={props.isOperatorLog}
                initialValue={props.value}
            />
        </div>
    )
}

export default CalendarPicker