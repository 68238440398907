import './viewCredentials.css';
import '../../overlayStyle.css';
import { Buffer } from "buffer";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from '../../withRouter';
import { getCookie } from "../../util/cookie";
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import WebsiteHeaderS2 from "../../components/WebsiteHeaderS2";
import languageUtil from "../../lib/languageUtil";
import errormsgUtil from "../../lib/errormsgUtil";
import { Button, Divider, Typography } from '@mui/material';
import WaterMarkVQ from '../../static/img/VQWatermark.svg';
import axios from 'axios';
import { arrayItemToLast, placeCrpPosition } from '../../lib/arrayUtil';

function mapStateToProps(state) {
    return { session: state.session, error: state.error }
}

export class ViewCredentials extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFile: '',
            selectedFileDetail: {}
        };
        this.overlay = React.createRef();
    }

    //loading image 
    showOverlay = () => {
        this.overlay.current.style.width = "100%";
    }
    hideOverlay = () => {
        if (this.overlay.current) this.overlay.current.style.width = "0%";
    }

    //select the file uploaded file once mounted 
    componentDidMount = () => {
        if (getCookie('uploadedFiles') && getCookie('uploadedFiles').length > 0) {
            this.setState({ selectedFile: Object.values(getCookie('uploadedFiles')[0])[0] })
            this.viewFileDetail(Object.keys(getCookie('uploadedFiles')[0])[0])
        }
    }

    //post request to get the detail information of the selected file 
    viewFileDetail = async (fileID) => {
        const submitData = {
            'fileid': fileID
        }

        this.showOverlay();
        await axios({
            method: 'post',
            url: `/api/portal/viewcredential`,
            data: submitData,
            headers: {
                "Authorization": getCookie('accessToken')
            }
        })
            .then((response) => {
                if (response.data) {
                    if (response.data.error !== '000') {
                        window.alert(errormsgUtil(response.data.error, this.props.session.language))
                    } else {
                        this.setState({ selectedFileDetail: { ...response.data.file } })
                    }
                }
            })
            .catch((err) => {
                console.debug(err);
                if (err.response) {
                    console.debug(err.response);
                    console.debug(err.response.status);
                    console.debug(err.message);
                    console.debug(err.response.data);
                    if (err.response.status === 401) {
                        window.alert("Your login session has timeout.  Please re-login.");
                    } else {
                        window.alert("Error:" + err.message + ". Please report this error status to development team.")
                    }
                } else {
                    window.alert("Error:" + err.message + ". Please report this error status to development team.")
                    console.debug(err.message);
                }
            }).finally(this.hideOverlay);
    }

    //icon for the file list 
    displayFileIcon = (fileName) => {
        let fileWithTypes = getCookie("uploadedFilesType");
        let fileType = "";
        let pngFile = '';
        for (let i = 0; i < fileWithTypes.length; i++) {
            if (Object.keys(fileWithTypes[i])[0] === fileName) {
                fileType = Object.values(fileWithTypes[i])[0];
            }
        }

        if (fileType === 'DCH') {
            if (this.state.selectedFile === fileName) {
                pngFile = 'dch_on.png';
            } else {
                pngFile = 'dch_off.png';
            }
        } else if (fileType === 'PORTFOLIO') {
            if (this.state.selectedFile === fileName) {
                pngFile = 'portfolio_on.png';
            } else {
                pngFile = 'portfolio_off.png';
            }
        } else {
            if (this.state.selectedFile === fileName) {
                pngFile = 'attachment_on.png'
            } else {
                pngFile = 'attachment_off.png';
            }
        }

        return (
            <img style={{ marginRight: '10px' }} src={require('../../static/img/' + pngFile)} alt='fileIcon' />
        )
    }

    //display file list in the menu bar 
    displayFileList = () => {
        if (getCookie('uploadedFiles') && getCookie('uploadedFiles').length >= 1) {
            return (
                getCookie('uploadedFiles').map((fileObj, idx) => {
                    let fileName = Object.values(fileObj)[0];
                    return (
                        <ListItem
                            key={idx} component="div"
                            sx={{ height: '80px' }}
                        >
                            <ListItemButton
                                sx={
                                    this.state.selectedFile === fileName
                                        ? { border: '1px solid #DCDCDC', borderRadius: '10px', boxShadow: '2px 2px 5px #DCDCDC', height: '100%' }
                                        : { borderBottom: '1px solid #DCDCDC', height: '100%' }
                                }
                                onClick={() => this.handleClickListItem(fileObj)}
                            >
                                {this.displayFileIcon(fileName)}
                                <ListItemText className='listed_filename'>
                                    <Typography variant='body2'>
                                        {fileName}
                                    </Typography>
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>
                    )
                })
            )
        } else {
            return (<></>)
        }
    }

    displayArrayContent = (arrayContent) => {
        return (
            arrayContent.map((contentItem, contentIdx) => {
                return (
                    <div key={contentIdx} 
                        style={
                            contentItem === 0 
                                ? {display:'flex', flexDirection:'row'}
                                : {display:'flex', flexDirection:'row', paddingTop:'10px'}
                        }
                    >
                        <Typography style={{ fontWeight: 'bold', paddingRight:'5px' }}></Typography>
                        <div>
                            <Typography style={{ fontWeight: 'bold' }}>
                                {contentItem}
                            </Typography>
                        </div>
                    </div>
                )
            })
        )
    }

    //decode file to display the content
    displayCredObj = () => {
        let decodedFileData = [];
        if (this.state.selectedFileDetail.data.length > 0) {
            for (let i = 0; i < this.state.selectedFileDetail.data.length; i++) {
                let decodedRequestBodyString = Buffer.from(this.state.selectedFileDetail.data[i], "base64");
                decodedFileData.push(JSON.parse(decodedRequestBodyString));
            }

        }
        return (
            <div
                style={{
                    width: 'calc(100% - 100px)', height: 'auto',
                    minWidth: '100%', margin: '50px 0px'
                }}
            >
                {decodedFileData.map((fileObj, fileIdx) => {
                    let CredKeys = Object.keys(fileObj)
                    let isQR = fileObj.source === 'QR';
                    let isQA = fileObj.source === 'QA';

                    if (isQA && !CredKeys.includes('qf_level')) {
                        if (!CredKeys.includes('student_name')) {                            
                            CredKeys.unshift("qf_level")
                        } else {
                            CredKeys.splice(1, 0, "qf_level")
                        }
                    }
                    //re-ordering the attributes 
                    if (CredKeys.includes('assessment_outcomes')) CredKeys = arrayItemToLast(CredKeys, 'assessment_outcomes')
                    if (CredKeys.includes('educational_qualifications_considered')) CredKeys = arrayItemToLast(CredKeys, 'educational_qualifications_considered')
                    if (CredKeys.includes('note')) CredKeys = arrayItemToLast(CredKeys, 'note')
                    if (CredKeys.includes('crp') && isQR) CredKeys = placeCrpPosition(CredKeys);

                    return (
                        <div
                            key={fileIdx}
                            style={{
                                minWidth: '100%'
                            }}
                        >
                            {fileIdx > 0 && (
                                <Divider style={{ width: '100%', margin: '20px 0px' }} />
                            )}
                            <Typography variant="h5" style={{ fontWeight: 'bold' }} >
                                {fileObj.qualification_title && fileObj.qualification_title.toUpperCase()}
                                {fileObj.report_title && languageUtil("QA_REPORT_TITLE", this.props.session.language)}
                            </Typography>
                            <div 
                                className='listing_credentials'
                                style={{
                                    backgroundImage:`url(${WaterMarkVQ})`,
                                    backgroundRepeat:'no-repeat',
                                    backgroundPosition:'center',
                                    backgroundSize:'25%'
                                }}>

                                
                                {CredKeys.map((cred_key, cred_idx) => {
                                    let notDisplayKeys = ['qualification_title', 'report_title', 'source'];
                                    let fullDisplayKeys = [ 'assessment_outcomes', 'educational_qualifications_considered', 'note'];
                                    if (!notDisplayKeys.includes(cred_key) && !fullDisplayKeys.includes(cred_key)) {
                                        return (
                                            <div key={cred_idx} style={{ minWidth: '50%', marginTop: '20px' }}>
                                                <Typography style={{ color: 'grey' }}>
                                                    {languageUtil(cred_key.toUpperCase(), this.props.session.language)}
                                                </Typography>
                                                {Array.isArray(fileObj[cred_key]) && fileObj[cred_key].length > 1
                                                    ? this.displayArrayContent(fileObj[cred_key])
                                                    : <Typography style={{ fontWeight: 'bold' ,textAlign : 'justify'}}>
                                                        {cred_key === "region"
                                                            ? this.props.session.language === "english"
                                                                ? fileObj[cred_key].includes("Hong Kong") && ("Hong Kong")
                                                                : fileObj[cred_key].includes("香港") && ("香港")
                                                            : cred_key === "qf_level" && fileObj[cred_key] === undefined
                                                                ? "/"
                                                                : fileObj[cred_key]
                                                        }
                                                    </Typography>
                                                }
                                            </div>
                                        )
                                    } else if (fullDisplayKeys.includes(cred_key)) {
                                        return (
                                            <div key={cred_idx} style={{ minWidth: '100%', marginTop: '20px' }}>
                                                <Typography style={{ color: 'grey' }}>
                                                    {languageUtil(cred_key.toUpperCase(), this.props.session.language)}
                                                </Typography>
                                                {Array.isArray(fileObj[cred_key]) && fileObj[cred_key].length > 1
                                                    ? this.displayArrayContent(fileObj[cred_key])
                                                    : <Typography style={{ fontWeight: 'bold' ,textAlign : 'justify'}}>
                                                        {cred_key === "region"
                                                            ? this.props.session.language === "english"
                                                                ? fileObj[cred_key].includes("Hong Kong") && ("Hong Kong")
                                                                : fileObj[cred_key].includes("香港") && ("香港")
                                                            : cred_key === "qf_level" && fileObj[cred_key] === undefined
                                                                ? "/"
                                                                : fileObj[cred_key]
                                                        }
                                                    </Typography>
                                                }
                                            </div>
                                        )
                                    } else {
                                        return undefined
                                    }
                                })}
                            </div>
                            {isQR && (
                                <div style={{ width: '100%' }}>
                                    <img style={{
                                        scale: '120%',
                                        marginLeft: '95%',
                                        marginRight: '0px'
                                    }}
                                        src={require("../../static/img/qf_logo.svg").default}
                                        alt="qf_logo"
                                    />
                                </div>
                            )}
                        </div>
                    )
                })}
            </div>
        )
    }


    //display content of the selected file
    displayFileContent = () => {
        let imageFile = false;
        let pdfFile = false;
        let textFile = false;
        let encodedFileData = this.state.selectedFileDetail.data[0];
        let selectedFileType = this.state.selectedFileDetail.file_type;
        
        switch (selectedFileType.toLowerCase()) {
            case "jpg":
            case "jpeg":
            case "png":
                imageFile = true;
                break;
            case "pdf":
                pdfFile = true;
                break;
            case "dch":
            case "portfolio":
                textFile = true;
                break;
            default:
                break;
        }

        if (this.state.selectedFile && Object.keys(this.state.selectedFileDetail).length >= 1) {
            return (
                <div
                    style={{
                        backgroundColor: 'white', padding: '20px 50px',
                        minWidth: '602px', minHeight: 'calc(100vh - 220px)',
                        margin: '10px 10px', borderRadius: '10px',
                    }}
                >
                    {imageFile && (
                        <div style={{
                            width: '100%', height: '100%',
                            display: 'flex', flexDirection: 'row',
                            alignItems: 'center', justifyContent: 'center'
                        }}
                        >
                            <img
                                style={{objectFit:'cover', width:'100%', height:'100%'}}
                                src={`data:image/${selectedFileType};base64, ${encodedFileData}`}
                                alt="imgPreview"
                            />
                        </div>
                    )}
                    {pdfFile && (
                        <a
                            href={`data:application/pdf;base64, ${encodedFileData}`}
                            download={this.state.selectedFile.split('.')[0] + '.pdf'}
                            style={{
                                textDecoration: 'none', width: '100%', height: '100%',
                                display: 'flex', flexDirection: 'row',
                                alignItems: 'center', justifyContent: 'center'
                            }}
                        >
                            <Button
                                style={{
                                    backgroundColor: '#125D98'
                                }}
                            >
                                <Typography style={{ color: 'white' }}>{languageUtil("DOWNLOAD_FILE", this.props.session.language)}</Typography>
                            </Button>
                        </a>
                    )}
                    {textFile && (
                        <>
                            <div
                                style={{
                                    height: '130px', width: '100%', minWidth: '402px',
                                    display: 'flex', flexDirection: 'column', gap: '20px'
                                }}
                            >
                                <div
                                    style={{
                                        margin: 'auto auto', display: 'flex',
                                    }}
                                >
                                    <img style={{ scale: '130%' }} src={require("../../static/img/vq_logo.svg").default} alt="vq_logo" />
                                </div>
                                <div
                                    style={{
                                        margin: 'auto auto',
                                        padding: '7px',
                                        backgroundColor: '#F7FAFC',
                                    }}
                                >
                                    <Typography variant='caption' style={{ color: '#125D98', fontWeight: 'bold' }}>
                                        {languageUtil("PORTFOLIO_STATEMENT", this.props.session.language)}
                                    </Typography>
                                </div>
                                <Divider style={{ width: '100%' }} />
                            </div>
                            {this.displayCredObj()}
                        </>
                    )}

                </div>
            )
        } else {
            return (
                <></>
            )
        }
    }

    handleClickListItem = (fileObj) => {
        let fildID = Object.keys(fileObj)[0];
        let fileName = Object.values(fileObj)[0];

        if (this.state.selectedFile === fileName) {
            this.setState({ selectedFile: '' })
        } else {
            this.setState({ selectedFile: fileName })
            this.viewFileDetail(fildID)
        }
    }

    render() {
        return (
            <div style={{overflow:'hidden', minWidth:'852px'}}>
                <div ref={this.overlay} className="overlay_cover">
                    <div className="spinner">
                        <div className="bounce1"></div>
                        <div className="bounce2"></div>
                        <div className="bounce3"></div>
                    </div>
                </div>
                <div className="viewCred">
                    <WebsiteHeaderS2 title="VIEW"/>
                    <div className="viewContainer">
                        <div className="view_menu">
                            <this.displayFileList />
                        </div>
                        <div className="view_content">
                            {Object.keys(this.state.selectedFileDetail).length > 0 && (this.displayFileContent())}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps)(withRouter(ViewCredentials))