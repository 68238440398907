import "./Function5.css";
import React from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import languageUtil from "../../lib/languageUtil";

function mapStateToProps(state) {
  return { session: state.session, error: state.error }
}

function Function5(props) {
  let [isMouseOver, setIsMouseOver] = React.useState(false);
  let [isOnButton, setIsOnButton] = React.useState(false);

  return (
    <div className="function-4 animate-enter4"
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
    >
      <div className="credentials-management-4 titilliumweb-bold-mine-shaft-28px"
        style={isMouseOver ? { color: 'white' } : { color: 'black' }}
      >
        {languageUtil("CREDENTIALS", props.session.language)}
        {props.session.language === 'english' ? <br /> : ""}
        {languageUtil("VERIFICATION", props.session.language)}
      </div>
      <Link to="/Verification">
        <div className="button-14"
          onMouseEnter={() => setIsOnButton(true)}
          onMouseLeave={() => setIsOnButton(false)}
          style={isMouseOver ? { borderColor: 'white' } : { borderColor: '#AEB2B6' }}>
          <div className="login-9 titilliumweb-normal-mine-shaft-16px"
            style={isMouseOver && !isOnButton ? { color: 'white' } : { color: 'black' }}>
            {languageUtil("MORE", props.session.language).toUpperCase()}
          </div>
          {isMouseOver && !isOnButton
            ? (<img src={require("../../static/img/enter_icon_white.svg").default} alt='arrow' />)
            : (<img src={require("../../static/img/enter_icon.svg").default} alt='arrow' />)
          }
        </div>
      </Link>
      <div className="rectangle-109-4" style={isMouseOver ? { backgroundColor: 'white' } : { backgroundColor: 'var(--greenmain)' }}></div>
    </div>
  );
}

export default connect(mapStateToProps)(Function5);
