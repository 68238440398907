import { CLEAR_SESSION, SET_SESSION } from '../constants/actionTypes';

const initialState = {
  language: 'english',
}

const session = (state = initialState, action) => {
  switch (action.type) {
    case SET_SESSION:
      return Object.assign({},
        action.session
      );
    case CLEAR_SESSION:
      return Object.assign({
        language: state.language
      })
    default:
      return state;
  }
}

export default session;
