import React from "react";
import StepDetail from "../StepDetail";
import "./Steps.css";

import indivUploadSvg from "../../static/img/management_upload.png"
import indivSelectMultiSvg from "../../static/img/management_select_multiple.png"
import indivProcessSvg from "../../static/img/management_process.png"

import operatorReceiveSvg from "../../static/img/verify_receive.png"
import operatorUploadSvg from "../../static/img/verify_upload.png"
import operatorProcessSvg from "../../static/img/verify_process.png"

import ConvertDownloadSvg from "../../static/img/convert_download.png"
import ConvertProcessSvg from "../../static/img/convert_process.png"
import ConvertUploadSvg from "../../static/img/convert_upload.png"


function Steps(props) {
  const { stepDetail1Props, stepDetail2Props, stepDetail3Props } = props;

  function handleDisplayStepSVG(stepNumber) {
    switch (stepNumber) {
      case 'step1':
        if(props.isIndividual) {
          return indivUploadSvg
        }else if(props.isCorporate) {
          return operatorReceiveSvg
        }else if(props.isConversion) {
          return ConvertUploadSvg
        }

        break;
      case 'step2':
        if(props.isIndividual) {
          return indivSelectMultiSvg
        }else if(props.isCorporate) {
          return operatorUploadSvg
        }else if(props.isConversion) {
          return ConvertProcessSvg
        }
        break;
      case 'step3':
        if(props.isIndividual) {
          return indivProcessSvg
        }else if(props.isCorporate) {
          return operatorProcessSvg
        }else if(props.isConversion) {
          return ConvertDownloadSvg
        }
        break;
      default:
        break;
    }
  }

  function display2Steps() {
    return (
    <div className="steps">
      <StepDetail
        receive={stepDetail1Props.receive}
        receviedDigitalCre={stepDetail1Props.receviedDigitalCre}
        step1={stepDetail1Props.step1}
        className={stepDetail1Props.className}
        stepSVG={handleDisplayStepSVG('step1')}
      />
      <img className="line-24" src={require("../../static/img/vector-25@2x.svg").default} alt="seperation" />
      <StepDetail
        receive={stepDetail2Props.receive}
        receviedDigitalCre={stepDetail2Props.receviedDigitalCre}
        step1={stepDetail2Props.step1}
        className={stepDetail2Props.className}
        stepSVG={handleDisplayStepSVG('step2')}
      />
    </div>
    )
  }

  function display3Steps() {
    return (
      <div className="steps">
        <StepDetail
          receive={stepDetail1Props.receive}
          receviedDigitalCre={stepDetail1Props.receviedDigitalCre}
          step1={stepDetail1Props.step1}
          className={stepDetail1Props.className}
          stepSVG={handleDisplayStepSVG('step1')}
        />
        <img className="line-24" src={require("../../static/img/vector-25@2x.svg").default} alt="seperation" />
        <StepDetail
          receive={stepDetail2Props.receive}
          receviedDigitalCre={stepDetail2Props.receviedDigitalCre}
          step1={stepDetail2Props.step1}
          className={stepDetail2Props.className}
          stepSVG={handleDisplayStepSVG('step2')}
        />

        <StepDetail
          receive={stepDetail3Props.receive}
          receviedDigitalCre={stepDetail3Props.receviedDigitalCre}
          step1={stepDetail3Props.step1}
          className={stepDetail3Props.className}
          stepSVG={handleDisplayStepSVG('step3')}
        />
      </div>
    )
  }

  function displaySteps(){
    if(props.isIndividual){
      return display2Steps()
    }else return display3Steps()
  }

  return (
    displaySteps()
  );
}

export default Steps;
