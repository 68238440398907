import React, { Component } from 'react'
import './personalEmailActive.css';

export class PersonalEmailActive extends Component {
  render() {
    return (
      <div className='personalActivateView'>
        <div style={{margin: 'auto auto', display: 'flex'}}>
          <img style={{ scale: '100%' }} src={require("../../static/img/personalEmailActivated.svg").default} alt="pemail_active" />
        </div>
      </div>
    )
  }
}

export default PersonalEmailActive